import { useState } from "react";
import MenuFrame from "../../component/menu_frame";
import { getApi, getLoginToken } from "../../shared/constant";
import { Button, ButtonGroup, Container, IconButton, List, ListItem, ListItemButton, ListItemIcon, ListItemText, Snackbar, Typography } from "@mui/material";
import LoginFooter from "../../component/login_footer";
import NoReferProgram from "../../component/no_refer_program";
import "../../css/common.css";
import ShareIcon from '@mui/icons-material/Share';
import SendIcon from '@mui/icons-material/Send';
import { CopyAll } from "@mui/icons-material";
import CloseIcon from '@mui/icons-material/Close';
import { getReferStatistics } from "../../shared/user";
import 'animate.css';
import { Helmet } from "react-helmet";

function Refer() {
    const [refer, setRefer] = useState(null);
    const [statistics, setStatistics] = useState(null);
    const [open, setOpen] = useState(null);

    const handleClose = (event, reason) => {
        setOpen(null);
    };

    if (refer === null) {
        fetch(getApi() + "refer/get-code?token=" + getLoginToken()).then((res) => {
            return res.json();
        }).then((json) => {
            // console.log(json);
            if (json.code === 404) {
                // No program
                setRefer('');
            } else if (json.code === 200) {
                //Yes program
                setRefer(json.message.code);
                getReferStatistics().then((json) => {
                    // console.log(json);
                    if (json.code === 404) {
                        // No program
                    } else if (json.code === 200) {
                        setStatistics(json.message);
                    }
                }).catch((e) => {
                    console.error(e);
                });
            }
        }).catch((e) => {
            console.error(e);
        });
    }

    let rt = "Play%20Ludo%20and%20earn%20real%20cash%20%E2%82%B910000%20daily.%0A%0ACommission%20Charge%20-%204%25%20Only%0AReferral%20-%202%25%20On%20All%20Games.%0A%0A%2824x7%29%20Admin%20Support%0AInstant%20Withdrawal%20Via%20UPI%2FBank.%0A%0ARegister%20Now%2C%20My%20refer%20code%20is%20%2A" + refer + "%2A.%0A%F0%9F%91%87%F0%9F%91%87%F0%9F%91%87%F0%9F%91%87%F0%9F%91%87%0Ahttps%3A%2F%2Fkhelboludo.com%2Fregister%2F" + refer;
    let rtt = "Play Ludo and earn real cash ₹10000 daily.\n\nCommission Charge - 4% Only\nReferral - 2% On All Games.\n\n(24x7) Admin Support\nInstant Withdrawal Via UPI/Bank.\n\nRegister Now, My refer code is *" + refer + ".\n👇👇👇👇👇\nhttps://khelboludo.com/register/" + refer;
    return (
        <MenuFrame>
            <Helmet>
                <title>Khelboludo - refer and earn</title>
            </Helmet>
            <Snackbar
                open={open !== null}
                autoHideDuration={6000}
                onClose={handleClose}
                message={open}
                action={<IconButton size="small" color="inherit" onClick={handleClose}><CloseIcon fontSize="small" /></IconButton>}
            />
            <Container className="main-frame">
                {refer === null ? "" : refer === '' ?
                    <NoReferProgram onJoin={(txt) => { setRefer(txt) }} /> :
                    <div style={{ minHeight: '80vh', display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column', textAlign: 'center' }}>
                        <img src="/refer2.png" alt="Refer" style={{ maxWidth: '100%' }} className="animate__animated animate__fadeInDown" />
                        <Typography sx={{ typography: { xs: 'h4', sm: 'h3' } }} className="animate__animated animate__tada">Congratulations</Typography>
                        <Typography variant="body2">You are join our referral program</Typography>
                        <Button onClick={() => { navigator.clipboard.writeText(refer); setOpen("Your refrral code is copied"); }} ><Typography variant="h4">{refer}</Typography></Button>
                        <Typography variant="body1" color={'text.secondary'}><strong>Refered Friend: </strong> {statistics === null ? 0 : statistics.refers}</Typography>
                        <Typography variant="body1" color={'text.secondary'}><strong>Earning: </strong> {statistics === null ? 0 : statistics.earn}</Typography>
                        <List>
                            <ListItem disablePadding>
                                <ListItemButton>
                                    <ListItemIcon>
                                        <img src="/reward1.png" alt="Refer" style={{ maxWidth: '80px' }} />
                                    </ListItemIcon>
                                    <ListItemText primary={<Typography fontWeight={"bold"}>When your friend signs up on Khelboludo from you link</Typography>} secondary={<Typography color="success.main" fontWeight="bold" fontSize="0.8rem" >You will earn 2% commission on referral's winning every time.</Typography>} />
                                </ListItemButton>
                            </ListItem>
                            <ListItem disablePadding>
                                <ListItemButton>
                                    <ListItemIcon>
                                        <img src="/reward2.png" alt="Refer" style={{ maxWidth: '80px' }} />
                                    </ListItemIcon>
                                    <ListItemText primary={<Typography fontWeight={"bold"}>Suppose your referral plays a battle for 10000 ludo coin and win</Typography>} secondary={<Typography color="success.main" fontWeight="bold" fontSize="0.8rem" >You will get 200 in reward coin.</Typography>} />
                                </ListItemButton>
                            </ListItem>
                        </List>

                        <ButtonGroup fullWidth style={{ margin: "10px 0" }}>
                            <Button variant="contained" color="success" component={"a"} href={"whatsapp://send?text=" + rt}> <ShareIcon /> &nbsp; WhatsApp</Button>
                            <Button variant="contained" color="info" component={"a"} href={"https://t.me/share/url?url=https%3A%2F%2Fkhelboludo.com%2Fregister%2F" + refer + "&text=" + rt}> <SendIcon /> &nbsp; Telegram</Button>
                        </ButtonGroup>
                        <Button variant="outlined" fullWidth onClick={() => { navigator.clipboard.writeText(rtt); setOpen("Message copied"); }}> <CopyAll /> &nbsp; Copy to clipboard</Button>
                        <br />
                    </div>
                }
            </Container>
            <LoginFooter />
        </MenuFrame>
    );
}

export default Refer;