import { Box, Button, CircularProgress, Container, Grid, Paper, TextField, Typography } from "@mui/material";
import MenuFrame from "../../component/menu_frame";
import { useNavigate, useParams } from "react-router-dom";
import 'animate.css';
import { useState } from "react";
import { getApi, getLoginToken } from "../../shared/constant";

function SetRoomCode() {
    const navigate = useNavigate();
    const { match, access } = useParams();
    const [submit, setSubmit] = useState(false);
    const [codeErr, setCodeErr] = useState(false);
    const [help, setHelp] = useState("Room code that you created.");


    function handleSubmit(event) {
        event.preventDefault();
        setSubmit(true);
        const data = new FormData(event.currentTarget);

        if (/^[0-9]{5,10}$/.test(data.get('roomcode'))) {
            setCodeErr(null);
        } else {
            setHelp("Invalid roomcode for ludo.")
            setCodeErr(true);
            setSubmit(false);
            return;
        }

        fetch(getApi() + "games/current/set-room-code", {
            method: "POST",
            body: JSON.stringify({
                "code": data.get('roomcode'),
                "match": match,
                "token":getLoginToken()
            })
        }).then((res) => {
            return res.json();
        }).then((json) => {
            if (json.message === 1) {
                navigate("/user/games/ongoing/" + match)
            } else {
                setHelp("There is an error raised while submitting roomcode.")
                setCodeErr(true);
            }
            setSubmit(false);
        }).catch((err) => {
            console.error(err);
            setSubmit(false);
        })
    }

    return (
        <MenuFrame warning="page_roomcode">
            <Container className="animate__animated animate__rubberBand" style={{ display: "flex", alignItems: "center", justifyContent: "center", height: "80vh" }}>
                <Paper style={{ padding: "10px", width: "100%" }}>
                    <Box component="form" noValidate onSubmit={handleSubmit} id="roomcode-form">
                        <Typography variant="h5">Please provide roomcode</Typography>
                        <Typography variant="body2" color={"warning.main"}>Create a room in ludo king and paste room code here.</Typography>
                        <Typography variant="body2" color={"warning.main"}>लूडो किंग में एक रूम बनाएं और यहां रूम कोड पेस्ट करें।</Typography>
                        <Grid container spacing={2} mt={1}>
                            <Grid item xs={12}>
                                <TextField placeholder="X X X X X X" name="roomcode" fullWidth id="roomcode" label="Room code" autoFocus helperText={help} error={codeErr} />
                            </Grid>
                            <Grid item xs={12}>
                                <Button type="submit" fullWidth variant="contained" sx={{ mt: 1, mb: 2 }} disabled={submit}>
                                    {submit ? (<CircularProgress color="primary" size={24} />) : ("Submit")}
                                </Button>
                            </Grid>
                        </Grid>
                        <Typography variant="body2" color={"info.main"}>Penalty for intentional cheating on room code is 200 coin. | रूम कोड से जुड़ा किसी भी धोखाधड़ी के लिए जुर्माना 200 है।</Typography>
                        <Typography variant="body2" color={"info.main"}>Only ludo classic room code is allowed. Penalty for wrong room code is 100 coin. | केवल लूडो क्लासिक रूम कोड की अनुमति है। गलत रूम कोड के लिए जुर्माना 100 है।।</Typography>
                        <Typography variant="body2" color={"info.main"}>Penalty for not submitting room code is 50 coin. | रूम कोड सबमिट न करने पर जुर्माना 50 है।</Typography>
                    </Box>
                </Paper>
            </Container>
        </MenuFrame>
    );
}

export default SetRoomCode;