import { useState } from "react";
import MenuFrame from "../../component/menu_frame";
import { Accordion, AccordionDetails, AccordionSummary, Box, Chip, Container, Link, Pagination, Stack, Typography } from "@mui/material";
import LoginFooter from "../../component/login_footer";
import "../../css/common.css";
// import EmojiEventsIcon from '@mui/icons-material/EmojiEvents';
// import MoneyOffIcon from '@mui/icons-material/MoneyOff';
// import PersonAddIcon from '@mui/icons-material/PersonAdd';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';
import { getAdminTransaction, getUserAdminData } from "../../shared/user";
import { useParams } from "react-router-dom";
import { Helmet } from "react-helmet";

function getTransactionView(transaction, account, user, filter, expanded, handleChange, balance, reward, page) {
    if (transaction === null || account === null || transaction.length === 0) return (<div style={{ display: "flex", justifyContent: 'center', alignItems: 'center', minHeight: '80vh' }}><img src="/no_transaction.png" alt="No Transaction found" /></div>);

    if (filter === "Wallet") {
        transaction = transaction.filter((item) => {
            return item.ref === "Withdraw" || item.ref === "Deposit";
        });
    } else if (filter !== null) {
        transaction = transaction.filter((item) => {
            return item.ref === filter;
        });
    }
    const MAX_PER_PAGE = 15;
    let pmin = (page - 1) * MAX_PER_PAGE;
    let pmax = (page) * MAX_PER_PAGE;
    transaction = transaction.filter((_, idx) => {
        return idx >= pmin && idx < pmax;
    });

    return transaction.map((row) =>
        <Accordion key={row.id} expanded={expanded === row.id} onChange={handleChange(row.id)}>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Box style={{ alignItems: "center", display: 'flex', justifyContent: 'space-between', width: '100%' }}>
                    <Typography variant="body2">
                        #<em style={{ borderRight: '1px solid gray', paddingRight: '5px' }}>{row.id}</em> {row.dt}
                    </Typography>
                    <div style={{ marginLeft: 'auto' }}></div>
                    <Typography variant="body2" color={row.credit === user ? "success" : "error"} component={'div'} style={{ display: "flex", alignItems: "center" }}>
                        {row.paid_on === "Ludo Coin" ? <img src="/favicon.ico" width={"16px"} alt="ludo coin" /> : <AccountBalanceWalletIcon color={row.credit === user ? "success" : "error"} fontSize="small" />} &nbsp; <strong>{row.credit === user ? "" : "-"}{row.amount}</strong>
                    </Typography>
                </Box>
            </AccordionSummary>
            <AccordionDetails>
                {filter === null ? <Typography variant="body2" color={"text.secondry"} style={{ display: "flex", alignItems: "center" }}> Closing Balance &nbsp;&nbsp; <img src="/favicon.ico" alt="ludo coin" width={"22px"} style={{ margin: "5px" }} /> {balance[row.id]} &nbsp; <AccountBalanceWalletIcon style={{ margin: "5px" }} /> {reward[row.id]}</Typography> : ""}
                <Typography variant="subtitle1">{row.description}</Typography>
            </AccordionDetails>
        </Accordion>
    )
}

function AdminTransaction() {
    const [transaction, setTransaction] = useState(null);
    const [user, setUser] = useState(-1);
    const [expanded, setExpanded] = useState(false);
    const [account, setAccount] = useState(null);
    const [filter, setFilter] = useState(null);
    const [page, setPage] = useState(1);

    let trial_bal = {};
    let trial_reward = {};

    const { id } = useParams();

    const handleChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    };

    if (transaction === null)
        getAdminTransaction(id).then((data) => {
            // console.log(data);
            if (data.code !== 200) {
                alert(data.message);
                setTransaction([]);
            } else {
                setTransaction(data.message);
                setUser(data.user);
            }
            return getUserAdminData(id);
        }).then((json) => {
            // console.log(json);
            setAccount(json.message);
        }).catch((err) => {
            console.error(err);
        });

    if (transaction !== null && account !== null) {
        let cub = account.balance;
        let cur = account.rewards;
        transaction.forEach(item => {

            trial_bal[item.id] = cub;
            trial_reward[item.id] = cur;
            if (item.paid_on === "Ludo Coin") {
                if (item.credit === user) {
                    cub = cub - item.amount;
                } else {
                    cub = cub + item.amount;
                }
            } else if (item.paid_on === "Reward") {
                if (item.credit === user) {
                    cur = cur - item.amount;
                } else {
                    cur = cur + item.amount;
                }
            }
        });
    }

    return (
        <MenuFrame user={account}>
            <Helmet>
                <title>Account transaction details</title>
            </Helmet>
            <Container className="main-frame">
                <table style={{width:"100%"}}>
                    <tbody>
                        <tr>
                            <td colSpan={2}>
                                <Typography variant="body2" textAlign={"center"}><Link underline="none" href={"/user/public/" + id}>View Profile</Link></Typography>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <Typography variant="body2" className="icontext">Closing Balance:</Typography>
                            </td>
                            <td>
                                <Typography variant="body2" className="icontext">
                                    &nbsp; <img src="/favicon.ico" width={"16px"} alt="ludo coin" />&nbsp;{account === null ? "" : account.balance}
                                    &nbsp; &nbsp; <AccountBalanceWalletIcon fontSize="small" />&nbsp;{account === null ? "" : account.rewards}
                                </Typography>
                            </td>
                        </tr>
                        <tr>
                            <td><Typography variant="body2" className="icontext">Opening Balance</Typography></td>
                            <td>
                                <Typography variant="body2" className="icontext">
                                    &nbsp; <img src="/favicon.ico" width={"16px"} alt="ludo coin" />&nbsp;{account === null ? "" : account.opening_balance}
                                    &nbsp; &nbsp; <AccountBalanceWalletIcon fontSize="small" />&nbsp;{account === null ? "" : account.opening_rewards}
                                </Typography>
                            </td>
                        </tr>
                    </tbody>
                </table>
                <Stack direction="row" spacing={1} style={{ maxWidth: "100%", overflowX: "scroll", paddingBottom: "15px", marginTop: "10px" }}>
                    <Chip label="All" variant={filter === null ? "filled" : "outlined"} onClick={() => { setFilter(null) }} />
                    <Chip label="Wallet" variant={filter === "Wallet" ? "filled" : "outlined"} onClick={() => { setFilter("Wallet") }} />
                    <Chip label="Referral" variant={filter === "Referral" ? "filled" : "outlined"} onClick={() => { setFilter("Referral") }} />
                </Stack>

                {getTransactionView(transaction, account, user, filter, expanded, handleChange, trial_bal, trial_reward, page)}

                {transaction == null ? "" : <Pagination style={{ marginTop: '10px' }} count={transaction == null ? 0 : Math.ceil(transaction.length / 15)} color="primary" onChange={(e, v) => { setPage(v); }} />}
            </Container>
            <br />
            <LoginFooter />
        </MenuFrame>
    );
}

export default AdminTransaction;