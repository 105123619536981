import { useEffect, useState } from "react";
import MenuFrame from "../../component/menu_frame";
import { Backdrop, Button, CircularProgress, Container, Link, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tooltip, Typography } from "@mui/material";
import { getApi, getMediaApi } from "../../shared/constant";
import 'animate.css';
import { trackerLog } from "../../shared/tracker";
import { orange } from "@mui/material/colors";
import "../../css/conflict_resulation.css";
import { Helmet } from "react-helmet";
import { useNavigate } from "react-router-dom";

function ConflictResolution(props) {
    const navigate = useNavigate();
    const [conflict, setConflict] = useState(null);
    const [submit, setSubmit] = useState(false);

    useEffect(() => {
        const interval = setInterval(async () => {
            loadInit(false);
        }, 30000);
        return () => clearInterval(interval);
    }, [conflict, loadInit]);


    if (conflict === null) {
        loadInit(true);
    }
    function loadInit(log) {
        fetch(getApi() + "admin/conflict/get?state=" + props.type).then((res) => {
            return res.json();
        }).then((json) => {
            // console.log(json);
            if (json.code === 200) {
                setConflict(json.message);
            } else {
                setConflict([]);
            }
            if (log) trackerLog("ACFOG");
        }).catch((err) => {
            console.error(err);
        });
    }

    function markWin(mpid) {
        setSubmit(true);
        fetch(getApi() + "admin/conflict/win", {
            method: 'POST',
            body: JSON.stringify({
                pid: mpid
            })
        }).then((res) => {
            return res.json();
        }).then((json) => {
            // console.log(json);
            loadInit();
            setSubmit(false);
        }).catch((err) => {
            console.error(err);
            setSubmit(false);
        });
    }

    function markWin25(mpid) {
        setSubmit(true);
        fetch(getApi() + "admin/conflict/win25", {
            method: 'POST',
            body: JSON.stringify({
                pid: mpid
            })
        }).then((res) => {
            return res.json();
        }).then((json) => {
            // console.log(json);
            loadInit();
            setSubmit(false);
        }).catch((err) => {
            console.error(err);
            setSubmit(false);
        });
    }

    function cancel(mpid) {
        setSubmit(true);
        fetch(getApi() + "admin/conflict/cancel", {
            method: 'POST',
            body: JSON.stringify({
                pid: mpid
            })
        }).then((res) => {
            return res.json();
        }).then((json) => {
            // console.log(json);
            loadInit();
            setSubmit(false);
        }).catch((err) => {
            console.error(err);
            setSubmit(false);
        });
    }

    return (
        <MenuFrame>
            <Container className="main-frame">
                <Helmet>
                    <title>{props.type} Resolution {conflict === null ? "" : " - " + String(conflict.length) + " Open"} </title>
                </Helmet>
                <Typography variant="h4" >{props.type} Resolution</Typography>
                <Typography variant="body2" color="success.main" >Open Case: {conflict === null ? 0 : conflict.length}</Typography>
                <TableContainer component={Paper} className="animate__animated animate__fadeIn">
                    <Table id="conflict-table">
                        <TableHead>
                            <TableRow>
                                <TableCell><strong>ID#</strong></TableCell>
                                <TableCell><strong>Date</strong></TableCell>
                                <TableCell><strong>Game</strong></TableCell>
                                <TableCell><strong>Name</strong></TableCell>
                                <TableCell align="right"><strong>Entry</strong></TableCell>
                                <TableCell><strong>Result Accepted</strong></TableCell>
                                <TableCell><strong>Screenshot</strong></TableCell>
                                <TableCell><strong>Contact</strong></TableCell>
                                <TableCell align="center" colSpan={3}><strong>Action</strong></TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {conflict === null ? <></> :
                                conflict.map((row) => (
                                    <TableRow key={row.pid} style={row.user_accepted === "Win" ? { background: orange[50] } : {}}>
                                        <TableCell scope="row"><span>{row.match_ref}</span><br /><Button variant="text" onClick={() => { navigate("/user/games/roomcode/admin/" + row.match_ref) }}>{row.room_code}</Button></TableCell>
                                        <TableCell style={{ minWidth: "75px" }}>{row.dt.substring(0, 11)}<br /><strong>{row.dt.substring(12)}</strong></TableCell>
                                        <TableCell nowrap="nowrap">{row.game_name}</TableCell>
                                        <TableCell><Link underline="none" color={"unset"} href={"/admin/transaction/" + row.username} ><Tooltip title={row.person_name} arrow>@{row.username}</Tooltip></Link></TableCell>
                                        <TableCell align="right">{row.amount_paid}</TableCell>
                                        <TableCell nowrap="nowrap">{row.user_accepted} <br /> {row.update_time} </TableCell>
                                        <TableCell>{row.screenshot === null ? "NA" : <Link href={getMediaApi() + row.screenshot} target="_blank"  ><img src={getMediaApi() + row.screenshot} alt={row.screenshot} width={'64px'} /></Link>} <br /> {row.win_time}</TableCell>
                                        <TableCell>{row.mobile}<br />{row.email} </TableCell>
                                        <TableCell className="actionclass">
                                            <div className="w300">
                                                <Button color="success" variant="contained" onClick={() => { markWin(row.pid); }}>Win</Button>
                                                <Button color="warning" variant="contained" onClick={() => { markWin25(row.pid); }}>Win (25%)</Button>
                                                <Button color="error" variant="contained" onClick={() => { cancel(row.pid); }}>Cancel</Button>
                                            </div>
                                        </TableCell>
                                    </TableRow>
                                ))
                            }
                        </TableBody>
                    </Table>
                </TableContainer>
            </Container>
            <Backdrop open={submit} ><CircularProgress /></Backdrop>
        </MenuFrame>
    );
}

export default ConflictResolution;
