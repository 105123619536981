import { getApi, getLoginToken } from "./constant";

export function userLogout() {
    return fetch(getApi() + "logout?session=" + getLoginToken()).then((xhr) => {
        if (xhr.status !== 200) {
            console.log("Force logout");
        }
        localStorage.clear();
        return;
    }).catch((err) => {
        localStorage.clear();
    });
}

export function changeUserCred(method, newVal) {
    console.log(method);
    console.log(newVal);
    return fetch(getApi() + "user/change-contact", {
        method: "POST",
        body: JSON.stringify({
            token: getLoginToken(),
            method: method === 'SMS' ? "MOBILE" : "MAIL",
            new_val:newVal
        })
    }).then((res) => {
        return res.json();
    });
}

export function getUserData() {
    return fetch(getApi() + "get-user?token=" + getLoginToken()).then((res) => {
        return res.json();
    }).then((json) => {
        if(json.code === 401){
            userLogout();
            window.location.reload();
            throw json;
        }
        return json;
    })
}

export function getUserAccountsData() {
    return fetch(getApi() + "accounts/get?token=" + getLoginToken()).then((res) => {
        return res.json();
    }).then((data) => {
        if(data.code === 401){
            userLogout();
            window.location.reload();
            throw data;
        }
        return data;
    });
}

export function getUserAdminData(user) {
    return fetch(getApi() + "accounts/admin/get?token=" + getLoginToken()+"&username="+user).then((res) => {
        return res.json();
    }).then((data) => {
        if(data.code === 401){
            userLogout();
            window.location.reload();
            throw data;
        }
        return data;
    });
}

export function getTransaction() {
    return fetch(getApi() + "accounts/transaction?token=" + getLoginToken()).then((res) => {
        return res.json();
    });
}

export function getAdminTransaction(id) {
    return fetch(getApi() + "admin/transaction?account=" + id + "&token=" + getLoginToken()).then((res) => {
        return res.json();
    });
}

export function getReferStatistics() {
    return fetch(getApi() + "refer/statistics?token=" + getLoginToken()).then((res) => {
        return res.json();
    }).then((json) => {
        return json;
    });
}

export function getUserStatistics() {
    return fetch(getApi() + "user/statistics?token=" + getLoginToken()).then((res) => {
        return res.json();
    }).then((json) => {
        return json;
    });
}

export function otpVerification(code, type) {
    return fetch(getApi() + "user/otp", {
        method: 'POST',
        body: JSON.stringify({
            "token": getLoginToken(),
            "otp": code,
            "type": type
        })
    }).then((res) => {
        return res.json();
    })
}

export function otpResend(type) {
    return fetch(getApi() + "user/resend-otp", {
        method: 'POST',
        body: JSON.stringify({
            "token": getLoginToken(),
            "type": type === "SMS" || type === "Mail" ? "register" : "login"
        })
    }).then((res) => {
        return res.json();
    })
}

export function addAccountNote(amount, debitac, creditac, description, ref, coin, eid) {
    return fetch(getApi() + "accounts/add-account-note", {
        method: "POST",
        body: JSON.stringify({
            token: getLoginToken(),
            amount: amount,
            credit: creditac,
            debit: debitac,
            description: description,
            refrence: ref,
            coin: coin,
            exid: eid
        })
    }).then((res) => {
        return res.json();
    })
}

export function updateMail(mail) {
    return fetch(getApi() + "user/update-mail", {
        method: 'POST',
        body: JSON.stringify({
            email: mail.trim().toLowerCase(),
            token: getLoginToken()
        })
    }).then((res) => {
        return res.json();
    });
}

export function updateName(name) {
    return fetch(getApi() + "user/update-name", {
        method: 'POST',
        body: JSON.stringify({
            name: name.trim(),
            token: getLoginToken()
        })
    }).then((res) => {
        return res.json();
    });
}