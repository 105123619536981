import { useState } from "react";
import MenuFrame from "../../component/menu_frame";
import DataTable from 'react-data-table-component';
import { getApi, getLoginToken } from "../../shared/constant";
import { Chip } from "@mui/material";
import DoneIcon from '@mui/icons-material/Done';
import { Helmet } from "react-helmet";


const RowComponent = ({ data }) => {
    return (<>
        <div>Note: {data.user_comment}</div>
        <div>Admin: <pre style={{ fontFamily: "inherit", marginTop: 0 }}>{data.admin_comment}</pre></div>
        {data.method === "UPI" ? "" :
            <div>
                <table style={{ width: "100%" }}>
                    <tr>
                        <td>Bank</td><td>{data.bank_name}</td>
                    </tr>
                    <tr>
                        <td>IFSC</td><td>{data.ifsc}</td>
                    </tr>
                    <tr>
                        <td>Account</td><td>{data.acc_num}</td>
                    </tr>
                </table>
            </div>}
        {data.method === "Bank" ? "" :
            <div>
                <table style={{ width: "100%" }}>
                    <tr>
                        <td>UPI</td><td>{data.upi}</td>
                    </tr>
                    <tr>
                        <td>Name</td><td>{data.other_details}</td>
                    </tr>
                </table>
            </div>}
    </>);
}

function SellHistory() {
    const [history, setHistory] = useState(null);

    const columns = [
        { name: 'ID', selector: row => "RW23" + row.num, },
        { name: 'Date', selector: row => row.dt, },
        { name: 'Method', selector: row => row.method, },
        { name: 'Coin', selector: row => <>{row.amount} {row.approve_amount == null ? "" : <Chip deleteIcon={<DoneIcon />} size="small" onDelete={() => { }} color="success" variant="outlined" label={row.approve_amount} />}</>, },
        { name: 'Status', selector: row => row.status, },
    ];

    if (history === null) {
        fetch(getApi() + "withdraw/history?token=" + getLoginToken()).then((res) => {
            return res.json();
        }).then((json) => {
            setHistory(json.message)
        }).catch((e) => {
            console.error(e);
        });
    }


    return (
        <MenuFrame>
            <Helmet>
                <title>Khelboludo - Sell History</title>
            </Helmet>
            <DataTable
                // dense={true}
                columns={columns}
                data={history === null ? [] : history}
                progressPending={history === null}
                expandableRows
                expandableRowsComponent={RowComponent}
                pagination
                paginationPerPage={15}
            />
        </MenuFrame>
    );
}

export default SellHistory;