import { getApi } from "./constant";

export function dismissNotification(nfid) {
    return fetch(getApi() + "notification/dismiss", {
        method:"DELETE",
        body:JSON.stringify({
            nid:nfid
        })
    }).then((res) => {
        return res.json();
    }).then((json) => {
        return json;
    });
}