import { useEffect, useState } from 'react';
import { neonCursor } from 'threejs-toys'
import "../css/welcome.css"
import { Button, Typography } from '@mui/material';
import { getApi, getLoginToken } from '../shared/constant';
import EastIcon from '@mui/icons-material/East';
import { trackerLog } from '../shared/tracker';
import { Helmet } from 'react-helmet';

function WelcomeUser() {
    const [account, setAccount] = useState(null);
    if (account === null) {
        fetch(getApi() + "accounts/get?token=" + getLoginToken()).then((res) => {
            if (res.status !== 200) { throw res.json(); }
            return res.json();
        }).then((data) => {
            setAccount(data.message);
            trackerLog("WELCM");
        }).catch((err) => {
            console.log(err);
        });
    }
    useEffect(() => {
        try {
            neonCursor({
                el: document.getElementById('app'),
                shaderPoints: 16,
                curvePoints: 80,
                curveLerp: 0.5,
                radius1: 5,
                radius2: 30,
                velocityTreshold: 10,
                sleepRadiusX: 100,
                sleepRadiusY: 100,
                sleepTimeCoefX: 0.0025,
                sleepTimeCoefY: 0.0025
            })
        } catch (error) {
            window.location = "/";
            console.log(error);
        }
    }, []);

    return (
        <div id="app">
            <Helmet>
                <title>Welcome to KhelboLudo</title>
                <meta name="title" content="Welcome" />
                <meta name="description" content="Are you ready? Onboard yourself" />
            </Helmet>
            <div id="hero">
                <Typography variant="h6">
                    Welcome
                </Typography>

                <Typography variant="h3">
                    {account === null ? "To The Game" : account.display_name}
                </Typography>
                <a href="/">
                    khelboludo.com
                    <br /><br />
                    <Button variant="outlined" style={{ color: "white", borderColor: "white" }}>Skip &nbsp; <EastIcon /></Button>
                </a>
            </div>

        </div>);
}

export default WelcomeUser;