import IconButton from '@mui/material/IconButton';
import { Drawer, List, Typography } from '@mui/material';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import MailIcon from '@mui/icons-material/Mail';
import styled from '@emotion/styled';
import { AttachMoney, BugReport, BugReportOutlined, Close, Dns, MoneyOff, Settings } from '@mui/icons-material';
import SportsEsportsIcon from '@mui/icons-material/SportsEsports';
import AccessTimeFilledIcon from '@mui/icons-material/AccessTimeFilled';
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';
import ReceiptIcon from '@mui/icons-material/Receipt';
import ContactPageIcon from '@mui/icons-material/ContactPage';
import ChatIcon from '@mui/icons-material/Chat';
import DocumentScannerIcon from '@mui/icons-material/DocumentScanner';
import RedeemIcon from '@mui/icons-material/Redeem';
import CurrencyRupeeIcon from '@mui/icons-material/CurrencyRupee';
import GroupIcon from '@mui/icons-material/Group';
// import QuickreplyIcon from '@mui/icons-material/Quickreply';
import packageJson from '../../package.json';
import Link from '@mui/material/Link';
import { ADMIN, MANAGER, PARTNER, USER, getAuthentication } from '../shared/constant';
import CasinoIcon from '@mui/icons-material/Casino';
import BalanceIcon from '@mui/icons-material/Balance';
import DownloadIcon from '@mui/icons-material/Download';
import PublishIcon from '@mui/icons-material/Publish';
import LogoutIcon from '@mui/icons-material/Logout';
import SportsKabaddiIcon from '@mui/icons-material/SportsKabaddi';
// import { useState } from 'react';
import StorageIcon from '@mui/icons-material/Storage';
import PeopleAltIcon from '@mui/icons-material/PeopleAlt';
import NearbyErrorIcon from '@mui/icons-material/NearbyError';

function SideDrawer(props) {
    // const [count, setCount] = useState(0);

    const DrawerHeader = styled('div')(({ theme }) => ({
        display: 'flex',
        alignItems: 'flex-start',
        padding: '0 10px',
        justifyContent: 'space-between',
    }));

    const game_menu = [
        { 'name': 'Games', 'icon': <SportsEsportsIcon />, link: '/user/games' },
        { 'name': 'History', 'icon': <AccessTimeFilledIcon />, link: '/user/history' },
        { 'name': 'Wallet', 'icon': <AccountBalanceWalletIcon />, link: '/user/wallet' },
        { 'name': 'Transaction', 'icon': <ReceiptIcon />, link: '/user/transaction' }
    ];

    const contact_menu = [
        { 'name': 'Mail', 'icon': <MailIcon />, link: '/support/mail' },
        { 'name': 'Contact', 'icon': <ContactPageIcon />, link: '/contact' },
        { 'name': 'Live chat', 'icon': <ChatIcon />, link: '/support/livechat' },
    ];

    const user_misc_menu = [
        { 'name': 'KYC', 'icon': <DocumentScannerIcon />, link: '/user/kyc' },
        { 'name': 'Refer & Earn', 'icon': <RedeemIcon />, link: '/user/refer' },
        { 'name': 'Lucky Draw', 'icon': <CasinoIcon />, link: '/lucky-draw' },
    ];

    const conflict_menu = [
        // { 'name': 'Notification', 'icon': <Badge badgeContent={count} color="warning"><NotificationsIcon /></Badge>, link: '/admin/notification' },
        { 'name': 'Conflict', 'icon': <BalanceIcon />, link: '/admin/conflict-resolution' },
        { 'name': 'Ongoing', 'icon': <SportsKabaddiIcon />, link: '/admin/ongoing-match' },
        { 'name': 'History', 'icon': <AccessTimeFilledIcon />, link: '/admin/history-match' },
        // { 'name': 'Chat Request', 'icon': <QuickreplyIcon />, link: '/admin/chat-request' },
    ];

    const finops_menu = [
        { 'name': 'Withdraw Approval', 'icon': <PublishIcon />, link: '/admin/withdraw-approve' },
        { 'name': 'Deposit Approval', 'icon': <DownloadIcon />, link: '/admin/deposit-approve' },
        { 'name': 'Penalty', 'icon': <MoneyOff />, link: '/admin/credit-note' },
        { 'name': 'Refunds', 'icon': <AttachMoney />, link: '/admin/debit-note' },
    ];
    const accounts_menu = [
        { 'name': 'Balance Sheet', 'icon': <CurrencyRupeeIcon />, link: '/admin/profit-and-loss' },
        { 'name': 'User list', 'icon': <GroupIcon />, link: '/admin/user-list' },
        { 'name': 'Page Warning', 'icon': <NearbyErrorIcon />, link: '/admin/page-warning' },
        { 'name': 'Setting', 'icon': <Settings />, link: '/admin/setting' },
    ];


    const admin_menu = [
        { 'name': 'Visitors Report', 'icon': <PeopleAltIcon />, link: '/admin/visitors-report' },
        { 'name': 'Server Information', 'icon': <StorageIcon />, link: '/admin/server' },
        { 'name': 'Server Maintenance', 'icon': <Dns />, link: '/admin/server-maintenance' },
        { 'name': 'Stacktrace Logs', 'icon': <BugReport />, link: '/admin/api-log' },
        { 'name': 'HTTP Logs', 'icon': <BugReportOutlined />, link: '/admin/request-log' },
    ];

    return (<Drawer variant="persistent" anchor="left" open={props.open} sx={{
        minWidth: 250, width: '25%', flexShrink: 0,
        '& .MuiDrawer-paper': { minWidth: 250, width: '25%', boxSizing: 'border-box', },
    }} >
        <DrawerHeader>
            <div>
                <br />
                {props.data !== null ? props.data.display_name : ''}
                <br />
                <small>
                    {props.data !== null ? props.data.user_type === 'General' ? 'Special member' : props.data.user_type : ''}
                </small>
            </div>
            <IconButton onClick={props.onDrawerClose}>
                <Close />
            </IconButton>
        </DrawerHeader>

        <Divider />
        {getAuthentication() === USER ? (<>
            <List>
                {
                    game_menu.map((menu, index) => (
                        <Link key={index} href={menu.link} color="unset" underline="none">
                            <ListItem disablePadding>
                                <ListItemButton>
                                    <ListItemIcon>
                                        {menu.icon}
                                    </ListItemIcon>
                                    <ListItemText primary={menu.name} />
                                </ListItemButton>
                            </ListItem>
                        </Link>
                    ))
                }
            </List>
            <Divider />
            <List>
                {
                    user_misc_menu.map((menu, index) => (
                        <Link key={index} href={menu.link} color="unset" underline="none">
                            <ListItem disablePadding>
                                <ListItemButton>
                                    <ListItemIcon>
                                        {menu.icon}
                                    </ListItemIcon>
                                    <ListItemText primary={menu.name} />
                                </ListItemButton>
                            </ListItem>
                        </Link>
                    ))
                }
            </List>
            <Divider />
        </>) : ""}
        {getAuthentication() === ADMIN || getAuthentication() === PARTNER || getAuthentication() === MANAGER ? (<>
            <List>
                {
                    conflict_menu.map((menu, index) => (
                        <Link key={index} href={menu.link} color="unset" underline="none">
                            <ListItem disablePadding>
                                <ListItemButton>
                                    <ListItemIcon>
                                        {menu.icon}
                                    </ListItemIcon>
                                    <ListItemText primary={menu.name} />
                                </ListItemButton>
                            </ListItem>
                        </Link>
                    ))
                }
            </List>
            <Divider />
        </>) : ""}
        {getAuthentication() === ADMIN || getAuthentication() === PARTNER || getAuthentication() === MANAGER ? (<>
            <List>
                {
                    finops_menu.map((menu, index) => (
                        <Link key={index} href={menu.link} color="unset" underline="none">
                            <ListItem disablePadding>
                                <ListItemButton>
                                    <ListItemIcon>
                                        {menu.icon}
                                    </ListItemIcon>
                                    <ListItemText primary={menu.name} />
                                </ListItemButton>
                            </ListItem>
                        </Link>
                    ))
                }
            </List>
            <Divider />
        </>) : ""}
        {getAuthentication() === ADMIN || getAuthentication() === PARTNER ? (<>
            <List>
                {
                    accounts_menu.map((menu, index) => (
                        <Link key={index} href={menu.link} color="unset" underline="none">
                            <ListItem disablePadding>
                                <ListItemButton>
                                    <ListItemIcon>
                                        {menu.icon}
                                    </ListItemIcon>
                                    <ListItemText primary={menu.name} />
                                </ListItemButton>
                            </ListItem>
                        </Link>
                    ))
                }
            </List>
            <Divider />
        </>) : ""}
        {getAuthentication() === ADMIN ? (<>
            <List>
                {
                    admin_menu.map((menu, index) => (
                        <Link key={index} href={menu.link} color="unset" underline="none">
                            <ListItem disablePadding>
                                <ListItemButton>
                                    <ListItemIcon>
                                        {menu.icon}
                                    </ListItemIcon>
                                    <ListItemText primary={menu.name} />
                                </ListItemButton>
                            </ListItem>
                        </Link>
                    ))
                }
            </List>
            <Divider />
        </>) : ""}
        <List>
            {
                contact_menu.map((menu, index) => (
                    <Link key={index} href={menu.link} color="unset" underline="none">
                        <ListItem disablePadding>
                            <ListItemButton>
                                <ListItemIcon>
                                    {menu.icon}
                                </ListItemIcon>
                                <ListItemText primary={menu.name} />
                            </ListItemButton>
                        </ListItem>
                    </Link>
                ))
            }
        </List>
        <List>
            <Link href={"/user/profile/logout"} color="unset" underline="none">
                <ListItem disablePadding>
                    <ListItemButton>
                        <ListItemIcon>
                            <LogoutIcon color='error' />
                        </ListItemIcon>
                        <ListItemText primary={<Typography color={"error"} fontWeight={"bold"}>Logout</Typography>} />
                    </ListItemButton>
                </ListItem>
            </Link>
        </List>
        <Divider />
        <Typography variant="caption" display="block" style={{ padding: '20px 10px' }}>
            KhelboLudo {packageJson.version}
        </Typography>
    </Drawer>);
}

export default SideDrawer;