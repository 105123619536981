import { useState } from "react";
import "../../../css/edit.css";
import MenuFrame from "../../../component/menu_frame";
import { Backdrop, Box, Button, Card, CircularProgress, Container, CssBaseline, FormControl, FormHelperText, Grid, InputAdornment, InputLabel, MenuItem, Select, TextField, Typography } from "@mui/material";
import { getApi, getLoginToken, isLogin } from "../../../shared/constant";
import LocationOnIcon from '@mui/icons-material/LocationOn';
import { useNavigate } from "react-router-dom";
import { getReferStatistics, getUserStatistics } from "../../../shared/user";
import { Helmet } from "react-helmet";



function ProfileEdit() {
    const [refStatistics, setRefStatistics] = useState(null);
    const [userStatistics, setUserStatistics] = useState(null);
    const [user, setUser] = useState(null);
    const navigate = useNavigate();

    function handleSave(event) {
        event.preventDefault();
        const data = new FormData(event.currentTarget);
        const dob_u = new Date(data.get('year'), data.get('month') - 1, data.get('day'));
        fetch(getApi() + "user/update", {
            method: 'POST',
            body: JSON.stringify({
                name: data.get('name'),
                dob: dob_u.valueOf() < 0 ? null : dob_u.valueOf() / 1000,
                location: user.location,
                state: user.state,
                country: user.country,
                pincode: user.pincode,
                token: getLoginToken()
            })
        }).then((res) => {
            return res.json();
        }).then((json) => {
            console.log(json);
        }).catch((err) => {
            console.log(err);
        });
        console.log(data);
    }
    // function handleChangePassword(event) {
    //     event.preventDefault();
    //     const data = new FormData(event.currentTarget);
    //     console.log(data);
    // }

    if (isLogin() && user === null) {
        fetch(getApi() + "get-user?token=" + getLoginToken()).then((res) => {
            if (res.status !== 200) { throw res; }
            return res.json();
        }).then((data) => {
            setUser(data.message);
            return;
        }).then(() => {
            return getReferStatistics();
        }).then((json) => {
            setRefStatistics(json.message);
        }).then(() => {
            return getUserStatistics();
        }).then((json) => {
            console.log(json);
            setUserStatistics(json.message);
        }).catch((err) => {
            console.log(err);
        });
    }

    return (
        <MenuFrame warning="page_profile">
            <Helmet>
                <title>Khelboludo - Edit Profile</title>
            </Helmet>
            {user === null ? (
                <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={true}>
                    <CircularProgress color="inherit" />
                </Backdrop>
            ) :
                (<Card className="profile-pane" style={{ margin: '10px', padding: '10px', textAlign: 'center' }}>
                    <div className="profile">
                        <img src={user.profile_pic === null ? "/male-profile2.svg" : user.profile_pic} alt="profile card" />
                    </div>
                    <div className="name">{user.name}</div>
                    <Grid container spacing={2} justifyContent={"center"}>
                        <Grid item xs={6}>
                            <Typography variant="body2" color={"text.secondary"}>Game Played</Typography>
                            <Typography variant="h5"><strong>{userStatistics === null ? 0 : userStatistics.played}</strong></Typography>
                        </Grid>
                        <Grid item xs={6}>
                            <Typography variant="body2" color={"text.secondary"}>Winning</Typography>
                            <Typography variant="h5"><strong>{userStatistics === null ? 0 : userStatistics.wins}</strong></Typography>
                        </Grid>
                        <Grid item xs={6}>
                            <Typography variant="body2" color={"text.secondary"}>Referral Earning</Typography>
                            <Typography variant="h5"><strong>{refStatistics === null ? 0 : refStatistics.earn}</strong></Typography>
                        </Grid>
                        {/* <Grid item xs={6}>
                            <Typography variant="body2" color={"text.secondary"}>Referral Earning</Typography>
                            <Typography variant="h5"><strong>000</strong></Typography>
                        </Grid> */}
                    </Grid>
                    <br />
                    <div>{user.email}</div>
                    <div><strong>+91 {user.mobile}</strong></div>
                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', flexWrap: 'wrap', }}>
                        <LocationOnIcon /> {user.country}
                    </div>

                    <Container component="main" maxWidth="xs">
                        <CssBaseline />
                        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', }} mb={10}>
                            <Box component="form" noValidate onSubmit={handleSave} sx={{ mt: 3 }} id="regester-form">
                                <Grid container spacing={2}>
                                    <Grid item xs={12} sm={12}>
                                        <TextField autoComplete="given-name" name="name" required fullWidth id="name" label="Name" defaultValue={user.name} />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <TextField required fullWidth id="email" label="Email Address" name="email" autoComplete="email" defaultValue={user.email} InputProps={{ readOnly: true }} />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <TextField required fullWidth id="phone" label="Phone number" name="phone" autoComplete="phone" defaultValue={user.mobile} InputProps={{ readOnly: true, startAdornment: <InputAdornment position="start">91</InputAdornment>, }} />
                                    </Grid>
                                    <Grid item xs={3}>
                                        <FormControl fullWidth>
                                            <InputLabel id="day">Day</InputLabel>
                                            <Select labelId="day" id="day" name="day" defaultValue={user.dob === null ? '' : user.dob.split('-')[0]} label="Day" onChange={null}>
                                                {
                                                    Array.from({ length: 31 }, (_, i) => '' + (i + 1)).map((val, index) => {
                                                        return (<MenuItem value={String(val).padStart(2, '0')} key={index}>{val}</MenuItem>)
                                                    })
                                                }
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={5}>
                                        <FormControl fullWidth>
                                            <InputLabel id="month">Month</InputLabel>
                                            <Select labelId="month" id="month" name="month" defaultValue={user.dob === null ? '' : user.dob.split('-')[1]} label="Month" onChange={null}>
                                                <MenuItem value={'01'}>Jan</MenuItem>
                                                <MenuItem value={'02'}>Feb</MenuItem>
                                                <MenuItem value={'03'}>Mar</MenuItem>
                                                <MenuItem value={'04'}>Apr</MenuItem>
                                                <MenuItem value={'05'}>May</MenuItem>
                                                <MenuItem value={'06'}>Jun</MenuItem>
                                                <MenuItem value={'07'}>Jul</MenuItem>
                                                <MenuItem value={'08'}>Aug</MenuItem>
                                                <MenuItem value={'09'}>Sep</MenuItem>
                                                <MenuItem value={'10'}>Oct</MenuItem>
                                                <MenuItem value={'11'}>Nov</MenuItem>
                                                <MenuItem value={'12'}>Dec</MenuItem>
                                            </Select>
                                        </FormControl>
                                        {/* <Autocomplete disablePortal fullWidth disableClearable id="month" options={Array.from({ length: 12 }, (_, i) => '' + (i + 1))} renderInput={(params) => <TextField {...params} name="month" label="Month" />} /> */}
                                    </Grid>
                                    <Grid item xs={4}>
                                        <FormControl fullWidth>
                                            <InputLabel id="year">Year</InputLabel>
                                            <Select labelId="year" id="year" name="year" defaultValue={user.dob === null ? '' : user.dob.split('-')[2]} label="Year" onChange={null}>
                                                {
                                                    Array.from({ length: 45 }, (_, i) => '' + (i + 1980)).map((val, index) => {
                                                        return (<MenuItem value={val} key={index}>{val}</MenuItem>)
                                                    })
                                                }
                                            </Select>
                                        </FormControl>
                                        {/* <Autocomplete disablePortal fullWidth disableClearable id="year" options={Array.from({ length: 45 }, (_, i) => '' + (i + 1980))} renderInput={(params) => <TextField {...params} name="year" label="Year" />} /> */}
                                    </Grid>
                                    <Grid item xs={12} style={{ padding: '0 20px', margin: 0 }}>
                                        <FormHelperText >You must be 18 year old. KYC needed.</FormHelperText>
                                    </Grid>
                                </Grid>
                                <Button type="submit" fullWidth variant="contained" sx={{ mt: 3, mb: 2 }} >
                                    Save
                                </Button>

                                <Button fullWidth variant="contained" sx={{ mt: 3, mb: 2 }} onClick={() => { navigate("/password-change/" + getLoginToken()) }}>
                                    Change Password
                                </Button>
                            </Box>

                        </Box>
                    </Container>
                </Card>)
            }

        </MenuFrame>
    );
}


export default ProfileEdit;