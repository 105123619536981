import "../css/verification.css";
import { Alert, Button, Collapse, Link, Paper, TextField, Typography } from "@mui/material";
import { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { changeUserCred, otpResend, otpVerification } from "../shared/user";
import { trackerLog } from "../shared/tracker";
import { Helmet } from "react-helmet";


function Verification(props) {
    const [otpMsg, setOtpMsg] = useState(null);
    const [success, setSuccess] = useState(null);
    const navigate = useNavigate();

    // useEffect(() => {
    //     const inputs = document.querySelectorAll('#otp > *[id]');
    //     for (let i = 0; i < inputs.length; i++) {
    //         inputs[i].addEventListener('keydown', function (event) {
    //             if (event.key === "Backspace") {
    //                 inputs[i].value = ''; if (i !== 0) inputs[i - 1].focus();
    //             } else if ((event.ctrlKey || event.metaKey) && event.key === 'v') {
    //                 inputs[i].maxLength = "100";
    //                 return true;
    //             } else {
    //                 if (i === inputs.length - 1 && inputs[i].value !== '') { return true; }
    //                 else if (event.keyCode > 47 && event.keyCode < 58) { inputs[i].value = event.key; if (i !== inputs.length - 1) inputs[i + 1].focus(); event.preventDefault(); }
    //                 else if (event.keyCode > 64 && event.keyCode < 91) { inputs[i].value = String.fromCharCode(event.keyCode); if (i !== inputs.length - 1) inputs[i + 1].focus(); event.preventDefault(); }
    //             }
    //         });
    //     }
    //     inputs[0].addEventListener('change', function (event) {
    //         if(event.target.value.length > 1){
    //             let cp = event.target.value;
    //             cp = cp.replace(/\s/g, "");
    //             for (let k = 0; k < cp.length && k < 6; k++) {
    //                 let ch = cp[k];
    //                 inputs[k].value = ch.toUpperCase();
    //             }
    //             inputs[0].maxLength = "1";
    //             event.preventDefault();
    //         }
    //     });
    // }, []);

    const { code } = useParams();
    // const arr = code === undefined ? [] : code.toUpperCase().split("");

    function handleSubmit(e) {
        e.preventDefault();

        trackerLog("OTPPG");

        // const code = (e.target.first.value + e.target.second.value + e.target.third.value + e.target.fourth.value + e.target.fifth.value + e.target.sixth.value);
        const code = e.target.otptext.value;
        console.log(code);
        if (code.length !== 6) {
            setOtpMsg("Invalid OTP entered. Please try again.");
        } else {
            otpVerification(code, props.type)
                // fetch(getApi() + "user/otp", {
                //     method: 'POST',
                //     body: JSON.stringify({
                //         "token": getLoginToken(),
                //         "otp": code,
                //         "type": props.type
                //     })
                // }).then((res) => {
                //     return res.json();
                // }).
                .then((data) => {
                    if (data.code === 200 && data.message === 1) {
                        setSuccess("You are now verified.");
                        setOtpMsg(null);
                        navigate("/welcome")
                    } else if (props.type === "SMS") {
                        return otpVerification(code, "Mail");
                    } else {
                        setOtpMsg(data.message);
                    }
                    return null;
                }).then((data) => {
                    if (data !== null) {
                        if (data.code === 200 && data.message === 1) {
                            setSuccess("You are now verified.");
                            setOtpMsg(null);
                            navigate("/welcome")
                        } else {
                            setOtpMsg(data.message);
                        }
                    }
                }).catch((err) => {
                    console.log(err);
                });
        }
    }

    function changeContact() {
        const num = prompt("Enter your new " + (props.type === "SMS" || props.type === "LoginSMS" ? "mobile number" : "email address"));
        if (props.num === null) {
            return;
        } else if (props.type === "SMS" || props.type === "LoginSMS") {
            if (!(/^[0-9]{10}$/.test(num))) {
                setOtpMsg("Invalid indian phone number.");
                return;
            }
        } else {
            if (!(/^[a-zA-Z0-9\.]+@[a-zA-Z0-9]+\.[A-Za-z]+$/.test(num))) {
                setOtpMsg("Invalid email address.");
            }
        }
        changeUserCred(props.type, num).then((res) => {
            // console.log(res);
            if (res.code === 200) {
                setOtpMsg(res.message);
            } else {
                console.log(res);
                setOtpMsg("Error code VJS-102")
            }
        }).catch((err) => {
            console.log(err);
        });
    }

    function resendOTP() {
        otpResend(props.type).then((json) => {
            if (json.code !== 200) {
                setOtpMsg(json.message);
            }
        }).catch((err) => {
            console.log(err);
        });
        setOtpMsg("Resending OTP in your mail and SMS");
    }

    return (
        <div className="otp-verification-page">
            <Helmet>
                <title>KhelboLudo - OTP Verification</title>
            </Helmet>
            <form onSubmit={handleSubmit} style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', minHeight: '100vh' }}>
                <Paper style={{ boxShadow: '0px 5px 20px 0px #d2dae3', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', padding: '10px' }}>
                    <Typography variant="h6" textAlign={'center'} color={'red'} ><strong>Please enter the OTP <br /> to verify your account</strong></Typography>
                    <Typography variant="body2" gutterBottom> <span>A code has been sent to your {props.type === "Mail" || props.type === "LoginMail" ? "mail" : "mobile"}</span> </Typography>
                    <TextField required id="otptext" name="otptext" type="text" placeholder={props.type} defaultValue={code} />
                    {/* <div id="otp" className="inputs" >
                        <input type="text" name="first" id="first" maxLength="1" defaultValue={arr[0]} />
                        <input type="text" name="second" id="second" maxLength="1" defaultValue={arr[1]} />
                        <input type="text" name="third" id="third" maxLength="1" defaultValue={arr[2]} />
                        <input type="text" name="fourth" id="fourth" maxLength="1" defaultValue={arr[3]} />
                        <input type="text" name="fifth" id="fifth" maxLength="1" defaultValue={arr[4]} />
                        <input type="text" name="sixth" id="sixth" maxLength="1" defaultValue={arr[5]} />
                    </div> */}
                    <Typography variant="body2" textAlign={"center"} >By continuing, you agree to our <Link href="/legal/terms-and-condition">Legal Term</Link> and you are 18 year or older</Typography>
                    <Collapse in={(otpMsg !== null)}>
                        <Alert severity="warning" onClose={() => { setOtpMsg(null) }}>{otpMsg}</Alert>
                    </Collapse>
                    <Collapse in={(success !== null)}>
                        <Link href="/login" color='green' underline="none">
                            <Alert severity="success" >{success}</Alert>
                        </Link>
                    </Collapse>
                    <Collapse in={(success !== null)}>
                        <Link href="/welcome" color='green' underline="none">
                            <div style={{ padding: '20px 0' }}> <Button color="success" variant="contained" style={{ borderRadius: 20, height: 40, width: 140 }} >Dashboard</Button> </div>
                        </Link>
                    </Collapse>
                    <Collapse in={(success === null)}>
                        <div style={{ padding: '20px 0' }}> <Button type="submit" color="error" variant="contained" style={{ borderRadius: 20, height: 40, width: 140 }} >Validate</Button> </div>
                    </Collapse>
                    <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-around", width: "100%" }}>
                        <div>
                            <Button onClick={resendOTP}>Resend</Button>
                        </div>
                        <div>
                            <Button onClick={changeContact}>Change Contact</Button>
                        </div>
                        <div>
                            <Button onClick={()=>{navigate("/user/profile/logout")}}>Logout</Button>
                        </div>
                    </div>
                </Paper>
            </form>
        </div>
    );
}

export default Verification;

