import { Alert, Avatar, Box, Button, Card, CardActions, CardContent, Checkbox, CircularProgress, Collapse, Container, CssBaseline, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, FormControlLabel, Grid, LinearProgress, Switch, TextField, Typography } from "@mui/material";
import LoginFooter from "../../component/login_footer";
import MenuFrame from "../../component/menu_frame";
import WalletIcon from '@mui/icons-material/Wallet';
import { useState } from "react";
import { getApi, getLoginToken } from "../../shared/constant";
import "../../css/common.css";
import Countdown from 'react-countdown';
import { trackerLog } from "../../shared/tracker";
import { getUserData, updateName } from "../../shared/user";
import { Helmet } from "react-helmet";

function WithdrawCoin() {
    const [submit, setSubmit] = useState(false);
    const [error, setError] = useState(null);
    const [success, setSuccess] = useState(null);
    const [upi, setUPI] = useState(true);
    const [request, setRequest] = useState(null);
    const [timer, setTimer] = useState(false);
    const [user, setUser] = useState(true);
    const ONE_MIN = 60000;

    function handleSubmit(event) {
        event.preventDefault();
        setSubmit(true)
        const data = new FormData(event.currentTarget);
        let err = "";

        if (isNaN(data.get('amount')) || data.get('amount').length === 0) {
            err = "Invalid withdrawal amount. Please enter positive amount."
        } else if (data.get('amount') < 199) {
            err = "You can sell minimum 199 coin in every 1hr."
        } else if (event.target.method.checked) {
            if (!/^[a-zA-Z0-9\.]+@[a-zA-Z0-9]+$/.test(data.get('upi'))) {
                err = "Invalid UPI address"
            } else if (data.get('other_details').length < 3) {
                err = "Name on UPI is too short. It is important to identify you."
            }
        } else {
            if (data.get('bank_name').length < 5) {
                err = "Please write the full name of bank."
            } else if (data.get('ifsc').length !== 11) {
                err = "Invalid IFSC code. Please check your passbook for IFSC code."
            } else if (data.get('acc_num').length < 5) {
                err = "Invalid bank account number."
            }
        }

        if (err !== "") {
            setSubmit(false);
            setError(err);
            return;
        }

        fetch(getApi() + "withdraw/request", {
            method: 'POST',
            body: JSON.stringify({
                token: getLoginToken(),
                amount: data.get('amount'),
                method: event.target.method.checked ? "UPI" : "Bank",
                bank_name: event.target.method.checked ? null : data.get('bank_name'),
                ifsc: event.target.method.checked ? null : data.get('ifsc'),
                acc_num: event.target.method.checked ? null : data.get('acc_num'),
                upi: event.target.method.checked ? data.get('upi') : null,
                other_details: event.target.method.checked ? data.get('other_details') : null,
                comment: data.get('comment')
            })
        }).then((res) => {
            return res.json();
        }).then((res) => {
            if (res.code === 200) {
                setError(null);
                setSuccess("Your request number is RW23" + res.message);
                document.forms[0].reset();
                setRequest(null);
                localStorage.setItem('withdraw_stop', new Date().getTime() + (60 * ONE_MIN));
            } else if (res.code === 400) {
                // console.log(res);
                localStorage.setItem('withdraw_stop', new Date().getTime() + (res.wait * 1000));
                setError(res.message);
                setSuccess(null);
                setTimer(true);
            } else {
                setSuccess(null);
                setError(res.message);
            }
            setSubmit(false);
        }).catch((err) => {
            setSubmit(false);
            setError(err);
        });
    }

    function cancel(data) {
        fetch(getApi() + "/withdraw/cancel", {
            method: 'POST',
            body: JSON.stringify({
                "req_num": data.num
            })
        }).then((res) => {
            return res.json();
        }).then((json) => {
            if (json.code === 200) {
                localStorage.removeItem("withdraw_stop");
                setSubmit(false);
                setRequest(null);
                setTimer(false);
                setError(null);
                setSuccess(null);
            } else {
                alert("Unable to cancel request.")
            }
        }).catch((err) => {
            console.error(err);
        });
    }

    function handleNameSubmit(event) {
        event.preventDefault();
        setSubmit(true);
        const data = new FormData(event.currentTarget);
        if (data.get('name').trim().length < 3) {
            alert("Please enter a valid name");
            setSubmit(false);
            return;
        }

        updateName(data.get('name')).then((data) => {
            console.log(data);
            return getUserData();
        }).then((json)=>{
            setUser(json.message);
            setSubmit(false);
        }).catch((err) => {
            console.error(err);
            setSubmit(false);
        });
    }

    // if (!timer && localStorage.getItem('withdraw_stop')) {
    //     if ((new Date().getTime() < localStorage.getItem('withdraw_stop'))) {
    //         setTimer(true);
    //         // setError("You are recently submitted sell request please wait for 1hr to submit next request. आपने हाल ही में विक्रय अनुरोध सबमिट किया है, कृपया अगला अनुरोध सबमिट करने के लिए 1 घंटे तक प्रतीक्षा करें।")
    //         return;
    //     }
    // }

    if (request === null) {
        getUserData().then((json) => {
            setUser(json.message);
            console.log(json.message);
            return fetch(getApi() + "withdraw/my?token=" + getLoginToken());
        }).then((res) => {
            return res.json();
        }).then((res) => {
            // console.log(res);
            if (res.code === 200) {
                if (res.message.length === 0) {
                    localStorage.removeItem("withdraw_stop");
                    setSubmit(false);
                }
                setRequest(res.message)
            } else {
                setRequest([])
            }
            trackerLog("UWITH");
        }).catch((err) => {
            console.log(err);
        });
    }
    // console.log(request);

    const renderer = ({ hours, minutes, seconds, completed }) => {
        if (completed) {
            return "";
        } else {
            return <Typography variant="h6" textAlign={"center"} >{String((60 * hours) + minutes).padStart(2, '0')}:{String(seconds).padStart(2, '0')}</Typography>;
        }
    };

    return (
        <MenuFrame warning="page_sell">
        <Helmet>
            <title>Khelboludo - Sell Request</title>
        </Helmet>
            {user === null ? "" :
                <Dialog open={user.name === null || user.name === "" || user.name === "User" || /\d/.test(user.name)} onClose={() => { }} component="form" onSubmit={handleNameSubmit}>
                    <DialogTitle>
                        What is your name?
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText component={"div"}>
                            <Typography variant="body1" mb={2}>Please enter your full name. <em>Your full name is required for withdrawal.</em></Typography>
                            <Typography variant="subtitle2" mb={2} color={"warning.main"}>On entering wrong or invalid name, 28% GST will be charged on the withdrawal amount. गलत एवं अमान्य नाम डालने पर 28% जीएसटी निकास राशि पर लगेगा।</Typography>
                            <TextField label="Your name" variant="outlined" name="name" required fullWidth id="name" />
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button type="submit" autoFocus>Save</Button>
                    </DialogActions>
                </Dialog>
            }

            {request === null || request.length === 0 ? "" :
                <Container>
                    {
                        request.map((row, idx) => (
                            <Card key={idx}>
                                <CardContent>
                                    <Typography variant="body2" color="error" gutterBottom>
                                        Pending for approval
                                    </Typography>
                                    <Typography variant="h5" component="div">
                                        {row.method} withdraw
                                    </Typography>
                                    <Typography sx={{ mb: 1 }} color="text.secondary">
                                        RW23{row.num}
                                    </Typography>
                                    <Typography sx={{ mb: 1 }}>
                                        {
                                            row.method === "Bank" ?
                                                <>Bank Name: {row.bank_name} <br />
                                                    IFSC: {row.ifsc} <br />
                                                    Account Number: {row.acc_num} </> :
                                                <>
                                                    UPI address: {row.upi} <br />
                                                    Name on UPI: {row.other_details}
                                                </>
                                        }
                                    </Typography>
                                    <Typography variant="body2" color="success.main">
                                        Sold for coin: {row.amount}
                                    </Typography>
                                </CardContent>
                                <CardActions>
                                    <Button color="error" variant="contained" onClick={() => { cancel(row); }}>Cancel</Button>
                                </CardActions>
                            </Card>
                        ))
                    }
                </Container>
            }
            <Box sx={{ display: 'flex', flexDirection: 'column', minHeight: '100vh', }}>
                <Container component="main" maxWidth="xs" className="main-frame">
                    <Collapse in={success !== null} >
                        <Alert severity="success" onClose={() => { setSuccess(null) }}>{success}</Alert>
                    </Collapse>
                    <CssBaseline />
                    <Box sx={{ marginTop: 1, display: 'flex', flexDirection: 'column', alignItems: 'center', }} mb={10}>
                        <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
                            <WalletIcon />
                        </Avatar>
                        <Typography component="h1" variant="h5">
                            Reward Coin Sell Request
                        </Typography>
                        <Box component="form" noValidate onSubmit={handleSubmit} sx={{ mt: 3 }} id="regester-form">
                            <Grid container spacing={2}>
                                <Grid item xs={12} sm={12}>
                                    <TextField required fullWidth id="amount" label="Amount" name="amount" type="number" />
                                </Grid>
                                <Grid item xs={12} sm={12}>
                                    <FormControlLabel control={<Switch defaultChecked name="method" onChange={(e) => { setUPI(e.target.checked); }} />} label={upi ? "UPI Withdrawal" : "Bank account withdrawal"} />
                                </Grid>
                                {upi ? (
                                    <>
                                        <Grid item xs={12} sm={12}>
                                            <TextField required fullWidth id="upi" label="UPI address" name="upi" type="text" />
                                        </Grid>
                                        <Grid item xs={12} sm={12}>
                                            <TextField required fullWidth id="other_details" label="Name on UPI (यूपीआई में प्रदर्शित नाम)" name="other_details" type="text" />
                                        </Grid>
                                    </>
                                ) : (<>
                                    <Grid item xs={12} sm={12}>
                                        <TextField required fullWidth id="bank_name" label="Bank Name" name="bank_name" type="text" />
                                    </Grid>
                                    <Grid item xs={12} sm={12}>
                                        <TextField required fullWidth id="ifsc" label="IFSC Code" name="ifsc" type="text" />
                                    </Grid>
                                    <Grid item xs={12} sm={12}>
                                        <TextField required fullWidth id="acc_num" label="Account Number" name="acc_num" type="number" />
                                    </Grid>
                                </>)}
                                <Grid item xs={12} sm={12}>
                                    <TextField fullWidth id="comment" label="Comment (Optional)" name="comment" type="text" />
                                </Grid>
                                <Grid item xs={12}>
                                    <FormControlLabel control={<Checkbox value="true" color="primary" name="accept" checked={true} />} label="Withdrawal will take some time and subject to available withdrawal limit condition." />
                                    निकासी में कुछ समय लगेगा और यह उपलब्ध निकासी सीमा शर्तों के अधीन होगा।
                                </Grid>
                                <Grid item xs={12}>
                                    <Typography variant="body2" color={'error'} textAlign={'justify'}>
                                        In case of giving wrong bank or UPI, your withdrawal will go to the same and no refund will be given. <br />
                                        गलत बैंक या UPI देने पर आपका निकासी उसी में चला जयगा एवं इसके कोई रिफंड नहीं दिया जायगा।
                                    </Typography>
                                </Grid>
                            </Grid>
                            <Collapse in={error !== null} >
                                <Alert severity="error" onClose={() => { setError(null) }}>{error}</Alert>
                            </Collapse>
                            {timer ? <><Countdown date={new Date(Math.floor(localStorage.getItem("withdraw_stop")))} renderer={renderer} /><LinearProgress /></> : ""}
                            <Button type="submit" fullWidth variant="contained" sx={{ mt: 3, mb: 2 }} disabled={submit}>
                                {submit ? (<CircularProgress color="primary" size={24} />) : "Submit"}
                            </Button>
                        </Box>
                    </Box>
                </Container>
                <LoginFooter />
            </Box>
        </MenuFrame>
    );
}

export default WithdrawCoin;
