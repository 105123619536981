import { Component } from "react";
import MenuFrame from "../../component/menu_frame";
import { Container, Divider, Link, Paper, Typography } from "@mui/material";
import LoginFooter from "../../component/login_footer";
import { Helmet } from "react-helmet";

class Mail extends Component {
    state = {}
    render() {
        return (
            <MenuFrame warning="page_contact">
                <Helmet>
                    <title>Khelboludo - Mail Support</title>
                </Helmet>
                <Container maxWidth="md">
                    <div className="chat-frame">
                        <Paper elevation={2} style={{ padding: '10px', minWidth: '90%', maxWidth: '500px' }}>
                            <img src="/logo192.png" width={'128px'} alt="logo" />
                            <Typography variant="h4">khelboludo.com</Typography>
                            <Divider />
                            <Link href="mailto:info@khelboludo.com">info@khelboludo.com</Link>
                        </Paper>
                    </div>
                </Container>
                <LoginFooter />
            </MenuFrame>
        );
    }
}

export default Mail;