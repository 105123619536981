import { Box, Button, IconButton, Typography } from "@mui/material";
import NotificationsActiveIcon from '@mui/icons-material/NotificationsActive';
import NotificationsIcon from '@mui/icons-material/Notifications';
import { dismissNotification } from "../../shared/notification";

function AdminNotification(props) {
    function dismiss(nf, index) {
        dismissNotification(nf.nid).then((json) => {
            if (json.code !== 200) {
                alert(json.message);
            }
            props.onClick(index)
        }).catch((err) => {
            console.log(err);
        });
    }

    if (props.notification === null || props.notification === 0) return "";

    return (
        <Box style={{ width: "400px", maxWidth: "100%" }}>
            <Typography variant="h6">Notification</Typography>
            <Typography variant="subtitle2" color={"text.secondary"}>Important notifications refresh every 30sec</Typography>
            <Box dense={true}>
                {props.notification === null ? "" :
                    props.notification.map((row, index) => (
                        <Box disablePadding key={index} style={{ marginTop: "10px", display: "flex", justifyContent: "start", alignItems: "top" }}>
                            <IconButton color={"gray"} style={{ padding: "10px" }}>
                                {row.seen ? <NotificationsIcon /> : <NotificationsActiveIcon />}
                            </IconButton>
                            <Box>
                                <Typography variant="body2" dangerouslySetInnerHTML={{ __html: row.message }}></Typography>
                                <Box style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                                    <Typography variant="caption" color={"secondry.main"}>{row.dt}</Typography>
                                    <Button onClick={() => { dismiss(row, index); }}>Dismiss</Button>
                                </Box>
                            </Box>
                        </Box>
                    ))
                }
            </Box>
        </Box>
    );
}

export default AdminNotification;
