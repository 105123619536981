import { Component } from "react";
import { Helmet } from "react-helmet";

class Policy extends Component {
    render() {
        return (
            <iframe src="/legal/privacy-policy.html" title="privacy policy" style={{ width: "100%", height: "100vh", padding: 0, margin: 0 }}>
                <Helmet>
                    <title>Khelboludo - Privacy Policy</title>
                </Helmet>
            </iframe>
        );
    }
}

export default Policy;