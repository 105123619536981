import { Backdrop, Button, CircularProgress, Container, Typography } from "@mui/material";
import MenuFrame from "../../component/menu_frame";
import { clearApi, clearDepositWithdraw, clearLogs, clearMatches, clearOTP, clearScreenShot, clearTransection } from "../../shared/maintenance";
import { useState } from "react";
import { Helmet } from "react-helmet";

function ServerMaintenance() {
    const [submit, setSubmit] = useState(false);

    function clearLogs01() {
        setSubmit(true);
        clearLogs().then((_) => {
            setSubmit(false);
        }).catch((err) => {
            console.log(err);
            setSubmit(false);
        });
    }
    function clearApi01() {
        setSubmit(true);
        clearApi().then((_) => {
            setSubmit(false);
        }).catch((err) => {
            console.log(err);
            setSubmit(false);
        });
    }
    function clearOTP01() {
        setSubmit(true);
        clearOTP().then((_) => {
            setSubmit(false);
        }).catch((err) => {
            console.log(err);
            setSubmit(false);
        });
    }
    function clearScreenShot01() {
        setSubmit(true);
        clearScreenShot().then((_) => {
            setSubmit(false);
        }).catch((err) => {
            console.log(err);
            setSubmit(false);
        });
    }
    function clearMatch01() {
        setSubmit(true);
        clearMatches().then((_) => {
            setSubmit(false);
        }).catch((err) => {
            console.log(err);
            setSubmit(false);
        });
    }
    function clearWallet01() {
        setSubmit(true);
        clearDepositWithdraw().then((_) => {
            setSubmit(false);
        }).catch((err) => {
            console.log(err);
            setSubmit(false);
        });
    }
    function clearOldtrans01() {
        setSubmit(true);
        clearTransection().then((_) => {
            setSubmit(false);
        }).catch((err) => {
            console.log(err);
            setSubmit(false);
        });
    }

    return (
        <MenuFrame>
            <Helmet>
                <title>Critical Server Maintanance Jobs</title>
            </Helmet>
            <Container>
                <Button variant="contained" color="success" onClick={clearLogs01} style={{ marginTop: "10px" }} fullWidth>Clean Request Logs</Button>
                <Typography variant="caption">Run this every day</Typography>
                <Button variant="contained" color="success" onClick={clearApi01} style={{ marginTop: "10px" }} fullWidth>Clean Server Logs</Button>
                <Typography variant="caption">Run this every day</Typography>
                <Button variant="contained" color="success" onClick={clearOTP01} style={{ marginTop: "10px" }} fullWidth>Old OTP Clean Routine</Button>
                <Typography variant="caption">Run this in every 1-5 hour</Typography>
                <Button variant="contained" color="success" onClick={clearScreenShot01} style={{ marginTop: "10px" }} fullWidth>Disk Clean Routine</Button>
                <Typography variant="caption">Run this in every 2 day</Typography>


                <Button variant="contained" color="success" onClick={clearMatch01} style={{ marginTop: "10px" }} fullWidth>Cancel Match Clean Routine</Button>
                <Typography variant="caption">Run this every day</Typography>
                <Button variant="contained" color="success" onClick={clearWallet01} style={{ marginTop: "10px" }} fullWidth>Wallet Rejection Clean Routine</Button>
                <Typography variant="caption">Run this every day</Typography>
                <Button variant="contained" color="success" onClick={clearOldtrans01} style={{ marginTop: "10px" }} fullWidth>90 Day Transection Clean Routine</Button>
                <Typography variant="caption">Run this every day</Typography>
            </Container>
            <Backdrop open={submit} ><CircularProgress /></Backdrop>
        </MenuFrame>
    );
}

export default ServerMaintenance;