import { Alert, Avatar, Box, Button, Card, CardContent, Checkbox, CircularProgress, Collapse, Container, CssBaseline, FormControlLabel, Grid, IconButton, InputAdornment, Link, TextField, Typography } from "@mui/material";
import LoginFooter from "../../component/login_footer";
import MenuFrame from "../../component/menu_frame";
import WalletIcon from '@mui/icons-material/Wallet';
import { useState } from "react";
import { getApi, getLoginToken } from "../../shared/constant";
import "../../css/common.css";
import QRCode from "react-qr-code";
import { getAdminConfig } from "../../shared/config";
import { ContentCopy } from "@mui/icons-material";
import { trackerLog } from "../../shared/tracker";
import { Helmet } from "react-helmet";

function BuyCoin() {
    const [submit, setSubmit] = useState(false);
    const [pay, setPay] = useState(false);
    const [error, setError] = useState(null);
    const [success, setSuccess] = useState(null);
    const [amount, setAmount] = useState("");
    const [upi, setUPI] = useState(null);
    const [upiName, setUpiName] = useState(null);
    const [upiPhone, setUpiPhone] = useState(null);
    const [request, setRequest] = useState(null);
    const [defaultPayment, setDefaultPayment] = useState(null);

    function handleSubmit(event) {
        event.preventDefault();
        setSubmit(true)
        const data = new FormData(event.currentTarget);

        if (defaultPayment === 'UPI') {
            if (data.get('tran_id').length < 3 || data.get("amount") <= 0) {
                setSubmit(false);
                setError("Invalid transaction number or amount.")
                return;
            }
            fetch(getApi() + "deposit/request", {
                method: 'POST',
                body: JSON.stringify({
                    "tran_id": data.get('tran_id'),
                    "amount": data.get("amount"),
                    "token": getLoginToken()
                })
            }).then((res) => {
                return res.json();
            }).then((res) => {
                if (res.code === 200) {
                    setError(null);
                    setSuccess(res.message);
                    document.forms[0].reset();
                    setPay(false);
                    setRequest(null);
                } else {
                    setSuccess(null);
                    setError(res.message);
                }
                setSubmit(false);
            }).catch((err) => {
                setSubmit(false);
                setError(err);
            });
        } else if (defaultPayment === 'PHONEPE') {
            if (data.get("amount") <= 0) {
                setSubmit(false);
                setError("Invalid transaction amount.")
                return;
            }
            fetch(getApi() + "deposit/phonepe", {
                method: "POST",
                body: JSON.stringify({
                    amount: data.get("amount"),
                    token: getLoginToken(),
                    comment: "Deposit request raised using phonepe."
                })
            }).then((res) => {
                return res.json();
            }).then((json) => {
                // console.log(json);
                window.location.href = json.payment.redirect;
                setSubmit(false);
            }).catch((err) => {
                console.log(err);
                setSubmit(false);
            });
        }
    }

    if (upi === null) {
        getAdminConfig("upi_add,upi_name,upi_phone,default_payment").then((json) => {
            console.log(json);
            setUPI(json.message["upi_add"]);
            setUpiPhone(json.message["upi_name"]);
            setUpiName(json.message["upi_name"]);
            setDefaultPayment(json.message["default_payment"]);
            trackerLog("UDEPO");
        }).catch((err) => {
            console.log(err);
        });
    }

    if (request === null) {
        fetch(getApi() + "deposit/my?token=" + getLoginToken()).then((res) => {
            return res.json();
        }).then((res) => {
            if (res.code === 200) {
                setRequest(res.message)
            } else {
                setRequest([])
            }
        }).catch((err) => {
            console.log(err);
        });
    }
    const upiLink = "upi://pay?pa=" + upi + "&cu=INR&pn=" + upiName + "&am=" + amount;

    return (
        <MenuFrame warning="page_buy">
            <Helmet>
                <title>Khelboludo - Buy Coin</title>
            </Helmet>
            {request === null || request.length === 0 ? "" :
                <Container>
                    {
                        request.map((row, idx) => (
                            <Card key={idx}>
                                <CardContent>
                                    <Typography variant="body2" color="error" gutterBottom>
                                        Pending for approval
                                    </Typography>
                                    <Typography variant="h5" component="div">
                                        Deposit
                                    </Typography>
                                    <Typography sx={{ mb: 1 }} color="text.secondary">
                                        RQ23{row.num}
                                    </Typography>
                                    <Typography>
                                        Transaction Number : {row.tran_id}
                                    </Typography>
                                    <Typography>
                                        Status : {row.status}</Typography>
                                    {row.user_comment === null ? "" : <Typography>
                                        Comment : {row.user_comment}
                                    </Typography>}
                                    {row.admin_comment === null ? "" : <Typography sx={{ mb: 1 }}>
                                        Response : {row.admin_comment}
                                    </Typography>}
                                    <Typography variant="body2" color="success.main">
                                        Buy coin: {row.amount}
                                    </Typography>
                                </CardContent>
                            </Card>
                        ))
                    }
                </Container>
            }
            <Box sx={{ display: 'flex', flexDirection: 'column', minHeight: '100vh', }}>
                <Container component="main" maxWidth="xs" className="main-frame">
                    <Collapse in={success !== null} >
                        <Alert severity="success" onClose={() => { setSuccess(null) }}>{success}</Alert>
                    </Collapse>
                    <CssBaseline />
                    <Box sx={{ marginTop: 1, display: 'flex', flexDirection: 'column', alignItems: 'center', }} mb={10}>
                        <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
                            <WalletIcon />
                        </Avatar>
                        <Typography component="h1" variant="h5">
                            Ludo Coin Buy Request
                        </Typography>
                        <Box component="form" noValidate onSubmit={handleSubmit} sx={{ mt: 3 }} id="regester-form">
                            <Grid container spacing={2}>
                                <Grid item xs={12} sm={12}>
                                    <Collapse in={!pay}>
                                        <TextField required fullWidth id="amount" label="Amount" name="amount" type="number" onKeyUp={(e) => { setAmount(e.target.value); }} />
                                    </Collapse>
                                </Grid>
                                <Grid item xs={12}>
                                    <FormControlLabel control={<Checkbox value="true" color="primary" name="accept" checked={true} />} label="I read and agree with terms and conditions, privacy policy and refund policy." />
                                    मैं नियम और शर्ते, गोपनीयता नीति और धनवापसी नीति को पढ़ा हूं और उससे सहमत हूँ।
                                </Grid>
                                {defaultPayment === "UPI" ?
                                    <>
                                        <Grid item xs={12} style={{ textAlign: 'center' }}>
                                            <Collapse in={!pay}>
                                                {upiPhone === null ? "" : <TextField readOnly fullWidth label={"PhonePe Paytm and GPay"} variant="outlined" defaultValue={upiPhone} type={'text'} InputProps={{
                                                    readOnly: true,
                                                    endAdornment: <InputAdornment position="end">
                                                        <IconButton
                                                            onClick={() => { navigator.clipboard.writeText(upiPhone); }}
                                                            onMouseDown={(e) => { e.preventDefault(); }}
                                                            edge="end" >
                                                            <ContentCopy />
                                                        </IconButton>
                                                    </InputAdornment>,
                                                }} />}
                                                <br />
                                                <br />
                                                {upiPhone === null ? "" : <TextField readOnly fullWidth label={"UPI ID"} variant="outlined" defaultValue={upi} type={'text'} InputProps={{
                                                    readOnly: true,
                                                    endAdornment: <InputAdornment position="end">
                                                        <IconButton
                                                            onClick={() => { navigator.clipboard.writeText(upi); }}
                                                            onMouseDown={(e) => { e.preventDefault(); }}
                                                            edge="end" >
                                                            <ContentCopy />
                                                        </IconButton>
                                                    </InputAdornment>,
                                                }} />}
                                                <Link href={upiLink} underline="none">
                                                    <QRCode value={upiLink} style={{ marginTop: "10px", height: "auto", maxWidth: "100%", width: "128px" }} /><br />
                                                    Click Here to Pay
                                                </Link>
                                            </Collapse>
                                        </Grid>
                                        <Grid item xs={12} sm={12}>
                                            <Button fullWidth variant="outlined" onClick={() => { setPay(!pay); setError(null); }}> {pay ? <strong>Back</strong> : <strong>Next</strong>}</Button>
                                        </Grid>
                                    </> :
                                    <Grid item xs={12} sm={12}>

                                        <Button type="submit" fullWidth variant="contained" sx={{ mt: 3, mb: 2 }} disabled={submit}>
                                            {submit ? (<CircularProgress color="primary" size={24} />) : "Continue"}
                                        </Button>
                                    </Grid>
                                }
                                <Grid item xs={12} sm={12}>
                                    <Collapse in={pay}>
                                        <TextField name="tran_id" required fullWidth id="tran_id" label="Transaction Number" type="text" autoFocus />
                                    </Collapse>
                                </Grid>
                            </Grid>
                            <Collapse in={error !== null} style={{ marginTop: "5px" }}>
                                <Alert severity="error" onClose={() => { setError(null) }}>{error}</Alert>
                            </Collapse>
                            <Collapse in={pay}>
                                <Button type="submit" fullWidth variant="contained" sx={{ mt: 3, mb: 2 }} disabled={submit}>
                                    {submit ? (<CircularProgress color="primary" size={24} />) : "Submit"}
                                </Button>
                            </Collapse>
                        </Box>
                    </Box>
                </Container>
                <LoginFooter />
            </Box>
        </MenuFrame>
    );
}

export default BuyCoin;
