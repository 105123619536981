import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import UserDashboard from './routes/user_dashboard';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter, Route, Routes, Navigate } from 'react-router-dom';
import Login from './routes/login';
import Terms from './routes/legal/terms';
import Refund from './routes/legal/refund';
import Policy from './routes/legal/policy';
import Regester from './routes/regester';
import ForgotPassword from './routes/forgot';
import Games from './routes/user/game';
import History from './routes/user/history';
import Wallet from './routes/user/wallet';
import Transaction from './routes/user/transaction';
import DocumentKYC from './routes/user/kyc';
import Refer from './routes/user/refer';
import ProfileEdit from './routes/user/profile/edit';
import Accounts from './routes/user/profile/accounts';
import Logout from './routes/user/profile/logout';
import Chat from './routes/user-support/chat';
import Mail from './routes/user-support/mail';
import Contact from './routes/contact';
import UserList from './routes/admin/user-list';
import PasswordChange from './routes/password_change';
import ProfitLoss from './routes/admin/profit-loss';
import { ADMIN, MANAGER, PARTNER, USER, getAuthentication, isLogin } from './shared/constant';
import { ThemeProvider, createTheme } from '@mui/material';
import Verification from './routes/verification';
import WelcomeUser from './routes/welcome';
import Dashboard from './routes/dashboard';
import GameRoom from './routes/user/game_room';
import ChallengeRoom from './routes/user/challenge_room';
import LuckyDraw from './routes/lucky_draw';
import OngoingMatch from './routes/user/ongoing_match';
import BuyCoin from './routes/user/buy_coin';
import WithdrawCoin from './routes/user/withdraw_coin';
import WithdrowApprove from './routes/admin/withdraw_approve';
import DepositApprove from './routes/admin/deposit_approve';
import ConflictResolution from './routes/admin/conflict_resolution';
import AdminSetting from './routes/admin/admin_setting';
import SellHistory from './routes/user/sell_history';
import BuyHistory from './routes/user/buy_history';
import DebitNote from './routes/admin/debit_note';
import CreditNote from './routes/admin/credit_note';
import AdminTransaction from './routes/admin/admin_transaction';
import NotFound from './NotFound';
import Server from './routes/admin/server';
import ApiLog from './routes/admin/api_log';
import RequestLog from './routes/admin/request_log';
import ServerMaintenance from './routes/admin/server_maintenance';
import VisitorsReport from './routes/admin/visitors-report';
import HistoryMatch from './routes/admin/history_match';
import MatchDisplay from './routes/match_display';
import PageWarning from './routes/admin/page_warning';
import SetRoomCode from './routes/user/set_roomcode';
import PublicProfile from './routes/user/profile/public';

const theme = createTheme({
  typography: {
    button: {
      textTransform: 'none'
    },
    link: {
      color: 'red'
    }
  },
  palette: {
    appbar: {
      main: '#FFFFFFEE',
      darker: '#000000DD',
    },
  },
});

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <ThemeProvider theme={theme}>
      <BrowserRouter>
        <Routes>
          <Route path="*" element={<NotFound />} />
          <Route path="/" element={isLogin() ? <UserDashboard /> : <Dashboard />} />
          <Route path="/login" element={isLogin() ? <Navigate to="/" /> : <Login />} />
          <Route path="/register/:code?" element={<Regester noname={true} nopass={true} nomail={true} />} />
          <Route path="/register/n/:code?" element={<Regester noname={false} nopass={true} nomail={true} />} />
          <Route path="/register/np/:code?" element={<Regester noname={false} nopass={false} nomail={true} />} />
          <Route path="/register/nmp/:code?" element={<Regester noname={false} nopass={false} nomail={false} />} />
          <Route path="/register/mp/:code?" element={<Regester noname={true} nopass={false} nomail={false} />} />
          <Route path="/forgot-password" element={<ForgotPassword />} />
          <Route path="/password-change/:hash" element={<PasswordChange />} />

          <Route path='/user/public/:username' element={<PublicProfile />} />
          <Route path='/user' element={isLogin() ? '' : <Navigate to="/login" />}>
            <Route path='games/ongoing/:pid' element={<OngoingMatch />} />
            <Route path='games/roomcode/:access/:match' element={<SetRoomCode />} />
            <Route path='games' element={getAuthentication() === USER ? <Games /> : <UserDashboard />} />
            <Route path='games/:game' element={getAuthentication() === USER ? <GameRoom /> : <UserDashboard />} />
            <Route path='games/challenge/:game' element={getAuthentication() === USER ? <ChallengeRoom /> : <UserDashboard />} />
            <Route path='history' element={<History />} />
            <Route path='wallet' element={getAuthentication() === USER ? <Wallet /> : <WithdrawCoin />} />
            <Route path='buy' element={<BuyCoin />} />
            <Route path='buy-history' element={<BuyHistory />} />
            <Route path='withdraw' element={<WithdrawCoin />} />
            <Route path='withdraw-history' element={<SellHistory />} />
            <Route path='transaction' element={<Transaction />} />
            <Route path='kyc' element={<DocumentKYC />} />
            <Route path='refer' element={<Refer />} />
            <Route path='profile'>
              <Route path='edit' element={<ProfileEdit />} />
              <Route path='accounts' element={<Accounts />} />
              <Route path='logout' element={<Logout />} />
            </Route>
          </Route>
          <Route path='/admin' element={isLogin() && getAuthentication() === ADMIN ? '' : <Navigate to="/login" />}>
            <Route path='server' element={<Server />} />
            <Route path='api-log' element={<ApiLog />} />
            <Route path='request-log' element={<RequestLog />} />
            <Route path='server-maintenance' element={<ServerMaintenance />} />
            <Route path='visitors-report' element={<VisitorsReport />} />
          </Route>
          <Route path='/admin' element={isLogin() && (getAuthentication() === PARTNER || getAuthentication() === ADMIN) ? '' : <Navigate to="/login" />}>
            <Route path='transaction/:id?' element={<AdminTransaction />} />
            <Route path='setting' element={<AdminSetting />} />
            <Route path='page-warning' element={<PageWarning />} />
          </Route>
          <Route path='/admin' element={isLogin() && (getAuthentication() === PARTNER || getAuthentication() === ADMIN || getAuthentication() === MANAGER) ? '' : <Navigate to="/login" />}>
            <Route path='withdraw-approve' element={<WithdrowApprove />} />
            <Route path='deposit-approve' element={<DepositApprove />} />
            <Route path='conflict-resolution' element={<ConflictResolution type="Conflict" />} />
            <Route path='ongoing-match' element={<ConflictResolution type="Ongoing" />} />
            <Route path='history-match' element={<HistoryMatch />} />
            <Route path='debit-note' element={<DebitNote />} />
            <Route path='credit-note' element={<CreditNote />} />
          </Route>
          <Route path='/support' element={isLogin() ? '' : <Navigate to="/login" />}>
            <Route path='mail' element={<Mail />} />
            <Route path='livechat' element={<Chat />} />
          </Route>
          <Route path='/legal'>
            <Route path="privacy-policy" element={<Policy />} />
            <Route path="terms-and-condition" element={<Terms />} />
            <Route path="refund-policy" element={<Refund />} />
          </Route>
          <Route path='/admin'>
            <Route path="profit-and-loss" element={<ProfitLoss />} />
            <Route path="user-list" element={<UserList />} />
          </Route>
          <Route path='/welcome' element={<WelcomeUser />} />
          <Route path='/contact' element={<Contact />} />
          <Route path='/verification/mail/:code?' element={<Verification type="Mail" />} />
          <Route path='/verification/mobile/:code?' element={<Verification type="SMS" />} />
          <Route path='/verification/login-mail/:code?' element={<Verification type="LoginMail" />} />
          <Route path='/verification/login-sms/:code?' element={<Verification type="LoginSMS" />} />
          <Route path='/lucky-draw' element={<LuckyDraw />} />
          <Route path='/match-display' element={<MatchDisplay />} />
        </Routes>
      </BrowserRouter>
    </ThemeProvider>
  </React.StrictMode>
);


// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
