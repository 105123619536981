import { Button, Container, Typography } from "@mui/material";
import MenuFrame from "../../component/menu_frame";
import DataTable from "react-data-table-component";
import 'animate.css';
import { useState } from "react";
import { getApi } from "../../shared/constant";
import { Helmet } from "react-helmet";

function VisitorsReport(props) {
    const [visitors, setVisitors] = useState(null);

    const columns = [
        { name: 'Page Name', sortable: true, wrap: true, minWidth: "150px", selector: row => row.name, },
        { name: 'Description', wrap: true, minWidth: "300px", selector: row => row.description, },
        { name: 'Hit Count', sortable: true, wrap: true, maxWidth: "120px", right: true, selector: row => row.hit_count, },
        { name: 'Unique', sortable: true, wrap: true, maxWidth: "100px", right: true, selector: row => row.unique_hit, },
        { name: 'Last Hit', sortable: true, wrap: true, width: "200px", selector: row => row.dt === "01-Jan-1970 05:30 AM" ? "" : row.dt, },
    ];

    if (visitors === null) {
        initData();
    }

    function initData() {
        fetch(getApi() + "traffic/get-all").then((res) => {
            return res.json();
        }).then((json) => {
            if (json.code === 200) {
                setVisitors(json.message);
            } else {
                setVisitors([]);
            }
        }).catch((err) => {
            console.log(err);
        });
    }

    function reset() {
        fetch(getApi() + "traffic/reset", { method: "DELETE" }).then((res) => {
            return res.text();
        }).then((_) => {
            initData();
        }).catch((err) => {
            console.log(err);
        })
    }

    return (
        <MenuFrame>
            <Helmet>
                <title>Khelboludo Admin - Visitors list</title>
            </Helmet>
            <Container>
                <DataTable
                    title="User Page Hit Count"
                    dense={true}
                    columns={columns}
                    data={visitors === null ? [] : visitors}
                    progressPending={visitors === null}
                    pagination
                    paginationPerPage={15}
                    className="animate__animated animate__fadeIn"
                />
                <Button color="warning" variant="contained" onClick={reset}>Reset All Counter</Button><br />
                <Typography variant="caption" color="warning.main">It will reset all counter, new visitor might not logged as new untill they close browser.</Typography>
            </Container>
        </MenuFrame>
    );
}

export default VisitorsReport;
