import { Alert, AlertTitle, Avatar, Box, CircularProgress, Container, CssBaseline, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, FormHelperText, Grid, IconButton, InputAdornment, TextField, Typography } from "@mui/material";
import { useState } from "react";
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Link from '@mui/material/Link';
import Button from '@mui/material/Button';
import { getApi } from "../shared/constant";
import { useNavigate, useParams } from "react-router-dom";
import FooterRegister from "../component/register_footer";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import 'animate.css';
import { trackerLog } from "../shared/tracker";
import { Helmet } from "react-helmet";

//Hide textbox on filling refer code and show name

function SuccessAlert(props) {
    const navigate = useNavigate();
    return (
        <Dialog open={props.open} onClose={() => { navigate("/verification/mobile"); }}>
            <DialogTitle>
                Account created
            </DialogTitle>
            <DialogContent>
                <DialogContentText>
                    <Typography variant="body1" component="span" gutterBottom>Congratulations! you are successfully created your account in khelboludo. We recommend to view onboarding video and connect to your mentor before playing the game.<br /><small>This game involves financial risk and can be addictive. Please play responsibly and at your own risk.</small></Typography>
                    <Typography variant="body1" component="span" gutterBottom>बधाई हो! आपने खेलबोलुडो में अपना खाता सफलतापूर्वक बना लिया है। हम गेम खेलने से पहले ऑनबोर्डिंग वीडियो देखने और अपने मार्गदर्शक से जुड़ने की सलाह देते हैं।<br /><small>इस खेल में वित्तीय जोखिम शामिल है और इसकी लत लग सकती है। कृपया जिम्मेदारी से और अपने जोखिम पर खेलें।</small></Typography>
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button autoFocus onClick={() => { navigate("/verification/mobile"); }}>
                    Go to Login
                </Button>
            </DialogActions>
        </Dialog>
    )
}

function Regester(param) {
    console.log(param);
    const { code } = useParams();
    const [submit, setSubmit] = useState(false);
    const [fNameErr, setFnameErr] = useState(null);
    const [lNameErr, setLnameErr] = useState(null);
    const [emailErr, setEmailErr] = useState(null);
    const [phoneErr, setPhoneErr] = useState(null);
    // const [doberr, set] = useState(null);
    // const [derr, set] = useState(null);
    // const [yerr, set] = useState(null);
    const [passErr, setPassErr] = useState(null);
    const [repassErr, setRepassErr] = useState(null);
    const [policyErr, setPolicyErr] = useState(false);
    const [referErr, setReferErr] = useState(null);
    const [successAlert, setSuccessAlert] = useState(false);
    const [failAlert, setFailAlert] = useState(null);

    const [showPassword, setShowPassword] = useState(false);
    const handleClickShowPassword = () => setShowPassword((show) => !show);
    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

    function handleSubmit(event) {
        event.preventDefault();
        setSubmit(true)
        const data = new FormData(event.currentTarget);
        let flag = false;

        trackerLog("RGSTR");

        if (!param.noname && data.get('firstName').trim().length < 3) {
            setFnameErr("Please enter a valid name");
            flag = true;
        } else {
            setFnameErr(null);
        }
        if (!param.noname && (data.get('lastName').trim().length < 3 || data.get('lastName').trim().includes(" "))) {
            setLnameErr("Surname should not have space.");
            flag = true;
        } else {
            setLnameErr(null);
        }
        if (!param.nomail) {
            if (data.get('email') === '') {
                if (data.get('phone') === '') {
                    setEmailErr("Email or phone number required.");
                }
            } else if (/^[a-zA-Z0-9\.]+@[a-zA-Z0-9]+\.[A-Za-z]+$/.test(data.get('email'))) {
                setEmailErr(null);
            } else {
                setEmailErr("Invalid email address. Ex: name@example.com");
                flag = true;
            }
        }
        if (/^[0-9]{10}$/.test(data.get('phone'))) {
            setPhoneErr(null);
        } else {
            setPhoneErr("Invalid indian phone number.");
            flag = true;
        }
        // if (data.get('day') === '' || data.get('month') === '' || data.get('year') === '') {
        //     this.setState({ doberr: "Incorrect date of birth. You must be 18 year old.", derr: true, merr: true, yerr: true });
        //     flag = true;
        // } else {
        //     let today = new Date(data.get('year'), data.get('month') - 1, data.get('day'));
        //     if (today.getDate() + '' !== data.get('day')) {
        //         this.setState({ doberr: "Invalid date", derr: true, merr: true, yerr: true });
        //         flag = true;
        //     } else {
        //         this.setState({ doberr: null, derr: false, merr: false, yerr: false });
        //     }
        // }
        if (!param.nopass && data.get('password').length < 6) {
            setPassErr("Password at least 6 characters long.");
            flag = true;
        } else if (!param.nopass && data.get('password') !== data.get('repassword')) {
            setPassErr("Password and repeat password are not matching");
            flag = true;
        } else {
            setPassErr(null);
        }
        if (!data.get('accept')) {
            setPolicyErr(true);
            flag = true;
        } else {
            setPolicyErr(false);
        }

        if (flag === true) {
            setSubmit(false);
            return;
        }

        // const dob_u = new Date(data.get('year'), data.get('month') - 1, data.get('day'));
        const json_data = {
            name: param.noname ? "" : data.get('firstName').trim() + " " + data.get('lastName').trim(),
            password: param.nopass ? "" : data.get('password'),
            // dob: dob_u.valueOf() / 1000,
            refer: data.get('refer').trim(),
            mobile: data.get('phone').trim(),
            email: param.nomail || data.get('email') === "" ? null : data.get('email').trim()
        }
        // console.log(json_data);

        fetch(getApi() + "new-user", {
            method: 'POST',
            body: JSON.stringify(json_data)
        }).then((res) => {
            return res.json();
        }).then((data) => {
            if (data.code === 200) {
                setSubmit(false);
                setSuccessAlert(true);
                document.getElementById("regester-form").reset();
                localStorage.setItem("token", data.message.token);
                localStorage.setItem("auth", data.message.auth);            
            } else {
                setSubmit(false);
                setFailAlert(data.message);
            }
        }).catch((err) => {
            console.error(err);
            setSubmit(false);
            setFailAlert(true);
        });
    };

    return (
        <Box sx={{ display: 'flex', flexDirection: 'column', minHeight: '100vh', }}>
            <Helmet>
                <title>Regester</title>
                <meta name="title" content="Regester new user" />
                <meta name="description" content="Sign up and win exciting prize" />
                <meta name="keywords" content="join,regester,signup,khelboludo,ludo,khelbo,playing,game,wins,ludo game,game"></meta>
            </Helmet>
            <Container component="main" maxWidth="xs">
                <CssBaseline />
                <Box sx={{ marginTop: 8, display: 'flex', flexDirection: 'column', alignItems: 'center', }} mb={10}>
                    <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
                        <LockOutlinedIcon />
                    </Avatar>
                    <Typography component="h1" variant="h5">
                        Create an new account
                    </Typography>
                    <Box component="form" noValidate onSubmit={handleSubmit} sx={{ mt: 3 }} id="regester-form">
                        <Grid container spacing={2} className="animate__animated animate__fadeIn">
                            {param.noname === true ? "" :
                                <>
                                    <Grid item xs={12} sm={6}>
                                        <TextField autoComplete="given-name" className="animate__animated animate__fadeInLeft" style={{ animationDelay: "0s" }} name="firstName" required fullWidth id="firstName" label="First Name" autoFocus helperText={fNameErr} error={fNameErr != null} />
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <TextField required fullWidth id="lastName" className="animate__animated animate__fadeInLeft" style={{ animationDelay: "0.1s" }} label="Last Name" name="lastName" autoComplete="family-name" helperText={lNameErr} error={lNameErr != null} />
                                    </Grid>
                                </>
                            }
                            {param.nomail === true ? "" :
                                <Grid item xs={12}>
                                    <TextField fullWidth id="email" className="animate__animated animate__fadeInLeft" style={{ animationDelay: "0.2s" }} label="Email Address" name="email" autoComplete="email" helperText={emailErr} error={emailErr != null} />
                                </Grid>
                            }
                            <Grid item xs={12}>
                                <TextField required fullWidth className="animate__animated animate__fadeInLeft" style={{ animationDelay: "0.3s" }} id="phone" label="Phone number" name="phone" autoComplete="phone" helperText={phoneErr} error={phoneErr != null} InputProps={{ startAdornment: <InputAdornment position="start">91</InputAdornment>, }} />
                            </Grid>
                            {/* <Grid item xs={3}>
                                    <Autocomplete disablePortal fullWidth disableClearable id="day" options={Array.from({ length: 31 }, (_, i) => '' + (i + 1))} renderInput={(params) => <TextField {...params} name="day" error={this.state.derr} label="Day" />} />
                                </Grid>
                                <Grid item xs={5}>
                                    <Autocomplete disablePortal fullWidth disableClearable id="month" options={Array.from({ length: 12 }, (_, i) => '' + (i + 1))} renderInput={(params) => <TextField {...params} name="month" error={this.state.merr} label="Month" />} />
                                </Grid>
                                <Grid item xs={4}>
                                    <Autocomplete disablePortal fullWidth disableClearable id="year" options={Array.from({ length: 45 }, (_, i) => '' + (i + 1980))} renderInput={(params) => <TextField {...params} name="year" error={this.state.yerr} label="Year" />} />
                                </Grid>
                                <Grid item xs={12} style={{ padding: '0 20px', margin: 0 }}>
                                    <FormHelperText error={this.state.doberr != null}>{this.state.doberr === null ? "You must be 18 year old to create account." : this.state.doberr}</FormHelperText>
                                </Grid> */}
                            {
                                param.nopass === true ? "" :
                                    <>
                                        <Grid item xs={12}>
                                            <TextField required fullWidth className="animate__animated animate__fadeInLeft" style={{ animationDelay: "0.4s" }} name="password" label="Password" type={showPassword ? 'text' : 'password'} InputProps={{
                                                endAdornment: <InputAdornment position="end">
                                                    <IconButton
                                                        aria-label="toggle password visibility"
                                                        onClick={handleClickShowPassword}
                                                        onMouseDown={handleMouseDownPassword}
                                                        edge="end" >
                                                        {showPassword ? <VisibilityOff /> : <Visibility />}
                                                    </IconButton>
                                                </InputAdornment>,
                                            }} id="password" autoComplete="new-password" helperText={passErr} error={passErr != null} />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <TextField required className="animate__animated animate__fadeInLeft" style={{ animationDelay: "0.5s" }} fullWidth name="repassword" label="Type your password again" type="password" id="repassword" autoComplete="new-password" />
                                        </Grid>
                                    </>
                            }
                            <Grid item xs={12}>
                                <TextField fullWidth id="refer" className="animate__animated animate__fadeInLeft" style={{ animationDelay: "0.6s" }} label="Referral Code (Optional)" name="refer" helperText={referErr} error={referErr != null} defaultValue={code} />
                            </Grid>
                            <Grid item xs={12}>
                                <FormControlLabel control={<Checkbox value="true" color="primary" name="accept" />} label="I read and agree with terms and conditions, privacy policy and refund policy." />
                                मैं नियम और शर्ते, गोपनीयता नीति और धनवापसी नीति को पढ़ा हूं और उससे सहमत हूँ।
                                <FormHelperText error>{policyErr ? "Please accept the above" : ""}</FormHelperText>
                            </Grid>
                        </Grid>
                        <Button type="submit" fullWidth variant="contained" sx={{ mt: 3, mb: 2 }} disabled={submit}>
                            {submit ? (<CircularProgress color="primary" size={24} />) : ("Sign Up")}
                        </Button>
                        <Grid container justifyContent="flex-end">
                            <Grid item>
                                <Link href="/login" variant="body2">
                                    Already have an account? Sign in
                                </Link>
                            </Grid>
                        </Grid>
                    </Box>
                </Box>
            </Container>

            <SuccessAlert open={successAlert} />

            <Dialog open={failAlert !== null} onClose={() => setFailAlert(null)}>
                <Alert severity="error">
                    <AlertTitle>Error in creating account</AlertTitle>
                    Please resolve the issue <strong>{failAlert}</strong>
                </Alert>
                <DialogActions>
                    <Button onClick={() => setFailAlert(null)} autoFocus>
                        Exit
                    </Button>
                </DialogActions>
            </Dialog>
            <FooterRegister />
        </Box>

    );
}

export default Regester;