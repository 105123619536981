import { getApi } from "./constant";

export function trackerLog(screen) {
    return fetch(getApi() + "traffic/log", {
        method: "POST",
        body: JSON.stringify({ "page": screen })
    }).then((res) => {
        return true;
    });
}
