import { useState } from "react";
import MenuFrame from "../../component/menu_frame";
import DataTable from 'react-data-table-component';
import { getApi, getLoginToken } from "../../shared/constant";
import ReplayIcon from '@mui/icons-material/Replay';
import { Button, Link } from "@mui/material";
import { SyncTransaction } from "../../shared/payments";
import { Helmet } from "react-helmet";

const RowComponent = ({ data }) => {
    return (<>
        <div>Transaction ID: {data.tran_id}</div>
        <div>Note: {data.user_comment}</div>
        <div>Admin: {data.admin_comment}</div>
    </>);
}

function BuyHistory() {
    const [history, setHistory] = useState(null);

    const columns = [
        { name: 'ID', selector: row => "RD23" + row.num, },
        { name: 'Date', selector: row => row.dt, },
        { name: 'Method', selector: row => row.method, },
        { name: 'Coin', selector: row => row.amount, },
        { name: 'Status', minWidth: "200px", selector: row => <div>{row.method === 'PhonePe' && row.status === 'Awaiting' ? <><Link href={row.pay_link} underline="none">Retry</Link> <Button variant="text" onClick={() => { reSyncTransc(row.tran_id); }} ><ReplayIcon /></Button></> : row.status}</div>, },
    ];

    if (history === null) {
        fetch(getApi() + "deposit/history?token=" + getLoginToken()).then((res) => {
            return res.json();
        }).then((json) => {
            // console.log(json.message);
            setHistory(json.message);
        }).catch((e) => {
            console.error(e);
        });
    }

    function reSyncTransc(num) {
        SyncTransaction(num).then((json) => {
            if (json.code === 200) {
                setHistory(null);
            } else {
                alert(json.message)
            }
            // console.log(json);
        }).catch((e) => {
            console.error(e);
        });
    }


    return (
        <MenuFrame>
            <Helmet>
                <title>Khelboludo - Coin Buy History</title>
            </Helmet>
            <DataTable
                // dense={true}
                columns={columns}
                data={history === null ? [] : history}
                progressPending={history === null}
                expandableRows
                expandableRowsComponent={RowComponent}
                pagination
                paginationPerPage={15}
            />
        </MenuFrame>
    );
}
export default BuyHistory;