import { Component } from "react";
import MenuFrame from "../component/menu_frame";
import { Container, Divider, Grid, Link, Paper, Typography } from "@mui/material";
import "../css/contact.css";
import LoginFooter from "../component/login_footer";
import { ADMIN, getAuthentication } from "../shared/constant";
import { Helmet } from "react-helmet";

class Contact extends Component {
    state = {}
    render() {
        return (
            <MenuFrame warning="page_contact">
                <Helmet>
                    <title>Khelboludo - Contact</title>
                </Helmet>
                <Container maxWidth='lg'>
                    <div className="contact-mid">
                        <Grid container spacing={2}>
                            <Grid item xs={12} sm={6}>
                                <Paper style={{ padding: '10px' }}>
                                    <Typography variant="h5" className="name"> <img src="/logo192.png" width={'50px'} alt="khelboludo logo" /> Infomation Desk</Typography>
                                    <Divider />
                                    <Typography variant="body1"><strong>Helpdesk</strong></Typography>
                                    <Typography variant="body2">Khelludo infomation helpdesk</Typography>
                                    <Typography variant="body2">India</Typography>
                                    <Link href="mailto:info@khelboludo.com">info@khelboludo.com</Link>
                                </Paper>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <Paper style={{ padding: '10px' }}>
                                    <Typography variant="h5" className="name"> <img src="/logo192.png" width={'50px'} alt="Khelboludo logo" /> Process and Operation</Typography>
                                    <Divider />
                                    <Typography variant="body1"><strong>Operation manager</strong></Typography>
                                    <Typography variant="body2">Jharkhand</Typography>
                                    <Typography variant="body2">India</Typography>
                                    <Link href="mailto:operation@khelboludo.com">operation@khelboludo.com</Link>
                                </Paper>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <Paper style={{ padding: '10px' }}>
                                    <Typography variant="h5" className="name"> <img src="/logo192.png" width={'50px'} alt="Khelboludo logo" /> Sales</Typography>
                                    <Divider />
                                    <Typography variant="body1"><strong>Sales manager</strong></Typography>
                                    <Typography variant="body2">Regional sales department</Typography>
                                    <Typography variant="body2">India</Typography>
                                    <Link href="mailto:sales@khelboludo.com">sales@khelboludo.com</Link>
                                </Paper>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <Paper style={{ padding: '10px' }}>
                                    <Typography variant="h5" className="name"> <img src="/logo192.png" width={'50px'} alt="Khelboludo logo" /> Purchase</Typography>
                                    <Divider />
                                    <Typography variant="body1"><strong>Purchase manager</strong></Typography>
                                    <Typography variant="body2">Delhi</Typography>
                                    <Typography variant="body2">India</Typography>
                                    <Link href="mailto:purchase@khelboludo.com">purchase@khelboludo.com</Link>
                                </Paper>
                            </Grid>
                            {getAuthentication() === ADMIN ?
                                <Grid item xs={12} sm={6}>
                                    <Paper style={{ padding: '10px' }}>
                                        <Typography variant="h5" className="name"> <img src="/logo192.png" width={'50px'} alt="Khelboludo logo" /> Webmaster</Typography>
                                        <Divider />
                                        <Typography variant="body1"><strong>Admin helpdesk</strong></Typography>
                                        <Typography variant="body2">Khelboludo webmaster</Typography>
                                        <Typography variant="body2">India</Typography>
                                        <Link href="mailto:admin@khelboludo.com">admin@khelboludo.com</Link>
                                    </Paper>
                                </Grid> : ""}
                        </Grid>
                    </div>
                </Container>
                <LoginFooter />
            </MenuFrame>
        );
    }
}

export default Contact;