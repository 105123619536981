import React, { useEffect, useState } from "react";
import MenuFrame from "../../component/menu_frame";
import Typography from '@mui/material/Typography';
import { Alert, Backdrop, Button, CircularProgress, Collapse, Container, Divider, Grid, Paper } from "@mui/material";
import { getApi, getLoginToken } from "../../shared/constant";
import { useNavigate, useParams } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import EmojiEventsIcon from '@mui/icons-material/EmojiEvents';
import LoginFooter from "../../component/login_footer";
import "../../css/common.css";
import { RedirectOnGameOngoing } from "../../shared/redirect_manager";
import { cancelBeforeJoin, getGameLiteRooms, getMyMatches } from "../../shared/match_methods";
import 'animate.css';
import { trackerLog } from "../../shared/tracker";
import { Helmet } from "react-helmet";

function GameRoom() {
    const navigate = useNavigate();
    const [submit, setSubmit] = useState(false);
    const [gameRooms, setGameRooms] = useState(null);
    const [curGame, setCurGame] = useState(null);
    const [gameError, setGameError] = useState(null);
    const { game } = useParams();

    useEffect(() => {
        // 1 min reload time for games room, page reload
        const interval = setInterval(async () => {
            if (curGame !== null) {
                await getMyMatches().then((res) => {
                    if (res.code === 200) { 
                        setCurGame(res.message); 
                    }
                }).catch((err) => {
                    console.error(err);
                });
            }
            getGameLiteRooms(game).then((data) => {
                if (data.code === 200)
                    setGameRooms(data.message);
            }).catch((err) => {
                console.error(err);
            });
        }, 30000);
        return () => clearInterval(interval);
    }, [curGame, game]);

    function initData() {
        getMyMatches().then((data) => {
            if (data.code === 200) { 
                setCurGame(data.message); 
            }
            return getGameLiteRooms(game);
        }).then((data) => {
            setGameRooms(data.message);
            if (!localStorage.getItem("LM001") || new Date().getTime() - localStorage.getItem("LM001") > 3600000) {
                localStorage.setItem("LM001", new Date().getTime())
                toast('🏆 You can play 50-500 game in lite mode!', {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                    toastId: 'LM001',
                });
            }
        }).catch((err) => {
            console.error(err);
        });
    }

    function cancelBefore() {
        cancelBeforeJoin(curGame.pid).then((data) => {
            if (data.code === 200)
                setCurGame(null);
        }).catch((err) => {
            console.error(err);
        });
    }

    async function createMatch(room) {
        setSubmit(true);
        if (curGame !== null) {
            await cancelBefore();
        }
        fetch(getApi() + "games/create-match", {
            method: "POST",
            body: JSON.stringify({
                token: getLoginToken(),
                room: room
            })
        }).then((res) => {
            return res.json();
        }).then((data) => {
            if (data.code === 200 && data.status === 'waiting') {
                navigate("/user/games/roomcode/user/" + data.message)
            } else if (data.code === 200) {
                setGameRooms(null);
            } else {
                setGameError(data.message);
            }
            setSubmit(false);
            trackerLog("LTROM");
        }).catch((err) => {
            console.error(err);
            setSubmit(false);
        })
    }

    function isOngoing(room) {
        return curGame !== null && curGame.room_id === room.room_id;
    }

    if (gameRooms === null) {
        initData();
    }

    return (
        <MenuFrame warning="page_lite">
            <Helmet>
                <title>Khelboludo - Online Games</title>
            </Helmet>
            <RedirectOnGameOngoing pid={(curGame !== null && curGame.game_room_code !== null) ? curGame.pid : null} />
            <ToastContainer position="top-right" autoClose={5000} hideProgressBar={false} newestOnTop={false} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover theme="light" />
            {/* <CurrentGameAlert data={curGmAlert} /> */}

            <Container className="main-frame">
                <Collapse in={gameError !== null} >
                    <Alert severity="error"><div dangerouslySetInnerHTML={{ __html: gameError }}></div></Alert>
                </Collapse>
                <Grid container spacing={2}>
                    {gameRooms === null ? <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={true}><CircularProgress color="inherit" /></Backdrop> :
                        gameRooms.map((room, idx) => (
                            <Grid key={idx} item xs={12} sm={6}>
                                <Paper style={{ padding: '10px', animationDelay: (0.1 * idx) + "s" }} className="animate__animated animate__fadeIn">
                                    <div>
                                        <Typography variant="subtitle1" component={'span'}><strong>{room.name}</strong> &nbsp;</Typography>
                                        <Typography variant="body2" component={'span'}>{room.online + 2} online</Typography>
                                    </div>
                                    <Divider />
                                    <Typography variant="h4" style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-around' }}><EmojiEventsIcon color="warning" fontSize="large" /> <span>{room.entry_fee}</span></Typography>

                                    {isOngoing(room) ?
                                        <Button fullWidth variant="contained" color="success" onClick={() => cancelBefore()} disabled={submit}>
                                            <CircularProgress color="warning" size={24} /> &nbsp; Waiting for opponent
                                        </Button> :
                                        <Button fullWidth variant="contained" color="info" onClick={() => createMatch(room.room_id)} disabled={submit}>
                                            {submit ? (<CircularProgress color="primary" size={24} />) : "Play Now"}
                                        </Button>
                                    }
                                </Paper>
                            </Grid>
                        ))}
                </Grid>
            </Container>
            <br />
            <LoginFooter />
        </MenuFrame>
    )
}

export default GameRoom;