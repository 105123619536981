import { Button, CircularProgress, Container, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from "@mui/material";
import { getApi, getLoginToken } from "../shared/constant";
import { useState } from "react";


function FileUploadAlert(params) {
    const [submit, setSubmit] = useState(false);

    function handleSubmit(e) {
        e.preventDefault();
        setSubmit(true);

        const data = new FormData(e.currentTarget);
        if(data.get("files").size === 0){
            alert("Please upload a screenshot of your game.");
            setSubmit(false);
            return;
        }
        fetch(getApi() + "games/screenshot-upload", {
            method: "POST",
            body: data
        }).then((res) => {
            return res.json();
        }).then((json) => {
            if (json.code !== 200) {
                throw json.message;
            }
            return json.message;
        }).then((name) => {
            return fetch(getApi() + "games/screenshot-name-update", {
                method: "POST",
                body: JSON.stringify({
                    screenshot: name,
                    pid: params.game,
                    token: getLoginToken()
                })
            });
        }).then((res) => {
            return res.json();
        }).then((res) => {
            if (res.code !== 200) {
                throw res.message;
            } else {
                return params.onUpload();
            }
        }).then((res) => {
            setSubmit(false);
            params.onClose();
            alert("Status updated! Your reward will credited after review in few minutes.");
        }).catch((err) => {
            alert(err);
            setSubmit(false);
        });
    }
    return (
        <Dialog open={params.open} onClose={params.onClose}>
            <DialogTitle>
                Upload game screenshot
            </DialogTitle>
            <DialogContent>
                <DialogContentText>
                    Submitting forged or fake screenshot penalty 200. Continuous violations may get leads to ban. No refund on ban.<br />
                    जाली या नकली स्क्रीनशॉट जमा करने पर 200 का जुर्माना लगेगा. लगातार उल्लंघन करने पर आपको प्रतिबंधित किया जा सकता है। प्रतिबंधित करने पर कोई रिफंड नहीं दिया जाएगा।<br />
                </DialogContentText>
            </DialogContent>
            {/* <CardActionArea> */}
            <DialogActions>
                <Container component="form" onSubmit={handleSubmit} encType="multipart/form-data" >
                    <Button variant="outlined" component="div" fullWidth style={{ textAlign: "left", marginBottom:"10px" }}>
                        <input type="file" name="files" style={{ width: '100%', height: '100%' }} accept="image/png, image/jpg, image/jpeg" />
                    </Button>
                    <Button type="submit" variant="contained" fullWidth disabled={submit}>
                        {submit ? (<CircularProgress color="primary" size={24} />) : ("Upload")}
                    </Button>
                </Container>
            </DialogActions>
            {/* </CardActionArea> */}
        </Dialog>
    );
}

export default FileUploadAlert;