import { Divider, Link, Typography } from "@mui/material";
import '../css/login.css';

function LoginFooter() {
    return (
        <footer>
            <Typography variant="body2" color={'#fff'} align="center" padding={1}>
                &copy; khelboludo.com | All right reserved. <Link href="/legal/privacy-policy" color="#FFF" underline="none">Privacy Policy</Link>
            </Typography>
            <Divider color={'#fff'} />
            <div className="footer-column">
                <img src="/logo192.png" alt="logo" width={64} />

                <ul style={{ fontSize: 12, color: '#FFF' }}>
                    <li>
                        <Link href="/legal/privacy-policy" color="#FFF" underline="none">
                            Privacy Policy
                        </Link>
                    </li>
                    <li>
                        <Link href="/legal/terms-and-condition" color="#FFF" underline="none">
                            Terms and conditions
                        </Link>
                    </li>
                    <li>
                        <Link href="/legal/refund-policy" color="#FFF" underline="none">
                            Refund Policy
                        </Link>
                    </li>
                </ul>
            </div>
            <Divider />
            <br />
        </footer>
    );
}

export default LoginFooter;