import { Helmet } from 'react-helmet';
import '../css/match_display.css';

function MatchDisplay() {
    return (
        <div className="match_display" id="match_display">
            <Helmet>
                <title>Matches</title>
                <meta name="title" content="Matches" />
                <meta name="description" content="We are online in lite and rich mode. Play now" />
                <meta name="keywords" content="khelboludo,ludo,khelbo,playing,game,rich,lite,wins,reward,real cash,earning,match,ludo game,game"></meta>
            </Helmet>
            <div id='center' className="main center">
                <div className="mainInner">
                    <div className="game-wrap">
                        <div className="game-thumb">
                            <img src="https://i.imgur.com/7DqCtzq.png" alt="ludo game" />
                            <div className="ribbon">people are playing right now</div>
                        </div>
                        <div class="bid-now-wrap">
                            <a href="/user/games">Play Now</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default MatchDisplay
