import { Component } from "react";
import { Helmet } from "react-helmet";

class Terms extends Component {
    state = {}
    render() {
        return (
            <iframe src="/legal/terms.html" title="terms and condition" style={{ width: "100%", height: "100vh", padding: 0, margin: 0 }}>
                <Helmet>
                    <title>Khelboludo - Terms and Condition</title>
                </Helmet>
            </iframe>
        );
    }
}


/*
Games rule:-
1. Winning screen shot is compulsary if u won tha game then click on win and give ss if u lost then click loss and if you no  play game and not goti open then click cancel the game.
2. If 1 goti open then you have to must play the game if u left then your money cut off 25% and comission.
3. Cancel game refund your money no commision will be cut off.

Deposit / withdraw
1. You can deposit your money by upi method or scan the QR code.
2. Minimum and maximum no limit you can deposit as u wish deposit money that u can play the game without play you can't withdraw this.
3. Minimum withdraw 95 coins and maximum one lakh through upi method or direct bank transfer / NEFT within 10-15 min.

Penalty:-
1. If you share fraud or fake screenshot  then money will be cut off 200 coins from your wallet.
2. If you share wrong update then money will be cut of 50 coins from your wallet.
3. If you give no update then money will be cut off 25 coins from your wallet.

Extra income
1. You can earn money by refer if u refer one person and he play games that his betting winning coins of 2% added in your reward coins that can you withdraw this.
2. Daily earn reward scratch and claim them.

*/

export default Terms;