export function getApi() {
    switch (getEnv()) {
        case 'development':
            return "http://localhost:3000/api/v1/";
        case 'production':
            return "https://" + window.location.hostname + "/api/v1/";
        default:
            return "http://localhost:3000/api/v1/";
    }
}

export function getMediaApi() {
    return "https://image." + window.location.hostname + "/screenshot/";
}

export function getWhatsAppChat(num) {
    return "https://api.whatsapp.com/send?phone=91"+num+"&text=Hello,%20I%20need%20assistance%20in%20khelboludo.com";
}



export function getEnv() {
    return process.env.NODE_ENV;
}

export function isLogin() {
    const token = localStorage.getItem('token');
    return token !== null && token !== '';
}

export function getLoginToken() {
    return localStorage.getItem('token');
}
export function getAuthentication() {
    return localStorage.getItem('auth');
}

export const USER = "User";
export const ADMIN = "Admin";
export const ACCOUNTS = "Accounts";
export const MANAGER = "Manager";
export const PARTNER = "Partner";

export const VERIFICATION = "Verification";
export const ACTIVE = "Active";
export const HOLD = "Hold";
export const BANNED = "Banned";


export const LIGHT_DUMMY = [{ "match_id": 0, "room_id": 0, "name": "Ludo Classic", "entry_fee": 50, "result": "Ongoing", "names": "danish51040,amar67231", "ids": "1,2" },
{ "match_id": 0, "room_id": 0, "name": "Ludo Classic", "entry_fee": 150, "result": "Ongoing", "names": "parin41025,mahavir18203", "ids": "1,2" },
{ "match_id": 0, "room_id": 0, "name": "Ludo Classic", "entry_fee": 100, "result": "Ongoing", "names": "ashok74337,rocky18799", "ids": "1,2" },
{ "match_id": 0, "room_id": 0, "name": "Ludo Classic", "entry_fee": 150, "result": "Ongoing", "names": "amit80440,mishra44768", "ids": "1,2" },
{ "match_id": 0, "room_id": 0, "name": "Ludo Classic", "entry_fee": 250, "result": "Ongoing", "names": "dev67675,nikhil25781", "ids": "1,2" },
{ "match_id": 0, "room_id": 0, "name": "Ludo Classic", "entry_fee": 50, "result": "Ongoing", "names": "chahat67552,jaya57667", "ids": "1,2" },
{ "match_id": 0, "room_id": 0, "name": "Ludo Classic", "entry_fee": 150, "result": "Ongoing", "names": "hrithik73209,khatri70077", "ids": "1,2" },
{ "match_id": 0, "room_id": 0, "name": "Ludo Classic", "entry_fee": 350, "result": "Ongoing", "names": "shahbaz20789,zayn65950", "ids": "1,2" },
{ "match_id": 0, "room_id": 0, "name": "Ludo Classic", "entry_fee": 200, "result": "Ongoing", "names": "manoj90827,shah33069", "ids": "1,2" },
{ "match_id": 0, "room_id": 0, "name": "Ludo Classic", "entry_fee": 400, "result": "Ongoing", "names": "hasan82789,niyam31594", "ids": "1,2" },
{ "match_id": 0, "room_id": 0, "name": "Ludo Classic", "entry_fee": 200, "result": "Ongoing", "names": "rishi80242,arjun32134", "ids": "1,2" },
{ "match_id": 0, "room_id": 0, "name": "Ludo Classic", "entry_fee": 50, "result": "Ongoing", "names": "chauhan18243,ajay97477", "ids": "1,2" },
{ "match_id": 0, "room_id": 0, "name": "Ludo Classic", "entry_fee": 250, "result": "Ongoing", "names": "milan75629,rakhi26837", "ids": "1,2" },
{ "match_id": 0, "room_id": 0, "name": "Ludo Classic", "entry_fee": 150, "result": "Ongoing", "names": "dilip88233,jasim15223", "ids": "1,2" },
{ "match_id": 0, "room_id": 0, "name": "Ludo Classic", "entry_fee": 300, "result": "Ongoing", "names": "aamir79433,mivan13118", "ids": "1,2" },
{ "match_id": 0, "room_id": 0, "name": "Ludo Classic", "entry_fee": 50, "result": "Ongoing", "names": "asmita30226,lalbabu27566", "ids": "1,2" },
{ "match_id": 0, "room_id": 0, "name": "Ludo Classic", "entry_fee": 450, "result": "Ongoing", "names": "manoj68815,samay70479", "ids": "1,2" },
{ "match_id": 0, "room_id": 0, "name": "Ludo Classic", "entry_fee": 500, "result": "Ongoing", "names": "sajan73565,yadav61515", "ids": "1,2" },
{ "match_id": 0, "room_id": 0, "name": "Ludo Classic", "entry_fee": 350, "result": "Ongoing", "names": "madhukar18075,anand43946", "ids": "1,2" },
{ "match_id": 0, "room_id": 0, "name": "Ludo Classic", "entry_fee": 100, "result": "Ongoing", "names": "jakir67767,hamid29993", "ids": "1,2" },
{ "match_id": 0, "room_id": 0, "name": "Ludo Classic", "entry_fee": 150, "result": "Ongoing", "names": "chotu75285,kalpen72274", "ids": "1,2" },
{ "match_id": 0, "room_id": 0, "name": "Ludo Classic", "entry_fee": 50, "result": "Ongoing", "names": "nitin34281,furkaan98122", "ids": "1,2" },
{ "match_id": 0, "room_id": 0, "name": "Ludo Classic", "entry_fee": 50, "result": "Ongoing", "names": "akshay27869,bhatt24162", "ids": "1,2" },
{ "match_id": 0, "room_id": 0, "name": "Ludo Classic", "entry_fee": 150, "result": "Ongoing", "names": "neha58273,funtus68665", "ids": "1,2" },
{ "match_id": 0, "room_id": 0, "name": "Ludo Classic", "entry_fee": 100, "result": "Ongoing", "names": "kriyan62930,veer20564", "ids": "1,2" },
{ "match_id": 0, "room_id": 0, "name": "Ludo Classic", "entry_fee": 200, "result": "Ongoing", "names": "bablu36619,tousif45237", "ids": "1,2" },
{ "match_id": 0, "room_id": 0, "name": "Ludo Classic", "entry_fee": 250, "result": "Ongoing", "names": "ranveer77771,gupta80308", "ids": "1,2" },
{ "match_id": 0, "room_id": 0, "name": "Ludo Classic", "entry_fee": 150, "result": "Ongoing", "names": "kumar55941,rajesh46229", "ids": "1,2" },
{ "match_id": 0, "room_id": 0, "name": "Ludo Classic", "entry_fee": 150, "result": "Ongoing", "names": "aakash29496,manav36688", "ids": "1,2" },
{ "match_id": 0, "room_id": 0, "name": "Ludo Classic", "entry_fee": 350, "result": "Ongoing", "names": "thakur51653,singh46746", "ids": "1,2" },
{ "match_id": 0, "room_id": 0, "name": "Ludo Classic", "entry_fee": 150, "result": "Ongoing", "names": "kiran82549,niket89053", "ids": "1,2" },
{ "match_id": 0, "room_id": 0, "name": "Ludo Classic", "entry_fee": 350, "result": "Ongoing", "names": "agrawal84017,manu87120", "ids": "1,2" },
{ "match_id": 0, "room_id": 0, "name": "Ludo Classic", "entry_fee": 250, "result": "Ongoing", "names": "patil20499,anil36322", "ids": "1,2" },
{ "match_id": 0, "room_id": 0, "name": "Ludo Classic", "entry_fee": 400, "result": "Ongoing", "names": "riyaz87121,kaur68210", "ids": "1,2" },
{ "match_id": 0, "room_id": 0, "name": "Ludo Classic", "entry_fee": 150, "result": "Ongoing", "names": "anisha62806,alisha94190", "ids": "1,2" },
{ "match_id": 0, "room_id": 0, "name": "Ludo Classic", "entry_fee": 150, "result": "Ongoing", "names": "moksh54798,boby69086", "ids": "1,2" },
{ "match_id": 0, "room_id": 0, "name": "Ludo Classic", "entry_fee": 100, "result": "Ongoing", "names": "sharma50589,laksh82029", "ids": "1,2" },
{ "match_id": 0, "room_id": 0, "name": "Ludo Classic", "entry_fee": 100, "result": "Ongoing", "names": "banty63621,kalam73668", "ids": "1,2" },
{ "match_id": 0, "room_id": 0, "name": "Ludo Classic", "entry_fee": 250, "result": "Ongoing", "names": "moksh55875,neeraj16408", "ids": "1,2" },
{ "match_id": 0, "room_id": 0, "name": "Ludo Classic", "entry_fee": 350, "result": "Ongoing", "names": "imran95283,alishan40948", "ids": "1,2" },
{ "match_id": 0, "room_id": 0, "name": "Ludo Classic", "entry_fee": 300, "result": "Ongoing", "names": "nilam55945,goldu24754", "ids": "1,2" },
{ "match_id": 0, "room_id": 0, "name": "Ludo Classic", "entry_fee": 200, "result": "Ongoing", "names": "kiaan81262,mayan87221", "ids": "1,2" },
{ "match_id": 0, "room_id": 0, "name": "Ludo Classic", "entry_fee": 150, "result": "Ongoing", "names": "aum43730,mehta40905", "ids": "1,2" },
{ "match_id": 0, "room_id": 0, "name": "Ludo Classic", "entry_fee": 50, "result": "Ongoing", "names": "ohas83865,estiyak22158", "ids": "1,2" },
{ "match_id": 0, "room_id": 0, "name": "Ludo Classic", "entry_fee": 350, "result": "Ongoing", "names": "ehtisham12423,mahendra55103", "ids": "1,2" },
{ "match_id": 0, "room_id": 0, "name": "Ludo Classic", "entry_fee": 450, "result": "Ongoing", "names": "sai81861,karthik84004", "ids": "1,2" },
{ "match_id": 0, "room_id": 0, "name": "Ludo Classic", "entry_fee": 150, "result": "Ongoing", "names": "aahil22198,guddu44040", "ids": "1,2" },
{ "match_id": 0, "room_id": 0, "name": "Ludo Classic", "entry_fee": 250, "result": "Ongoing", "names": "rao67292,manish94255", "ids": "1,2" },
{ "match_id": 0, "room_id": 0, "name": "Ludo Classic", "entry_fee": 100, "result": "Ongoing", "names": "atul32472,nayan26887", "ids": "1,2" },
{ "match_id": 0, "room_id": 0, "name": "Ludo Classic", "entry_fee": 200, "result": "Ongoing", "names": "khan13606,kamran92766", "ids": "1,2" },
{ "match_id": 0, "room_id": 0, "name": "Ludo Classic", "entry_fee": 150, "result": "Ongoing", "names": "ranbir57324,veer26605", "ids": "1,2" },
{ "match_id": 0, "room_id": 0, "name": "Ludo Classic", "entry_fee": 200, "result": "Ongoing", "names": "mohan88977,jay44609", "ids": "1,2" },
{ "match_id": 0, "room_id": 0, "name": "Ludo Classic", "entry_fee": 150, "result": "Ongoing", "names": "pappu15469,hussain95977", "ids": "1,2" },
{ "match_id": 0, "room_id": 0, "name": "Ludo Classic", "entry_fee": 50, "result": "Ongoing", "names": "joshi59922,ari51688", "ids": "1,2" },
{ "match_id": 0, "room_id": 0, "name": "Ludo Classic", "entry_fee": 50, "result": "Ongoing", "names": "golu46754,krish43655", "ids": "1,2" },
{ "match_id": 0, "room_id": 0, "name": "Ludo Classic", "entry_fee": 150, "result": "Ongoing", "names": "ehsan24222,moksh91639", "ids": "1,2" },
{ "match_id": 0, "room_id": 0, "name": "Ludo Classic", "entry_fee": 250, "result": "Ongoing", "names": "nirmay59693,firoz20980", "ids": "1,2" },
{ "match_id": 0, "room_id": 0, "name": "Ludo Classic", "entry_fee": 350, "result": "Ongoing", "names": "raja25917,raju74484", "ids": "1,2" },
{ "match_id": 0, "room_id": 0, "name": "Ludo Classic", "entry_fee": 450, "result": "Ongoing", "names": "estikhar49900,mitra88106", "ids": "1,2" },
{ "match_id": 0, "room_id": 0, "name": "Ludo Classic", "entry_fee": 400, "result": "Ongoing", "names": "shankar35873,laksh98850", "ids": "1,2" },
{ "match_id": 0, "room_id": 0, "name": "Ludo Classic", "entry_fee": 200, "result": "Ongoing", "names": "candan73151,ravi98581", "ids": "1,2" },
{ "match_id": 0, "room_id": 0, "name": "Ludo Classic", "entry_fee": 150, "result": "Ongoing", "names": "kavish25744,babu71145", "ids": "1,2" },
{ "match_id": 0, "room_id": 0, "name": "Ludo Classic", "entry_fee": 200, "result": "Ongoing", "names": "nitin66732,priya33883", "ids": "1,2" },
{ "match_id": 0, "room_id": 0, "name": "Ludo Classic", "entry_fee": 100, "result": "Ongoing", "names": "krish76764,neeraj95322", "ids": "1,2" },
{ "match_id": 0, "room_id": 0, "name": "Ludo Classic", "entry_fee": 200, "result": "Ongoing", "names": "patel12025,don60453", "ids": "1,2" },
{ "match_id": 0, "room_id": 0, "name": "Ludo Classic", "entry_fee": 100, "result": "Ongoing", "names": "naman55489,jha19649", "ids": "1,2" },
{ "match_id": 0, "room_id": 0, "name": "Ludo Classic", "entry_fee": 250, "result": "Ongoing", "names": "siddh71294,emran33112", "ids": "1,2" },
{ "match_id": 0, "room_id": 0, "name": "Ludo Classic", "entry_fee": 100, "result": "Ongoing", "names": "divya13960,jaiden30541", "ids": "1,2" },
{ "match_id": 0, "room_id": 0, "name": "Ludo Classic", "entry_fee": 350, "result": "Ongoing", "names": "kaisar27332,chandra47314", "ids": "1,2" },
{ "match_id": 0, "room_id": 0, "name": "Ludo Classic", "entry_fee": 450, "result": "Ongoing", "names": "basit36630,lal73643", "ids": "1,2" },
{ "match_id": 0, "room_id": 0, "name": "Ludo Classic", "entry_fee": 50, "result": "Ongoing", "names": "mukul97324,ishan47044", "ids": "1,2" },
{ "match_id": 0, "room_id": 0, "name": "Ludo Classic", "entry_fee": 200, "result": "Ongoing", "names": "das54696,deepak15777", "ids": "1,2" },
{ "match_id": 0, "room_id": 0, "name": "Ludo Classic", "entry_fee": 150, "result": "Ongoing", "names": "prity61665,ankita53475", "ids": "1,2" }];


export const RICH_DUMMY = [{ "match_id": 0, "room_id": 0, "name": "Ludo Classic", "entry_fee": 1500, "result": "Ongoing", "names": "veer94292,don31056", "ids": "1,2" },
{ "match_id": 0, "room_id": 0, "name": "Ludo Classic", "entry_fee": 150, "result": "Ongoing", "names": "parin41025,mahavir18203", "ids": "1,2" },
{ "match_id": 0, "room_id": 0, "name": "Ludo Classic", "entry_fee": 500, "result": "Ongoing", "names": "kriyan47827,estiyak89572", "ids": "1,2" },
{ "match_id": 0, "room_id": 0, "name": "Ludo Classic", "entry_fee": 2500, "result": "Ongoing", "names": "nitin90019,sharma46856", "ids": "1,2" },
{ "match_id": 0, "room_id": 0, "name": "Ludo Classic", "entry_fee": 150, "result": "Ongoing", "names": "amit80440,mishra44768", "ids": "1,2" },
{ "match_id": 0, "room_id": 0, "name": "Ludo Classic", "entry_fee": 800, "result": "Ongoing", "names": "mehta57623,funtus31244", "ids": "1,2" },
{ "match_id": 0, "room_id": 0, "name": "Ludo Classic", "entry_fee": 700, "result": "Ongoing", "names": "rocky34819,priya73146", "ids": "1,2" },
{ "match_id": 0, "room_id": 0, "name": "Ludo Classic", "entry_fee": 50, "result": "Ongoing", "names": "chahat67552,jaya57667", "ids": "1,2" },
{ "match_id": 0, "room_id": 0, "name": "Ludo Classic", "entry_fee": 750, "result": "Ongoing", "names": "mayan40170,prity67542", "ids": "1,2" },
{ "match_id": 0, "room_id": 0, "name": "Ludo Classic", "entry_fee": 3000, "result": "Ongoing", "names": "riyaz51205,laksh63567", "ids": "1,2" },
{ "match_id": 0, "room_id": 0, "name": "Ludo Classic", "entry_fee": 350, "result": "Ongoing", "names": "shahbaz20789,zayn65950", "ids": "1,2" },
{ "match_id": 0, "room_id": 0, "name": "Ludo Classic", "entry_fee": 5000, "result": "Ongoing", "names": "asmita79790,goldu20293", "ids": "1,2" },
{ "match_id": 0, "room_id": 0, "name": "Ludo Classic", "entry_fee": 8000, "result": "Ongoing", "names": "furkaan67415,atul75507", "ids": "1,2" },
{ "match_id": 0, "room_id": 0, "name": "Ludo Classic", "entry_fee": 400, "result": "Ongoing", "names": "hasan82789,niyam31594", "ids": "1,2" },
{ "match_id": 0, "room_id": 0, "name": "Ludo Classic", "entry_fee": 1000, "result": "Ongoing", "names": "moksh15564,sajan28495", "ids": "1,2" },
{ "match_id": 0, "room_id": 0, "name": "Ludo Classic", "entry_fee": 1200, "result": "Ongoing", "names": "neeraj79335,zayn30680", "ids": "1,2" },
{ "match_id": 0, "room_id": 0, "name": "Ludo Classic", "entry_fee": 50, "result": "Ongoing", "names": "chauhan18243,ajay97477", "ids": "1,2" },
{ "match_id": 0, "room_id": 0, "name": "Ludo Classic", "entry_fee": 1100, "result": "Ongoing", "names": "ashok23430,chandra19078", "ids": "1,2" },
{ "match_id": 0, "room_id": 0, "name": "Ludo Classic", "entry_fee": 1650, "result": "Ongoing", "names": "moksh12065,ehtisham75394", "ids": "1,2" },
{ "match_id": 0, "room_id": 0, "name": "Ludo Classic", "entry_fee": 150, "result": "Ongoing", "names": "dilip88233,jasim15223", "ids": "1,2" },
{ "match_id": 0, "room_id": 0, "name": "Ludo Classic", "entry_fee": 550, "result": "Ongoing", "names": "joshi72570,nitin21196", "ids": "1,2" },
{ "match_id": 0, "room_id": 0, "name": "Ludo Classic", "entry_fee": 500, "result": "Ongoing", "names": "das78132,nikhil28132", "ids": "1,2" },
{ "match_id": 0, "room_id": 0, "name": "Ludo Classic", "entry_fee": 50, "result": "Ongoing", "names": "asmita30226,lalbabu27566", "ids": "1,2" },
{ "match_id": 0, "room_id": 0, "name": "Ludo Classic", "entry_fee": 600, "result": "Ongoing", "names": "rakhi59402,rishi44552", "ids": "1,2" },
{ "match_id": 0, "room_id": 0, "name": "Ludo Classic", "entry_fee": 750, "result": "Ongoing", "names": "banty31736,jha15973", "ids": "1,2" },
{ "match_id": 0, "room_id": 0, "name": "Ludo Classic", "entry_fee": 500, "result": "Ongoing", "names": "sajan73565,yadav61515", "ids": "1,2" },
{ "match_id": 0, "room_id": 0, "name": "Ludo Classic", "entry_fee": 950, "result": "Ongoing", "names": "neha34304,alisha50974", "ids": "1,2" },
{ "match_id": 0, "room_id": 0, "name": "Ludo Classic", "entry_fee": 1000, "result": "Ongoing", "names": "mitra36061,hussain26204", "ids": "1,2" },
{ "match_id": 0, "room_id": 0, "name": "Ludo Classic", "entry_fee": 100, "result": "Ongoing", "names": "jakir67767,hamid29993", "ids": "1,2" },
{ "match_id": 0, "room_id": 0, "name": "Ludo Classic", "entry_fee": 2500, "result": "Ongoing", "names": "kumar42687,hasan97730", "ids": "1,2" },
{ "match_id": 0, "room_id": 0, "name": "Ludo Classic", "entry_fee": 2000, "result": "Ongoing", "names": "emran47402,mohan86787", "ids": "1,2" },
{ "match_id": 0, "room_id": 0, "name": "Ludo Classic", "entry_fee": 50, "result": "Ongoing", "names": "nitin34281,furkaan98122", "ids": "1,2" },
{ "match_id": 0, "room_id": 0, "name": "Ludo Classic", "entry_fee": 850, "result": "Ongoing", "names": "kiaan78950,guddu26393", "ids": "1,2" },
{ "match_id": 0, "room_id": 0, "name": "Ludo Classic", "entry_fee": 1150, "result": "Ongoing", "names": "singh78703,samay12632", "ids": "1,2" },
{ "match_id": 0, "room_id": 0, "name": "Ludo Classic", "entry_fee": 150, "result": "Ongoing", "names": "neha58273,funtus68665", "ids": "1,2" },
{ "match_id": 0, "room_id": 0, "name": "Ludo Classic", "entry_fee": 1200, "result": "Ongoing", "names": "jasim57664,jay35406", "ids": "1,2" },
{ "match_id": 0, "room_id": 0, "name": "Ludo Classic", "entry_fee": 1400, "result": "Ongoing", "names": "yadav91980,bhatt31297", "ids": "1,2" },
{ "match_id": 0, "room_id": 0, "name": "Ludo Classic", "entry_fee": 200, "result": "Ongoing", "names": "bablu36619,tousif45237", "ids": "1,2" },
{ "match_id": 0, "room_id": 0, "name": "Ludo Classic", "entry_fee": 1300, "result": "Ongoing", "names": "aamir36043,amar37347", "ids": "1,2" },
{ "match_id": 0, "room_id": 0, "name": "Ludo Classic", "entry_fee": 1100, "result": "Ongoing", "names": "ankita44122,bablu54605", "ids": "1,2" },
{ "match_id": 0, "room_id": 0, "name": "Ludo Classic", "entry_fee": 150, "result": "Ongoing", "names": "kumar55941,rajesh46229", "ids": "1,2" },
{ "match_id": 0, "room_id": 0, "name": "Ludo Classic", "entry_fee": 1000, "result": "Ongoing", "names": "thakur50357,ajay57095", "ids": "1,2" },
{ "match_id": 0, "room_id": 0, "name": "Ludo Classic", "entry_fee": 500, "result": "Ongoing", "names": "dilip12804,kiran87685", "ids": "1,2" },
{ "match_id": 0, "room_id": 0, "name": "Ludo Classic", "entry_fee": 350, "result": "Ongoing", "names": "thakur51653,singh46746", "ids": "1,2" },
{ "match_id": 0, "room_id": 0, "name": "Ludo Classic", "entry_fee": 2500, "result": "Ongoing", "names": "boby73816,amit27582", "ids": "1,2" },
{ "match_id": 0, "room_id": 0, "name": "Ludo Classic", "entry_fee": 8000, "result": "Ongoing", "names": "kalam93210,ehsan52743", "ids": "1,2" },
{ "match_id": 0, "room_id": 0, "name": "Ludo Classic", "entry_fee": 350, "result": "Ongoing", "names": "agrawal84017,manu87120", "ids": "1,2" },
{ "match_id": 0, "room_id": 0, "name": "Ludo Classic", "entry_fee": 1200, "result": "Ongoing", "names": "akshay49465,ranbir57734", "ids": "1,2" },
{ "match_id": 0, "room_id": 0, "name": "Ludo Classic", "entry_fee": 1150, "result": "Ongoing", "names": "shankar96079,rajesh23873", "ids": "1,2" },
{ "match_id": 0, "room_id": 0, "name": "Ludo Classic", "entry_fee": 550, "result": "Ongoing", "names": "alishan36963,mahendra84983", "ids": "1,2" },
{ "match_id": 0, "room_id": 0, "name": "Ludo Classic", "entry_fee": 150, "result": "Ongoing", "names": "anisha62806,alisha94190", "ids": "1,2" },
{ "match_id": 0, "room_id": 0, "name": "Ludo Classic", "entry_fee": 2550, "result": "Ongoing", "names": "raju55492,hrithik40406", "ids": "1,2" },
{ "match_id": 0, "room_id": 0, "name": "Ludo Classic", "entry_fee": 2300, "result": "Ongoing", "names": "kalpen27898,estikhar37687", "ids": "1,2" },
{ "match_id": 0, "room_id": 0, "name": "Ludo Classic", "entry_fee": 100, "result": "Ongoing", "names": "sharma50589,laksh82029", "ids": "1,2" },
{ "match_id": 0, "room_id": 0, "name": "Ludo Classic", "entry_fee": 1900, "result": "Ongoing", "names": "manoj88702,hamid11163", "ids": "1,2" },
{ "match_id": 0, "room_id": 0, "name": "Ludo Classic", "entry_fee": 1550, "result": "Ongoing", "names": "babu98826,khan94946", "ids": "1,2" },
{ "match_id": 0, "room_id": 0, "name": "Ludo Classic", "entry_fee": 250, "result": "Ongoing", "names": "moksh55875,neeraj16408", "ids": "1,2" },
{ "match_id": 0, "room_id": 0, "name": "Ludo Classic", "entry_fee": 950, "result": "Ongoing", "names": "mivan74237,parin75216", "ids": "1,2" },
{ "match_id": 0, "room_id": 0, "name": "Ludo Classic", "entry_fee": 500, "result": "Ongoing", "names": "lal49833,nirmay79840", "ids": "1,2" },
{ "match_id": 0, "room_id": 0, "name": "Ludo Classic", "entry_fee": 300, "result": "Ongoing", "names": "nilam55945,goldu24754", "ids": "1,2" },
{ "match_id": 0, "room_id": 0, "name": "Ludo Classic", "entry_fee": 800, "result": "Ongoing", "names": "moksh46225,naman98817", "ids": "1,2" },
{ "match_id": 0, "room_id": 0, "name": "Ludo Classic", "entry_fee": 7500, "result": "Ongoing", "names": "chauhan42315,firoz42035", "ids": "1,2" },
{ "match_id": 0, "room_id": 0, "name": "Ludo Classic", "entry_fee": 150, "result": "Ongoing", "names": "aum43730,mehta40905", "ids": "1,2" },
{ "match_id": 0, "room_id": 0, "name": "Ludo Classic", "entry_fee": 800, "result": "Ongoing", "names": "imran63997,niyam85317", "ids": "1,2" },
{ "match_id": 0, "room_id": 0, "name": "Ludo Classic", "entry_fee": 500, "result": "Ongoing", "names": "chotu98265,milan52434", "ids": "1,2" },
{ "match_id": 0, "room_id": 0, "name": "Ludo Classic", "entry_fee": 350, "result": "Ongoing", "names": "ehtisham12423,mahendra55103", "ids": "1,2" },
{ "match_id": 0, "room_id": 0, "name": "Ludo Classic", "entry_fee": 2000, "result": "Ongoing", "names": "raja36328,arjun67399", "ids": "1,2" },
{ "match_id": 0, "room_id": 0, "name": "Ludo Classic", "entry_fee": 700, "result": "Ongoing", "names": "mukul93652,kaur97235", "ids": "1,2" },
{ "match_id": 0, "room_id": 0, "name": "Ludo Classic", "entry_fee": 600, "result": "Ongoing", "names": "pappu34770,ranveer14306", "ids": "1,2" },
{ "match_id": 0, "room_id": 0, "name": "Ludo Classic", "entry_fee": 250, "result": "Ongoing", "names": "rao67292,manish94255", "ids": "1,2" },
{ "match_id": 0, "room_id": 0, "name": "Ludo Classic", "entry_fee": 900, "result": "Ongoing", "names": "gupta37102,kaisar28864", "ids": "1,2" },
{ "match_id": 0, "room_id": 0, "name": "Ludo Classic", "entry_fee": 100, "result": "Ongoing", "names": "atul32472,nayan26887", "ids": "1,2" },
{ "match_id": 0, "room_id": 0, "name": "Ludo Classic", "entry_fee": 500, "result": "Ongoing", "names": "patil28057,candan42304", "ids": "1,2" },
{ "match_id": 0, "room_id": 0, "name": "Ludo Classic", "entry_fee": 7500, "result": "Ongoing", "names": "kavish12818,anil78678", "ids": "1,2" },
{ "match_id": 0, "room_id": 0, "name": "Ludo Classic", "entry_fee": 150, "result": "Ongoing", "names": "ranbir57324,veer26605", "ids": "1,2" },
{ "match_id": 0, "room_id": 0, "name": "Ludo Classic", "entry_fee": 1200, "result": "Ongoing", "names": "patel98540,golu18707", "ids": "1,2" },
{ "match_id": 0, "room_id": 0, "name": "Ludo Classic", "entry_fee": 200, "result": "Ongoing", "names": "mohan88977,jay44609", "ids": "1,2" },
{ "match_id": 0, "room_id": 0, "name": "Ludo Classic", "entry_fee": 1250, "result": "Ongoing", "names": "krish79762,lalbabu29299", "ids": "1,2" },
{ "match_id": 0, "room_id": 0, "name": "Ludo Classic", "entry_fee": 1550, "result": "Ongoing", "names": "sai48666,neeraj78093", "ids": "1,2" },
{ "match_id": 0, "room_id": 0, "name": "Ludo Classic", "entry_fee": 50, "result": "Ongoing", "names": "joshi59922,ari51688", "ids": "1,2" },
{ "match_id": 0, "room_id": 0, "name": "Ludo Classic", "entry_fee": 600, "result": "Ongoing", "names": "shahbaz75038,karthik65857", "ids": "1,2" },
{ "match_id": 0, "room_id": 0, "name": "Ludo Classic", "entry_fee": 700, "result": "Ongoing", "names": "deepak67936,basit15361", "ids": "1,2" },
{ "match_id": 0, "room_id": 0, "name": "Ludo Classic", "entry_fee": 550, "result": "Ongoing", "names": "madhukar29339,krish58306", "ids": "1,2" },
{ "match_id": 0, "room_id": 0, "name": "Ludo Classic", "entry_fee": 500, "result": "Ongoing", "names": "aakash35839,chahat52597", "ids": "1,2" },
{ "match_id": 0, "room_id": 0, "name": "Ludo Classic", "entry_fee": 800, "result": "Ongoing", "names": "ohas21319,aum70676", "ids": "1,2" },
{ "match_id": 0, "room_id": 0, "name": "Ludo Classic", "entry_fee": 1000, "result": "Ongoing", "names": "manu62594,siddh56463", "ids": "1,2" },
{ "match_id": 0, "room_id": 0, "name": "Ludo Classic", "entry_fee": 1200, "result": "Ongoing", "names": "mahavir47296,nishan33115", "ids": "1,2" },
{ "match_id": 0, "room_id": 0, "name": "Ludo Classic", "entry_fee": 1800, "result": "Ongoing", "names": "laksh93609,anand31699", "ids": "1,2" },
{ "match_id": 0, "room_id": 0, "name": "Ludo Classic", "entry_fee": 1100, "result": "Ongoing", "names": "dev94722,agrawal23447", "ids": "1,2" },
{ "match_id": 0, "room_id": 0, "name": "Ludo Classic", "entry_fee": 1250, "result": "Ongoing", "names": "jakir36628,nayan17319", "ids": "1,2" },
{ "match_id": 0, "room_id": 0, "name": "Ludo Classic", "entry_fee": 1500, "result": "Ongoing", "names": "ravi86452,divya51694", "ids": "1,2" },
{ "match_id": 0, "room_id": 0, "name": "Ludo Classic", "entry_fee": 500, "result": "Ongoing", "names": "mishra13265,ari59621", "ids": "1,2" },
{ "match_id": 0, "room_id": 0, "name": "Ludo Classic", "entry_fee": 650, "result": "Ongoing", "names": "banty80435,danish37431", "ids": "1,2" },
{ "match_id": 0, "room_id": 0, "name": "Ludo Classic", "entry_fee": 1500, "result": "Ongoing", "names": "veer39613,manoj76434", "ids": "1,2" },
{ "match_id": 0, "room_id": 0, "name": "Ludo Classic", "entry_fee": 500, "result": "Ongoing", "names": "nilam40400,manav95128", "ids": "1,2" },
{ "match_id": 0, "room_id": 0, "name": "Ludo Classic", "entry_fee": 600, "result": "Ongoing", "names": "khatri27657,kamran41053", "ids": "1,2" },
{ "match_id": 0, "room_id": 0, "name": "Ludo Classic", "entry_fee": 1600, "result": "Ongoing", "names": "manish21374,shah93459", "ids": "1,2" },
{ "match_id": 0, "room_id": 0, "name": "Ludo Classic", "entry_fee": 1800, "result": "Ongoing", "names": "tousif84261,niket22637", "ids": "1,2" },
{ "match_id": 0, "room_id": 0, "name": "Ludo Classic", "entry_fee": 1850, "result": "Ongoing", "names": "aahil28687,rao22762", "ids": "1,2" },
{ "match_id": 0, "room_id": 0, "name": "Ludo Classic", "entry_fee": 1500, "result": "Ongoing", "names": "ishan20394,jaiden26265", "ids": "1,2" }];
