import { Component } from "react";
import { Helmet } from "react-helmet";

class Refund extends Component {
    render() {
        return (
            <iframe src="/legal/refund.html" title="Refund policy" style={{ width: "100%", height: "100vh", padding: 0, margin: 0 }}>
                <Helmet>
                    <title>Khelboludo - Refund Policy</title>
                </Helmet>
            </iframe>
        );
    }
}

export default Refund;