import { useState } from "react";
import MenuFrame from "../../component/menu_frame";
import { getApi, getLoginToken } from "../../shared/constant";
import { Accordion, AccordionDetails, AccordionSummary, Button, Checkbox, Chip, Container, Dialog, DialogContent, DialogContentText, DialogTitle, FormControl, InputLabel, MenuItem, Select, Table, TableBody, TableCell, TableContainer, TableRow, Typography } from "@mui/material";
import ModeEditIcon from '@mui/icons-material/ModeEdit';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import LoginFooter from "../../component/login_footer";
import { cancelBeforeJoin, resultUpdate } from "../../shared/match_methods";
import { AWAITING, CANCEL, LOSE, ONGOING } from "../../shared/match_stages";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useNavigate } from "react-router-dom";
import 'animate.css';
import { trackerLog } from "../../shared/tracker";
import { Helmet } from "react-helmet";

function History() {
    const [history, setHistory] = useState(null);
    const [update, setUpdate] = useState(null);
    const navigat = useNavigate();
    const [expanded, setExpanded] = useState(false);

    const handleChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    };

    if (history === null) {
        fetch(getApi() + "games/my-games?token=" + getLoginToken()).then((res) => {
            if (res.status !== 200)
                throw res;
            return res.json();
        }).then((data) => {
            setHistory(data.message);
            trackerLog("HISTR");
            // console.log(data.message);
        }).catch((err) => {
            console.error(err);
        });
    }
    function handleStateChange(event) {
        setUpdate(null);
    }

    function handleResultUpdate(event) {
        event.preventDefault();
        if (event.target.result.value === "Win") {
            navigat("/user/games")
        } else if (update.room === null && event.target.result.value === CANCEL) {
            cancelBeforeJoin(update.id).then((data) => {
                handleStateChange();
                setHistory(null);
            }).catch((err) => {
                console.log(err);
            });
        } else if (update.room !== null) {
            resultUpdate(update.id, event.target.result.value).then((data) => {
                handleStateChange();
                setHistory(null);
            }).catch((err) => {
                console.log(err);
            });
        }
        // resultUpdate(update, event.target.result.value).then((data) => {
        //     console.log(data);
        //     handleStateChange();
        //     setHistory(null);
        // }).catch((err) => {
        //     console.error(err);
        // });
    }

    // function cancelMatch(id) {
    //     fetch(getApi() + "/games/challange/cancel", {
    //         method: "DELETE",
    //         body: JSON.stringify({
    //             token: getLoginToken(),
    //             id: id
    //         })
    //     }).then((res) => {
    //         return res.json();
    //     }).then((data) => {
    //         console.log(data);
    //         handleStateChange();
    //         setHistory(null);
    //     }).catch((err) => {
    //         console.error(err);
    //     });
    // }


    return (
        <MenuFrame warning="page_history">
            <Helmet>
                <title>Khelboludo - Gameplay History</title>
            </Helmet>
            <Dialog onClose={handleStateChange} open={update !== null}>
                <DialogTitle>Update your game result ?</DialogTitle>
                <DialogContent>
                    <DialogContentText component={'form'} onSubmit={handleResultUpdate}>
                        <Typography variant="subtitle2" color={'error'} >Submission of incorrect, manipulated or forged results will lead to penalty and repeated account ban. Khelbolundo reserve the right to block any refund. In case of mistake immediately write apologies to info@khelludo.com. Apologise after 5 min will not be accepted.</Typography>
                        <Typography variant="subtitle2" color={'error'} >गलत, हेरफेर या जाली परिणाम सबमिट करने पर जुर्माना लगाया जाएगा और बार -बार करने पर खाता पप्रतिबंधित कर दिया जायेगा। खेलबोलुंडो किसी भी रिफंड को रोकने का अधिकार सुरक्षित रखता है। गलती होने पर तुरंत info@khelludo.com पर माफीनामा लिखें। 5 मिनट के बाद माफीनामा स्वीकार नहीं की जाएगी।</Typography>
                        <br />
                        <FormControl fullWidth>
                            <InputLabel id="game-result">Game result</InputLabel>
                            <Select labelId="game-result" id="game-result-select" defaultValue={''} name="result" label="Game result">
                                <MenuItem value={''}><em>Not Selected</em></MenuItem>
                                <MenuItem value={"Win"} disabled>Win</MenuItem>
                                <MenuItem value={"Lose"}>Lose</MenuItem>
                                <MenuItem value={"Cancel"}>Cancel</MenuItem>
                                {/* <MenuItem value={"Draw"}>Draw (Exit before opening)</MenuItem> */}
                            </Select>
                        </FormControl>
                        <Checkbox defaultChecked color="success" label="screenshot" /> I have taken screenshot
                        <FormControl fullWidth>
                            <Button variant="contained" type="submit">Save</Button>
                        </FormControl>
                    </DialogContentText>
                </DialogContent>
            </Dialog>





            <Container style={{ marginTop: '10px', minHeight: '80vh' }}>
                {(history === null || history.length === 0) ? <div style={{ display: "flex", justifyContent: 'center', alignItems: 'center', minHeight: '80vh' }}><img src="/no_transaction.png" alt="No Transaction found" /></div> :
                    //  <TableContainer component={Paper}>
                    //     <Table sx={{ minWidth: 650 }} aria-label="History" size="small" >
                    //         <TableHead>
                    //             <TableRow>
                    //                 <TableCell>Date</TableCell>
                    //                 <TableCell>Game <small>Room</small></TableCell>
                    //                 <TableCell>Room Code</TableCell>
                    //                 <TableCell align="right">Entry (Ludo Coin)</TableCell>
                    //                 <TableCell align="right">Reward Coin</TableCell>
                    //                 <TableCell align="right">Result</TableCell>
                    //             </TableRow>
                    //         </TableHead>
                    //         <TableBody>
                    //             {history.map((row) => (
                    //                 <TableRow key={row.id} color="error">
                    //                     <TableCell scope="row">{row.dt}</TableCell>
                    //                     <TableCell>
                    //                         {row.name}&nbsp;
                    //                         {row.m_result === ONGOING ? <Chip label={row.m_result} color="error" size="small" /> : ""}
                    //                     </TableCell>
                    //                     <TableCell>
                    //                         <Button onClick={() => { navigator.clipboard.writeText(row.room + "") }} endIcon={<ContentCopyIcon />}>
                    //                             {row.room == null ? "Waiting" : row.room}
                    //                         </Button>
                    //                     </TableCell>
                    //                     <TableCell align="right">{row.paid}</TableCell>
                    //                     <TableCell align="right">{row.m_result === AWAITING || row.m_result === ONGOING ? "Waiting" : row.win}</TableCell>
                    //                     <TableCell align="right"  >
                    //                         {
                    //                             row.accepted === null ?
                    //                                 <Chip onClick={() => { setUpdate(row.id); }} label={row.result} variant="outlined" onDelete={() => { setUpdate(row.id); }} size="small" deleteIcon={<ModeEditIcon />} color="warning" /> :
                    //                                 <Chip label={row.result} variant="outlined" color="success" />
                    //                         }
                    //                     </TableCell>
                    //                 </TableRow>
                    //             ))}
                    //         </TableBody>
                    //     </Table>
                    // </TableContainer>
                    history.map((row, idx) => (
                        <Accordion key={row.id} expanded={expanded === row.id} onChange={handleChange(row.id)} className="animate__animated animate__fadeIn" style={{ animationDelay: (0.07 * idx) + "s" }}>
                            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                <div style={{ alignItems: "center", display: 'flex', justifyContent: 'space-between', width: '100%' }}>
                                    <Typography variant="subtitle1"> {row.name} <br />
                                        <Chip label={row.m_result} color={row.m_result === ONGOING ? "error" : row.m_result === CANCEL ? "warning" : "success"} variant={row.m_result === AWAITING ? "outlined" : "filled"} size="small" />
                                    </Typography>
                                    <div style={{ marginLeft: 'auto' }}></div>
                                    <Typography variant="body2" sx={{ color: 'text.secondary' }} component={'div'}>
                                        {
                                            row.accepted === null ?
                                                <Chip onClick={() => { setUpdate(row); }} label={row.result} variant="outlined" onDelete={() => { setUpdate(row); }} size="small" deleteIcon={<ModeEditIcon />} color="warning" /> :
                                                row.result === CANCEL ? "" : <Chip label={row.result} variant="outlined" color={row.result === LOSE ? "warning" : "success"} size="small" />
                                        }
                                    </Typography>
                                </div>
                            </AccordionSummary>
                            <AccordionDetails>
                                <TableContainer>
                                    <Table>
                                        <TableBody>
                                            <TableRow>
                                                <TableCell component="th" scope="row">Date</TableCell>
                                                <TableCell align="right"> {row.dt}</TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell component="th" scope="row">Room Code</TableCell>
                                                <TableCell align="right">
                                                    <Button onClick={() => { navigator.clipboard.writeText(row.room + "") }} endIcon={<ContentCopyIcon />}>
                                                        {row.room == null ? "Waiting" : row.room}
                                                    </Button>
                                                </TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell component="th" scope="row">Entry Coin</TableCell>
                                                <TableCell align="right">{row.paid}</TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell component="th" scope="row">Reward</TableCell>
                                                <TableCell align="right">{row.m_result === AWAITING || row.m_result === ONGOING ? "Waiting" : row.win}</TableCell>
                                            </TableRow>
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </AccordionDetails>
                        </Accordion>
                    ))
                }
            </Container>
            <br />
            <LoginFooter />
        </MenuFrame>
    );
}

export default History;