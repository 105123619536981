import { Alert, Button, Container, Grid, Paper, Typography } from "@mui/material";
import MenuFrame from "../../component/menu_frame";
import AddCardIcon from '@mui/icons-material/AddCard';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import { useState } from "react";
import LoginFooter from "../../component/login_footer";
import { useNavigate } from "react-router-dom";
import { Receipt } from "@mui/icons-material";
import { getUserAccountsData } from "../../shared/user";
import 'animate.css';
import { trackerLog } from "../../shared/tracker";
import { Helmet } from "react-helmet";

function Wallet() {
    const [account, setAccount] = useState(null);
    const navigate = useNavigate();

    if (account === null) {
        getUserAccountsData().then((data) => {
            if (data.code !== 200) {
                alert(data.message);
            }
            setAccount(data.message);
            trackerLog("WALET");
        }).catch((err) => {
            console.warn(err);
        });
    }
    return (
        <MenuFrame user={account} warning="page_wallet">
            <Helmet>
                <title>Khelboludo - Wallet</title>
            </Helmet>
            <br />
            <Container style={{ minHeight: '80vh' }}>
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={6} md={6}>
                        <Paper style={{ padding: 10 }} className="animate__animated animate__fadeInUp">
                            <Typography variant="h5" color="text.primary" align="center" padding={'10px'}>Ludo Coin</Typography>
                            <Alert severity="info" icon={false} style={{ textAlign: 'center' }}>
                                Ludo coin is earned by lucky draw and buy. This is only used in game playing and can not able to withdraw in bank or UPI.<br />
                                लूडो कॉइन लकी ड्रा और खरीद कर अर्जित किया जाता है। इसका उपयोग केवल गेम खेलने में किया जा सकता  है और बैंक या यूपीआई से निकासी नहीं की जा सकती।
                            </Alert>
                            <div style={{ textAlign: 'center', padding: 20 }} >
                                <Typography variant="body2" color="text.secondary">Ludo coin</Typography>
                                <Typography variant="h3" color="text.primary">{account === null ? "0.00" : account.balance.toFixed(2)}</Typography>
                            </div>
                            <Button variant="contained" size="large" sx={{ mb: 1 }} endIcon={<AddCardIcon />} fullWidth onClick={() => { navigate("/user/buy"); }}>
                                Add ludo coin
                            </Button>
                            <Button variant="outlined" size="large" endIcon={<Receipt />} fullWidth onClick={() => { navigate("/user/buy-history"); }}>
                                Buy History
                            </Button>
                        </Paper>
                    </Grid>
                    <Grid item xs={12} sm={6} md={6}>
                        <Paper style={{ padding: 10 }} className="animate__animated animate__fadeInUp">
                            <Typography variant="h5" color="text.primary" align="center" padding={'10px'}>Reward Coin</Typography>
                            <Alert severity="info" icon={false} style={{ textAlign: 'center' }}>
                                Reward coin is your winning and referer can be withdraw on bank. You can also play game from reward coin.<br />
                                रिवॉर्ड कॉइन आपकी जीत और रेफर हुई कॉइन है, और इसे बैंक में निकाला जा सकता है। आप रिवॉर्ड कॉइन से गेम भी खेल सकते हैं।
                            </Alert>
                            <div style={{ textAlign: 'center', padding: 20 }} >
                                <Typography variant="body2" color="text.secondary">Reward coin</Typography>
                                <Typography variant="h3" color="text.primary">{account === null ? "0.00" : account.rewards.toFixed(2)}</Typography>
                            </div>
                            <Button variant="contained" size="large" sx={{ mb: 1 }} endIcon={<AccountBalanceIcon />} fullWidth onClick={() => { navigate("/user/withdraw"); }}>
                                Withdraw
                            </Button>
                            <Button variant="outlined" size="large" endIcon={<Receipt />} fullWidth onClick={() => { navigate("/user/withdraw-history"); }}>
                                Sell History
                            </Button>
                        </Paper>
                    </Grid>
                </Grid>
            </Container>
            <br />
            <LoginFooter />
        </MenuFrame>
    );
}

export default Wallet;