import '../css/App.css';
import '../css/parellex.css';
import  {getApi, getEnv, getMediaApi } from '../shared/constant'
import MenuFrame from '../component/menu_frame';
import MidPart from '../component/mid_soon';
import Top3Card from '../component/top3_card';
import FooterRegister from '../component/register_footer';
import 'animate.css';
import { trackerLog } from '../shared/tracker';

function Dashboard() {
  console.log("Current active enviroment: %c" + getEnv(), 'background: #fefec4; padding:0px 10px;');
  console.log("API endpoint: %c" + getApi(), 'background: #fefec4; padding:0px 10px;');
  console.log("Media endpoint: %c" + getMediaApi(), 'background: #fefec4; padding:0px 10px;');
  console.log('%cKhelboLudo', 'color: red; font-size:38px; font-weight:bold;');
  console.log('%cDeveloper Console', 'color: green; font-size:18px;');

  trackerLog("HOME");
  return (
    <MenuFrame>
      <div className='body-crouser'>
        <div className="container">
          <h1 className="animate__animated animate__fadeInUp">Welcome to the game</h1>
          <p className="animate__animated animate__fadeInUp" style={{ animationDelay: "0.25s" }} >Play ludo king and show you are a king. Win reward coin and redeem reward coin to real cash.</p>
          <a href="#games" className="animate__animated animate__fadeInUp" style={{ animationDelay: "0.5s" }} >Play the Game</a>
        </div>

        <div className="blank">
          <MidPart />
        </div>

        <div className="container second" id='games' style={{ height: '1200px' }}>
          <Top3Card />
        </div>
        {/* <div className="blank">
        </div> */}
      </div>
      <FooterRegister />
    </MenuFrame>
  );
}

export default Dashboard;
