import '../css/App.css';
import '../css/parellex.css';
import MenuFrame from '../component/menu_frame';
import { useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import MidPart from '../component/mid_soon';
import Top3Card from '../component/top3_card';
import FooterRegister from '../component/register_footer';
import { getUserData } from '../shared/user';
import { trackerLog } from '../shared/tracker';
import { Helmet } from 'react-helmet';

function UserDashboard() {

  const navigate = useNavigate();
  const [user, setUser] = useState(null);
  const [rdr, setRdr] = useState(null);
  // const [cvt, setCvt] = useState(null);

  useEffect(() => {
    if (rdr !== null && rdr !== undefined) {
      navigate(rdr)
    }
  }, [navigate, rdr]);

  if (user === null) {
    getUserData().then((json) => {
      // console.log(json.message);
      if (json.message === null || json.code !== 200) {
        setRdr('/user/profile/logout');
      } else if (json.message.mlv === 0 && json.message.ctv === 0) {
        setRdr('/verification/mobile');
      } else {
        setUser(json.message);
      }
      trackerLog("UDSBD");
    }).catch((e) => {
      console.error(e);
    });
  }

  return (
    <MenuFrame user={user} warning="page_dashboard">
      <Helmet>
        <title>Dashboard :: KhelboLudo</title>
      </Helmet>
      <div className='body-crouser'>
        <div className="container second">
          <Top3Card />
        </div>

        <div className="blank">
          <MidPart />
        </div>

        <div className="container">
          <h1>Play more win more</h1>
          <p>Play ludo king and show you are a king. Win reward coin and redeem reward coin to real cash.</p>
          <a href="#awesome">Awesome</a>
        </div>

        {/* <div className="blank">
        </div> */}
      </div>
      <FooterRegister />
    </MenuFrame>
  );
}

export default UserDashboard;
