import { Alert, Box, Toolbar, Typography } from "@mui/material";
import TopNav from "./_top_nav";
import SideDrawer from "./_side_drawer";
import { useState } from "react";
import { isLogin } from "../shared/constant";
import { getUserAccountsData } from "../shared/user";
import { getAdminConfig } from "../shared/config";
import "../css/marquee.css";

function MenuFrame(props) {
    const [open, setOpen] = useState(false);
    const [account, setAccount] = useState(null);
    const [data, setData] = useState({ "top_warning": null });
    const warn = props.warning ?? ''

    const handleDrawerClose = () => {
        setOpen(false);
    };
    const handleDrawerOpen = () => {
        setOpen(true);
    };

    if (account === null && isLogin()) {
        if (props.user !== undefined) {
            if (props.user !== null)
                setAccount(props.user);
        } else {
            getUserAccountsData().then((data) => {
                setAccount(data.message);
            }).catch((err) => {
                console.log(err);
            });
        }
    }

    if (data["top_warning"] === null) {
        getAdminConfig("top_warning," + warn).then((json) => {
            if (json.code === 200) {
                setData(json.message);
            } else {
                setData({ "top_warning": "" });
            }
        }).catch((err) => {
            console.log(err);
        });
    }

    return (
        <Box sx={{ flexGrow: 1 }}>
            <TopNav onDrawerOpen={handleDrawerOpen} data={account} />
            <SideDrawer onDrawerClose={handleDrawerClose} open={open} data={account} />
            <Box component="main">
                <Toolbar />
                <Alert sx={{ display: { xs: 'flex', sm: 'none' }, justifyContent: 'center' }} severity="success" icon={false}>
                    {/* Play game at 4% commission | 2% referral */}
                    <strong>{data["top_warning"] === null ? "" : data["top_warning"]}</strong>
                </Alert>
                {data[warn] === null || data[warn] === "" || data[warn] === undefined ? "" :
                    <Box component="div">
                        {
                            warn !== "" && (data[warn] !== null && data[warn] !== undefined && data[warn] !== "") ?
                                data[warn].length < 100 ?
                                    <Box component="div" className="marqueebox"><Typography variant="body2" color={"warning.main"} className="marquee" >{data[warn]}</Typography></Box> :
                                    <Alert severity="warning"><Typography variant="body2" fontWeight={"bold"}>{data[warn]}</Typography></Alert>
                                : ""
                        }
                    </Box>
                }
                {props.children}
            </Box>
        </Box>
    );
}

export default MenuFrame;