import { useState } from "react";
import MenuFrame from "../../component/menu_frame";
import { getApi, getLoginToken, getMediaApi } from "../../shared/constant";
import DataTable from "react-data-table-component";
import { Chip, Container, IconButton, InputBase, Link, Paper } from "@mui/material";
import SearchIcon from '@mui/icons-material/Search';
import "../../css/history_match.css";
import { Helmet } from "react-helmet";

function HistoryMatch(params) {
    const [allMatches, setAllMatches] = useState(null);
    const [matches, setMatches] = useState(null);

    if (matches === null) {
        fetch(getApi() + "admin/history/get-latest?token=" + getLoginToken()).then((res) => {
            return res.json();
        }).then((json) => {
            console.log(json);
            if (json.code === 200) {
                setMatches(json.message);
                setAllMatches(json.message);
            } else {
                setMatches([]);
            }
        }).catch((err) => {
            console.log(err);
        })
    }


    const columns = [
        { name: 'MatchID', sortable: true, maxWidth: "50px", wrap: true, selector: row => row.match_id, },
        { name: 'Date', sortable: true, minWidth: "200px", selector: row => row.dt, },
        { name: 'Game', sortable: true, minWidth: "150px", selector: row => row.name, },
        { name: 'Entry', sortable: true, right: true, selector: row => row.entry_fee, },
        { name: 'Result', sortable: true, selector: row => row.result, },
    ];

    const RowComponent = ({ data }) => {
        return (
            <div className="game-list-200-item">
                <table>
                    <tr>
                        <th>Participant</th>
                        <td>{data.names.split(",").map((itm) => (<Link href={"/admin/transaction/" + itm} color="unset" underline="none"><Chip label={"@" + itm} color="info" className="names" /></Link>))}</td>
                    </tr>
                    <tr>
                        <th>Screenshot</th>
                        <td>{data.screenshot === null ? "" : data.screenshot.split(",").map((itm) => (<Link href={getMediaApi() + itm} color="unset" underline="none" target="_blank"><img src={getMediaApi() + itm} className="ss" alt="Deleted" /></Link>))}</td>
                    </tr>
                    <tr>
                        <th>Winner</th>
                        <td>{data.username === null ? "NA" : <Link href={"/admin/transaction/" + data.username} color="unset" underline="none"><Chip label={"@" + data.username} color="success" className="names" /></Link>}</td>
                    </tr>
                    <tr>
                        <th>Roomcode</th>
                        <td>{data.game_room_code === null ? "NA" : data.game_room_code}</td>
                    </tr>
                </table>
            </div>);
    }

    return (
        <MenuFrame>
            <Helmet>
                <title>Khelboludo letest matches</title>
            </Helmet>
            <Container>
                <Paper component="form" sx={{ p: '2px 10px', display: 'flex', alignItems: 'center', m: "10px" }}>
                    <InputBase fullWidth placeholder="Search the user" name="filter" type="text" onChange={(event) => {
                        setMatches(allMatches.filter((item) => (item.game_room_code !== null && item.game_room_code.toLowerCase().includes(event.target.value.toLowerCase())) ||
                            (item.name !== null && item.name.toLowerCase().includes(event.target.value.toLowerCase())) ||
                            (item.result !== null && item.result.toLowerCase().includes(event.target.value.toLowerCase()))));
                    }} />
                    <IconButton type="button" sx={{ p: '10px' }} aria-label="search">
                        <SearchIcon />
                    </IconButton>
                </Paper>
            </Container>
            <DataTable
                title="Past 500 Games"
                dense={true}
                columns={columns}
                data={matches === null ? [] : matches}
                progressPending={matches === null}
                pagination
                paginationPerPage={15}
                expandableRows
                expandableRowsComponent={RowComponent}
                className="animate__animated animate__fadeIn"
            />
        </MenuFrame>
    );
}

export default HistoryMatch;
