import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

export function RedirectOnGameOngoing(param) {
    const navigate = useNavigate();
    useEffect(() => {
        if (param.pid !== null) {
            navigate("/user/games/ongoing/" + param.pid);
        }
    }, [navigate, param.pid]);
    return (<></>);
}