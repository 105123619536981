import { Box, Container, CssBaseline, Link, Typography } from "@mui/material";

function FooterRegister() {
    return (<>
        <CssBaseline />
        <Box component="footer" sx={{ py: 3, px: 2, mt: 'auto', backgroundColor: (theme) => theme.palette.mode === 'light' ? theme.palette.grey[200] : theme.palette.grey[800], }}>
            <Container maxWidth="sm">
                <Typography variant="body2">
                    <Link href="/legal/privacy-policy" underline="none" >Privacy Policy</Link> |&nbsp;
                    <Link href="/legal/terms-and-condition" underline="none" >Terms and conditions</Link> |&nbsp;
                    <Link href="/legal/refund-policy" underline="none" >Refund Policy</Link>
                </Typography>
                Copyright &copy; khelboludo.com, 2023
            </Container>
        </Box>
    </>);
}

export default FooterRegister;