import { AppBar, Avatar, Badge, Box, Button, Divider, IconButton, Link, Menu, MenuItem, Slide, Toolbar, Tooltip, Typography, useScrollTrigger } from "@mui/material";
import React, { useState } from "react";
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import MenuIcon from '@mui/icons-material/Menu';
import LogoutIcon from '@mui/icons-material/Logout';
import { useNavigate } from "react-router-dom";
import { USER, getApi, getAuthentication, isLogin } from "../shared/constant";
import WalletIcon from '@mui/icons-material/Wallet';
import AddIcon from '@mui/icons-material/Add';
import "../css/common.css";
import wavFile from './notification.wav';
import useSound from 'use-sound';
import { useEffect } from 'react';
import NotificationsIcon from '@mui/icons-material/Notifications';
import AdminNotification from "../routes/admin/admin_notification";

const settings = [
    { 'name': 'Coin Buy/Sell', 'icon': <AddIcon /> },
    { 'name': 'Profile', 'icon': <ManageAccountsIcon /> },
    // { 'name': 'Accounts', 'icon': <ChecklistRtlIcon /> },
    { 'name': 'Logout', 'icon': <LogoutIcon /> }
];

function TopNav(props) {
    const [anchorElUser, setAnchorElUser] = useState(null);
    const [anchorElNotification, setAnchorElNotification] = useState(null);
    const [playSound] = useSound(wavFile);
    const [count, setCount] = useState(0);
    const [notification, setNotification] = useState(null);

    const trigger = useScrollTrigger();
    const navigate = useNavigate();


    useEffect(() => {
        if (getAuthentication() !== USER) {
            const interval = setInterval(() => {
                checkNotification();
            }, 30000);
            return () => clearInterval(interval);
        }
        return;
    }, [checkNotification, getAuthentication]);

    function checkNotification() {
        fetch(getApi() + "admin/notification").then((res) => {
            return res.json();
        }).then((json) => {
            // console.log(json);
            if (json.code === 200) {
                setCount(json.message.length);
                setNotification(json.message);
                if (json.count !== 0) {
                    playSound();
                }
            } else {
                setNotification([]);
            }
        }).catch((err) => {
            console.log(err);
        });
    }


    const openMenu = (event) => {
        setAnchorElUser(event.currentTarget);
        setAnchorElNotification(null);
    };

    const openNotification = (event) => {
        setAnchorElNotification(event.currentTarget);
        setAnchorElUser(null);
    };

    const closeWithoutAction = () => {
        setAnchorElUser(null);
        setAnchorElNotification(null);
    };

    const closeWithAction = (e) => {
        switch (e) {
            case 'Profile':
                navigate('/user/profile/edit');
                break;
            case 'Accounts':
                navigate('/user/profile/accounts');
                break;
            case 'Logout':
                navigate('/user/profile/logout');
                break;
            case 'Login':
                navigate('/login');
                break;
            case 'Register':
                navigate('/register');
                break;
            case 'Coin Buy/Sell':
                navigate('/user/wallet');
                break;
            default:
                break;
        }
        setAnchorElUser(null);
    };

    if (getAuthentication() !== USER && notification === null) {
        checkNotification();
    }

    return (
        <Slide appear={false} direction="down" in={!trigger}>
            <AppBar color="appbar" position="fixed">
                <Toolbar>
                    {isLogin() ? (<IconButton size="large" edge="start" color="inherit" aria-label="menu" sx={{ mr: { xs: 0, sm: 2 } }} onClick={props.onDrawerOpen}>
                        <MenuIcon />
                    </IconButton>) : ""}
                    <Link href="/">
                        <img src="/logo192.png" width={50} alt="logo" style={{ margin: '0 5px 0 0' }} />
                    </Link>
                    <Box sx={{ flexGrow: 1 }} underline="none">
                        <Typography variant="h5" component="div" className="brand-name">
                            <Link href="/" color="#027799" underline="none">
                                <strong> Khelbo Ludo</strong>
                            </Link>
                        </Typography>
                    </Box>

                    {isLogin() ? (
                        <Box sx={{ flexGrow: 0 }}>

                            {getAuthentication() !== USER ? <><Button onClick={openNotification}><Badge badgeContent={count} color="warning"><NotificationsIcon /></Badge></Button>

                                <Menu sx={{ mt: '45px' }} id="menu-notification" keepMounted anchorEl={anchorElNotification}
                                    open={Boolean(anchorElNotification)} onClose={closeWithoutAction}
                                    anchorOrigin={{ vertical: 'top', horizontal: 'right', }}
                                    transformOrigin={{ vertical: 'top', horizontal: 'right', }}>
                                    <Box style={{ minWidth: '250px', padding: '0 10px' }} >
                                        <AdminNotification notification={notification} onClick={(idx) => { setNotification(notification.filter((itm, i) => idx !== i)); }} />
                                    </Box>
                                </Menu>
                            </> : ""}

                            {getAuthentication() !== USER ? <Tooltip title="Personal preferences" >
                                <IconButton onClick={openMenu} sx={{ p: 0 }} >
                                    <Avatar alt="Khelbo ludo" src="/male-profile1.svg" />
                                </IconButton>
                            </Tooltip> :
                                <Button variant="outlined" startIcon={<WalletIcon />} onClick={openMenu}>
                                    {props.data !== null ? (props.data.balance + props.data.rewards).toFixed(2) : 0.00}
                                </Button>}

                            <Menu sx={{ mt: '45px' }} id="menu-appbar" keepMounted anchorEl={anchorElUser}
                                open={Boolean(anchorElUser)} onClose={closeWithoutAction}
                                anchorOrigin={{ vertical: 'top', horizontal: 'right', }}
                                transformOrigin={{ vertical: 'top', horizontal: 'right', }}>
                                <Box style={{ minWidth: '210px', padding: '0 10px' }} >
                                    <div><strong>Wallet</strong></div>
                                    <Button variant="text" style={{ justifyContent: 'space-between' }} fullWidth>
                                        <div style={{ display: 'flex', alignItems: 'center' }}><WalletIcon /> &nbsp; Ludo Coin</div>
                                        <div>{props.data !== null ? props.data.balance.toFixed(2) : 0.00}</div>
                                    </Button>
                                    <br />
                                    <Button variant="text" style={{ justifyContent: 'space-between' }} fullWidth>
                                        <div style={{ display: 'flex', alignItems: 'center' }}><WalletIcon /> &nbsp; Reward</div>
                                        <div>{props.data !== null ? props.data.rewards.toFixed(2) : 0.00}</div>
                                    </Button>
                                    <Divider />
                                </Box>
                                {settings.map((setting) => (
                                    <MenuItem key={setting.name} onClick={() => { closeWithAction(setting.name); }}>
                                        {setting.icon} &nbsp;
                                        <Typography textAlign="center">{setting.name}</Typography>
                                    </MenuItem>
                                ))}
                            </Menu>
                        </Box>
                    ) : (<>
                        <Button color="inherit" onClick={() => { closeWithAction("Login"); }}>Login</Button>
                        <Button color="inherit" onClick={() => { closeWithAction("Register"); }}>Register</Button>
                    </>
                    )}
                </Toolbar>
            </AppBar>
        </Slide>
    )
}

export default TopNav;