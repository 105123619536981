import { useNavigate, useParams } from "react-router-dom";
import MenuFrame from "../../component/menu_frame";
import { useEffect, useState } from "react";
import { getApi, getLoginToken, getWhatsAppChat } from "../../shared/constant";
import { Alert, Avatar, Button, Checkbox, Chip, Collapse, Container, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, FormControlLabel, Grid, Link, List, ListItem, ListItemAvatar, Paper, Table, TableBody, TableCell, TableContainer, TableRow, Typography } from "@mui/material";
import LoginFooter from "../../component/login_footer";
import ListItemText from '@mui/material/ListItemText';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import ViewInArIcon from '@mui/icons-material/ViewInAr';
import IndeterminateCheckBoxIcon from '@mui/icons-material/IndeterminateCheckBox';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import FileUploadAlert from "../../component/file_upload";
import { Refresh } from "@mui/icons-material";
import SmsIcon from '@mui/icons-material/Sms';
import { resultUpdate } from "../../shared/match_methods";
import { trackerLog } from "../../shared/tracker";
import { Helmet } from "react-helmet";
import wavFile from '../../component/notification.wav';
import useSound from "use-sound";
import "../../css/ongoing.css";

function OngoingMatch() {
    const { pid } = useParams();
    const [curGame, setCurGame] = useState(null);
    const [gameError, setGameError] = useState(null);
    const [cancelCC, setCancelCC] = useState(false);
    const [loseCC, setLoseCC] = useState(false);
    const [ss, setSS] = useState(false);
    const [soundCount, setSoundCount] = useState(0);
    const navigate = useNavigate();
    const [playSound] = useSound(wavFile);

    useEffect(() => {
        const interval = setInterval(async () => {
            if (curGame.game_room_code === "WAITING") {
                getMatchData(false);
            }
        }, 5000);
        return () => clearInterval(interval);
    }, [curGame, getMatchData]);


    if (pid !== null && curGame === null) {
        getMatchData(true);
    }

    function getMatchData(first) {
        fetch(getApi() + "games/mymatch/get?token=" + getLoginToken()).then((res) => {
            return res.json();
        }).then((res) => {
            if (res.code === 200) {
                if (JSON.stringify(res.message) !== JSON.stringify(curGame)) {
                    setCurGame(res.message);
                }
            } else {
                navigate("/user/games")
            }
            handelSoundPlay();
            if (first) trackerLog("GOING");
        }).catch((err) => {
            console.error(err);
        });
    }

    function handleScreenShot() {
        setSS(true);
    }

    function handelSoundPlay() {
        if (soundCount < 2) {
            playSound();
            setSoundCount(soundCount + 1);
        }
    }

    function handleResultUpdate(val) {
        resultUpdate(curGame.pid, val).then((data) => {
            if (data.code !== 200) {
                setGameError(data.message);
            } else {
                navigate("/user/games")
            }
        }).catch((err) => {
            console.error(err);
        });
    }

    return (
        <MenuFrame warning="page_ongoing">
            <Helmet>
                <title>Khelboludo - Game in progress</title>
            </Helmet>
            <FileUploadAlert game={curGame === null ? 0 : curGame.pid} open={ss} onClose={() => { setSS(false); }} onUpload={() => { handleResultUpdate('Win') }} />
            <Dialog open={cancelCC} onClose={() => { setCancelCC(false); }}>
                <DialogTitle>
                    Game Cancel
                </DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        <FormControlLabel control={<Checkbox value="true" color="warning" name="accept" checked={true} />} label="You are canceling the game, You will get refund once after confirmation of admin. We may ask for screenshot if needed." />
                        आप गेम रद्द कर रहे हैं, एडमिन की पुष्टि के बाद आपको रिफंड मिल जाएगा। जरूरत पड़ने पर हम स्क्रीनशॉट मांग सकते हैं।
                        <Typography variant="body2" mt={2}>
                            <strong>You can cancel game if:</strong>
                            <ul>
                                <li>Opponent does not join.</li>
                                <li>You left before opening of opponent dice.</li>
                            </ul>
                            Invalid/Fake cancellation request may impose penalty after admin decision. <br />
                            अमान्य/नकली रद्दीकरण अनुरोध पर व्यवस्थापक के निर्णय के बाद जुर्माना लगाया जा सकता है।
                        </Typography>
                    </DialogContentText>
                </DialogContent>
                {/* <CardActionArea> */}
                <DialogActions>
                    <Container component="form" encType="multipart/form-data" >
                        <Button type="submit" variant="contained" color="warning" fullWidth onClick={() => { handleResultUpdate("Cancel") }}>
                            Confirm! Cancel
                        </Button>
                    </Container>
                </DialogActions>
            </Dialog>

            <Dialog open={loseCC} onClose={() => { setLoseCC(false); }} >
                <DialogTitle>
                    Lose Game | गेम हारा
                </DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        <FormControlLabel control={<Checkbox value="true" color="error" name="accept" checked={true} />} label="Confirm that you are losing the game. By clicking this you will get no refund and your opponent will get winning reward." />
                        पुष्टि करें कि आप गेम हार रहे हैं। इस पर क्लिक करने पर आपको कोई रिफंड नहीं मिलेगा और आपके प्रतिद्वंद्वी को विजयी इनाम मिलेगा।
                    </DialogContentText>
                </DialogContent>
                {/* <CardActionArea> */}
                <DialogActions>
                    <Container component="form" encType="multipart/form-data" >
                        <Button type="submit" variant="contained" color="error" fullWidth onClick={() => { handleResultUpdate("Lose"); }}>
                            Confirm! &nbsp; <strong>I lose game</strong>
                        </Button>
                    </Container>
                </DialogActions>
            </Dialog>

            <Container className="main-frame">
                {curGame === null ? "Loading ..." :
                    <>
                        <Typography variant="h5">{curGame.name} <Chip label={curGame.m_result} color="error" size="small" /></Typography>
                        <Typography variant="body2" color={"text.secondary"}>Started on: {curGame.dt}</Typography>
                        <Collapse in={gameError !== null}>
                            <Alert severity="error" onClose={() => { setGameError(null) }}>{gameError}</Alert>
                        </Collapse>
                        <Typography variant="body1" textAlign={'center'}><strong>Room code</strong></Typography>
                        <Button endIcon={curGame.game_room_code === "WAITING" ? <Refresh className="rotate-infinite" /> : <ContentCopyIcon />} fullWidth onClick={() => { curGame.game_room_code === "WAITING" ? window.location.reload() : navigator.clipboard.writeText(curGame.game_room_code + "") }}><Typography variant="h4">{curGame.game_room_code || "WAITING..."}</Typography></Button>
                        <Typography variant="body2" color={"text.secondary"} textAlign={'center'} gutterBottom>Click to copy</Typography>
                        {/* <Alert severity="info">
                            <AlertTitle><strong>Attention | ध्यान दें</strong></AlertTitle>
                            <Typography variant="body1" textAlign={'justify'}>Winner need to upload screenshot.</Typography>
                            <Typography variant="body1" textAlign={'justify'}>विजेता को स्क्रीनशॉट अपलोड करना होगा।</Typography>
                        </Alert> */}
                        <Typography variant="h5" >Penalty</Typography>
                        <List sx={{ width: '100%', bgcolor: 'background.paper' }}>
                            <ListItem>
                                <ListItemAvatar>
                                    <Avatar sx={{ bgcolor: 'secondary.main' }}><CheckBoxOutlineBlankIcon /></Avatar>
                                </ListItemAvatar>
                                <ListItemText primary="Forged or fake screenshot" secondary="200" />
                            </ListItem>
                            <ListItem>
                                <ListItemAvatar>
                                    <Avatar sx={{ bgcolor: 'success.main' }}><CheckBoxOutlineBlankIcon /></Avatar>
                                </ListItemAvatar>
                                <ListItemText primary="Wrong Update" secondary="50" />
                            </ListItem>
                            <ListItem>
                                <ListItemAvatar>
                                    <Avatar sx={{ bgcolor: 'primary.main' }}><CheckBoxOutlineBlankIcon /></Avatar>
                                </ListItemAvatar>
                                <ListItemText primary="No update (charged after 8hr)" secondary="25" />
                            </ListItem>
                        </List>

                        {
                            curGame === null || curGame.accepted !== null ? "" :
                                <Grid container spacing={2}>
                                    {/* <Grid item xs={12} sm={6}>
                                <Button variant="contained" fullWidth startIcon={<ScreenshotIcon />} onClick={handleScreenShot} >Upload Screenshot</Button>
                            </Grid> */}
                                    <Grid item xs={12} sm={6}>
                                        <Button variant="contained" fullWidth color="success" startIcon={<ViewInArIcon />} onClick={() => { handleScreenShot() }} >I Won</Button>
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <Button variant="contained" fullWidth color="warning" startIcon={<IndeterminateCheckBoxIcon />} onClick={() => { setLoseCC(true); }}>I Lost</Button>
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <Button variant="contained" fullWidth color="error" startIcon={<DeleteOutlineIcon />} onClick={() => { setCancelCC(true); }} >Cancle</Button>
                                    </Grid>
                                </Grid>
                        }
                        <br />
                        <Link href={getWhatsAppChat()} underline="none" target="_blank"><Button variant="outlined" color="success" fullWidth startIcon={<SmsIcon />}> Write Us</Button></Link>
                        <TableContainer component={Paper} style={{ margin: '20px 0' }}>
                            <Table>
                                <TableBody>
                                    <TableRow>
                                        <TableCell scope="row">Coin paid</TableCell>
                                        <TableCell align="right">{curGame.paid}</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell scope="row">Players</TableCell>
                                        <TableCell align="right">{curGame.max_player}</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell scope="row">Participation ID</TableCell>
                                        <TableCell align="right">{'#' + curGame.pid}</TableCell>
                                    </TableRow>
                                </TableBody>
                            </Table>
                        </TableContainer>
                        <Alert icon={false} severity="error">
                            <Typography variant="body1" textAlign={'justify'}>Exiting after opening one dice of opponent is considered as 25% win of opponent. In case of auto exit admin decision will be final. If you left in auto exit it will be considered as loss. If opponent opens 2 dice, existing will be considered as loss in any case.</Typography>
                            <Typography variant="body1" textAlign={'justify'}>प्रतिद्वंद्वी का एक पासा खोलने के बाद एग्जिट  करने  पर  प्रतिद्वंद्वी की 25% विजेता माना  जाएगा। स्वतः एग्जिट के मामले में व्यवस्थापक का निर्णय अंतिम होगा। अगर आप ऑटो एग्जिट में छोड़ देते है तो इसे हार माना जाएगा। यदि प्रतिद्वंद्वी 2 पासे खोल लिया है, तो किसी भी स्थिति में एग्जिट को हार माना जाएगा।</Typography>
                        </Alert>

                        <h1>Must read rules</h1>
                        <ul>
                            <li>
                                <Typography variant="body1" textAlign={'justify'}>Winner need to upload screenshot.</Typography>
                                <Typography variant="body1" textAlign={'justify'}>विजेता को स्क्रीनशॉट अपलोड करना होगा।</Typography>
                            </li>
                            <li>
                                <Typography variant="body1">Always take screenshot in game and game result. Mail the screenshot to info@khelboludo.com if needed.</Typography>
                                <Typography variant="body1">गेम एवं गेम रिजल्ट में हमेशा स्क्रीनशॉट लें। यदि आवश्यक हो तो स्क्रीनशॉट को info@khelboludo.com पर मेल करें।</Typography>
                            </li>
                            <li>
                                <Typography variant="body1">You will get room code only after the opponent joins the game or accept your challenge.</Typography>
                                <Typography variant="body1">प्रतिद्वंद्वी के खेल में जुड़ने या आपकी चुनौती स्वीकार करने के बाद आपको रूम कोड मिलेगा।</Typography>
                            </li>
                            <li>
                                <Typography variant="body1">Full refund on cancellation of game after 5 min if you don't get room code. 2% penalty before 5 min if not generated.</Typography>
                                <Typography variant="body1">यदि आपको रूम कोड नहीं मिलता है तो 5 मिनट के बाद गेम रद्द करने पर पूरा रिफंड। 5 मिनट से पहले उत्पन्न न होने पर 2% जुर्माना।</Typography>
                            </li>
                            <li>
                                <Typography variant="body1">Full refund on cancellation of game if opponent doesn't open his dice.</Typography>
                                <Typography variant="body1">यदि प्रतिद्वंद्वी अपनी गोटी नहीं खोलता है, एवं आप खेल रद्द करते है पर पूरा रिफंड मिलेगा।</Typography>
                            </li>
                        </ul>
                    </>}
            </Container>
            <LoginFooter />
        </MenuFrame>
    );
}

export default OngoingMatch;