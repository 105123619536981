import { useState } from "react";
import MenuFrame from "../../component/menu_frame";
import { Accordion, AccordionDetails, AccordionSummary, Backdrop, Box, Button, Checkbox, Chip, CircularProgress, Container, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, FormControlLabel, FormGroup, Pagination, Stack, TextField, Typography } from "@mui/material";
import LoginFooter from "../../component/login_footer";
import "../../css/common.css";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';
import { getTransaction, getUserData, updateMail } from "../../shared/user";
import 'animate.css';
import { trackerLog } from "../../shared/tracker";
import { Helmet } from "react-helmet";
import { getApi, getLoginToken } from "../../shared/constant";

function getTransactionView(transaction, account, user, filter, expanded, handleChange, balance, reward, page) {
    if (transaction === null || account === null || transaction.length === 0) return (<div style={{ display: "flex", justifyContent: 'center', alignItems: 'center', minHeight: '80vh' }}><img src="/no_transaction.png" alt="No Transaction found" /></div>);

    if (filter === "Wallet") {
        transaction = transaction.filter((item) => {
            return item.ref === "Withdraw" || item.ref === "Deposit";
        });
    } else if (filter !== null) {
        transaction = transaction.filter((item) => {
            return item.ref === filter;
        });
    }
    const MAX_PER_PAGE = 15;
    let pmin = (page - 1) * MAX_PER_PAGE;
    let pmax = (page) * MAX_PER_PAGE;
    transaction = transaction.filter((_, idx) => {
        return idx >= pmin && idx < pmax;
    });

    return transaction.map((row, idx) =>
        <Accordion key={row.id} expanded={expanded === row.id} onChange={handleChange(row.id)} className="animate__animated animate__fadeIn" style={{ animationDelay: (0.07 * idx) + "s" }}>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Box style={{ alignItems: "center", display: 'flex', justifyContent: 'space-between', width: '100%' }}>
                    <Typography variant="body2">
                        #<em style={{ borderRight: '1px solid gray', paddingRight: '5px' }}>{row.id}</em> {row.dt}
                    </Typography>
                    <div style={{ marginLeft: 'auto' }}></div>
                    <Typography variant="body2" color={row.credit === user ? "success" : "error"} component={'div'} style={{ display: "flex", alignItems: "center" }}>
                        {row.paid_on === "Ludo Coin" ? <img src="/favicon.ico" width={"16px"} alt="ludo coin" /> : <AccountBalanceWalletIcon color={row.credit === user ? "success" : "error"} fontSize="small" />} &nbsp; <strong>{row.credit === user ? "" : "-"}{row.amount}</strong>
                    </Typography>
                </Box>
            </AccordionSummary>
            <AccordionDetails>
                {filter === null ? <Typography variant="body2" color={"text.secondry"} style={{ display: "flex", alignItems: "center" }}> Closing Balance &nbsp;&nbsp; <img src="/favicon.ico" alt="ludo coin" width={"22px"} style={{ margin: "5px" }} /> {balance[row.id]} &nbsp; <AccountBalanceWalletIcon style={{ margin: "5px" }} /> {reward[row.id]}</Typography> : ""}
                <Typography variant="subtitle1">{row.description}</Typography>
            </AccordionDetails>
        </Accordion>
    )
}

function Transaction() {
    const [transaction, setTransaction] = useState(null);
    const [user, setUser] = useState(-1);
    const [expanded, setExpanded] = useState(false);
    const [account, setAccount] = useState(null);
    const [filter, setFilter] = useState(null);
    const [page, setPage] = useState(1);
    const [hold, setHold] = useState(false);
    const [emailAlert, setEmailAlert] = useState(true);
    const [submit, setSubmit] = useState(false);

    const handleChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    };

    if (sessionStorage.getItem("mail_asked") && emailAlert) {
        setEmailAlert(false);
    }
    function handleMailSubmit(event) {
        event.preventDefault();
        setSubmit(true);
        setEmailAlert(false);
        const data = new FormData(event.currentTarget);
        if (!(/^[a-zA-Z0-9\.]+@[a-zA-Z0-9]+\.[A-Za-z]+$/.test(data.get('email')))) {
            alert("Please enter a valid email address");
            return;
        }
        updateMail(data.get('email')).then((_) => {
            sessionStorage.setItem("mail_asked", true);
            setSubmit(false);
        }).catch((err) => {
            console.error(err);
            setSubmit(false);
            setEmailAlert(true);
        });
    }

    let trial_bal = {};
    let trial_reward = {};

    if (transaction === null) {
        getUserData().then((json) => {
            setAccount(json.message);
            return getTransaction();
        }).then((data) => {
            if (data.code !== 200) {
                alert(data.message);
            } else {
                setTransaction(data.message);
                setUser(data.user);
            }
            trackerLog("TRANS");
        }).catch((err) => {
            console.warn(err);
        });
    }

    if (transaction !== null && account !== null) {
        let cub = account.balance;
        let cur = account.rewards;
        transaction.forEach(item => {
            trial_bal[item.id] = cub;
            trial_reward[item.id] = cur;
            if (item.paid_on === "Ludo Coin") {
                if (item.credit === user) {
                    cub = cub - item.amount;
                } else {
                    cub = cub + item.amount;
                }
            } else if (item.paid_on === "Reward") {
                if (item.credit === user) {
                    cur = cur - item.amount;
                } else {
                    cur = cur + item.amount;
                }
            }
        });
        if (hold === false && (cub !== account.opening_balance || cur !== account.opening_rewards)) {
            fetch(getApi() + "accounts/hold?name=" + account.username + "&token=" + getLoginToken()).then((res) => {
                return res.json();
            }).then((json) => {
                console.log(json);
            }).catch((err) => {
                console.log(err);
            });
            setHold(true);
        }
    }

    return (
        <MenuFrame user={account}>
            <Helmet>
                <title>Khelboludo - Transaction Details</title>
            </Helmet>
            <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={submit}>
                <CircularProgress color="inherit" />
            </Backdrop>
            {account === null ? "" :
                <Dialog open={(account.email === '' || account.mlv === 0) && emailAlert} onClose={() => { }} component="form" onSubmit={handleMailSubmit}>
                    <DialogTitle>
                        What is your email address?
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText component={"div"}>
                            <Typography variant="body1" mb={2}>Be updated about financial transaction via mail.</Typography>
                            <Typography variant="subtitle2" color={"info.main"} mb={2}>You will receive mail on any withdraw, deposit and suspicious transaction request. आपको किसी भी निकासी, जमा और संदिग्ध लेनदेन अनुरोध पर मेल प्राप्त होगा।</Typography>
                            <TextField label="Your email address" variant="outlined" name="email" required fullWidth id="email" type="email" />
                            <FormGroup>
                                <FormControlLabel control={<Checkbox />} label="Notify me on mail me about discount and offers." />
                            </FormGroup>
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={() => { setEmailAlert(false); }} variant="contained" color="warning" autoFocus>Skip for now</Button>
                        <Button type="submit" variant="contained" color="success" autoFocus>Save</Button>
                    </DialogActions>
                </Dialog>
            }
            <Container className="main-frame">


                <Stack direction="row" spacing={1} style={{ maxWidth: "100%", overflowX: "scroll", paddingBottom: "15px", marginTop: "10px" }} className="animate__animated animate__fadeInDown">
                    <Chip label="All" variant={filter === null ? "filled" : "outlined"} onClick={() => { setFilter(null) }} />
                    <Chip label="Wallet" variant={filter === "Wallet" ? "filled" : "outlined"} onClick={() => { setFilter("Wallet") }} />
                    <Chip label="Referral" variant={filter === "Referral" ? "filled" : "outlined"} onClick={() => { setFilter("Referral") }} />
                </Stack>

                {getTransactionView(transaction, account, user, filter, expanded, handleChange, trial_bal, trial_reward, page)}

                {transaction == null ? "" : <Pagination style={{ marginTop: '10px' }} count={transaction == null ? 0 : Math.ceil(transaction.length / 15)} color="primary" onChange={(e, v) => { setPage(v); }} />}
            </Container>
            <br />
            <LoginFooter />

            <Dialog
                open={hold}>
                <DialogTitle id="alert-dialog-title" color={"warning.main"}>
                    Warning ! Temporary Hold
                </DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        There is a pending transaction in your account. We are working on it, for more assistance you can connect at live chat.<br />
                        आपके खाते में कोई लेनदेन लंबित है. हम इस पर काम कर रहे हैं, अधिक सहायता के लिए आप लाइव चैट पर जुड़ सकते हैं।
                    </DialogContentText>
                </DialogContent>
                {/* <DialogActions>
                    <Button onClick={this.handleClose} autoFocus>Close</Button>
                </DialogActions> */}
            </Dialog>
        </MenuFrame>
    );
}

export default Transaction;