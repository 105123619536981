import { useState } from "react";
import MenuFrame from "../../component/menu_frame";
import { getApi } from "../../shared/constant";
import { Backdrop, Button, CircularProgress, Container, Typography } from "@mui/material";
import { clearLogs } from "../../shared/maintenance";
import { Helmet } from "react-helmet";

function RequestLog() {
    const [logs, setLogs] = useState("");
    const [submit, setSubmit] = useState(false);

    function loadlogs() {
        setSubmit(true);
        fetch(getApi() + "system/extralog").then((res) => {
            return res.text();
        }).then((text) => {
            setLogs(text);
            setSubmit(false);
        }).catch((err) => {
            console.log(err);
            setSubmit(false);
        });
    }
    function clearLogs01() {
        setSubmit(true);
        clearLogs().then((_) => {
        }).then((_) => {
            setLogs("");
            setSubmit(false);
        }).catch((err) => {
            console.log(err);
            setSubmit(false);
        });
    }

    return (
        <MenuFrame>
            <Helmet>
                <title>HTTP request logs</title>
            </Helmet>
            <Container>
                <Button variant="contained" color="success" onClick={clearLogs01} style={{ marginTop: "10px" }} fullWidth>Clean</Button>
                <Button variant="contained" color="warning" onClick={loadlogs} style={{ marginTop: "10px" }} fullWidth>Load logs</Button>
                <Typography variant="caption" color="warning.main">Large file warning. It might hang your device.</Typography>
            </Container>
            <div style={{ overflowX: "scroll" }}>
                <pre>
                    {logs}
                </pre>
            </div>
            <Backdrop open={submit} ><CircularProgress /></Backdrop>
        </MenuFrame>
    );
}

export default RequestLog;