import { useState } from "react";
import MenuFrame from "../../component/menu_frame";
import { getApi } from "../../shared/constant";
import { Alert, AlertTitle, Backdrop, Card, CircularProgress, Container, Grid, Link } from "@mui/material";
import { Helmet } from "react-helmet";

function Server() {

    const [config, setConfig] = useState(null);

    if (config === null) {
        fetch(getApi() + "system/get").then((res) => {
            return res.json();
        }).then((json) => {
            setConfig(json);
        })
    }

    function getCells() {
        let data = [];
        for (var key in config) {
            if (config.hasOwnProperty(key)) {
                var value = config[key];
                if (Array.isArray(value)) {
                    value = value.join("<br/>");
                }
                data.push((
                    <>
                        <Grid key={key + "item"} item xs={3}><strong>{key}</strong></Grid>
                        <Grid key={key + "val"} item xs={3} dangerouslySetInnerHTML={{ __html: value }}></Grid>
                    </>
                ));
            }
        }
        return data;
    }

    return (
        <MenuFrame>
            <Helmet>
                <title>Khelboludo Server Information</title>
            </Helmet>
            <Backdrop open={config === null} ><CircularProgress /></Backdrop>
            {config === null ? "" :
                <>
                    {config["virtual_memory_percent"] > 85 ? <Container style={{ marginTop: "10px" }}>
                        <Alert severity="warning">
                            <AlertTitle>Critical RAM usage</AlertTitle>
                            Your <strong>RAM</strong> usage touched the critical limit please restart the server or scale up. Current usage {config["virtual_memory_percent"]}%
                        </Alert>
                    </Container> :""}
                    {config["cpu_percent"] > 85 ?<Container style={{ marginTop: "10px" }}>
                        <Alert severity="error">
                            <AlertTitle>Critical CPU usage</AlertTitle>
                            Your <strong>CPU</strong> usage {config["cpu_percent"]}% is higher than normal limit please restart the server or scale up.
                        </Alert>
                    </Container>:""}
                    {config["disk_usage"] > 85 ? <Container style={{ marginTop: "10px" }}>
                        <Alert severity="error">
                            <AlertTitle>Critical Disk usage</AlertTitle>
                            Your <strong>Hard Disk</strong> usage touched the critical limit please clean the server or scale up. Current usage {config["disk_usage"]}%
                        </Alert>
                    </Container> :""}
                    <Card style={{ padding: 10, margin: "10px 20px" }}>
                        <Grid container spacing={2} sx={{ minWidth: "100%" }}>
                            {getCells()}
                        </Grid>
                    </Card>
                    <Container>
                        <Link href="/api/v1">Garbage collection</Link>
                    </Container>
                </>
            }
        </MenuFrame>
    );
}

export default Server;