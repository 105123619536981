import React, { useState } from "react";
import "../../css/game_item.css";
import "../../css/common.css"
import MenuFrame from "../../component/menu_frame";
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardMedia from '@mui/material/CardMedia';
import CardContent from '@mui/material/CardContent';
import Avatar from '@mui/material/Avatar';
import Typography from '@mui/material/Typography';
import { red } from '@mui/material/colors';
import { CardActionArea, Container, Grid } from "@mui/material";
import { useNavigate } from "react-router-dom";
import LoginFooter from "../../component/login_footer";
import { getApi } from "../../shared/constant";
import 'animate.css';
import { trackerLog } from "../../shared/tracker";
import { Helmet } from "react-helmet";

function Games() {
    const [games, setGames] = useState([]);
    const navigate = useNavigate()

    if (games.length === 0)
        fetch(getApi() + "games/get-all").then((res) => {
            if (res.status !== 200)
                throw res;
            return res.json();
        }).then((data) => {
            setGames(data.message);
            trackerLog("GMLST");
        }).catch((err) => {
            console.error(err);
        });

    return (
        <MenuFrame warning="page_game">
            <Helmet>
                <title>Khelboludo - Online Games</title>
            </Helmet>
            <br />
            <Container className="main-frame">
                <Grid container spacing={2} justifyContent={'center'} alignItems={'stretch'}>{
                    games.map((game, index) => {
                        return (
                            <Grid key={index} item xs={12} sm={4} md={3} style={{ cursor: game.status === 'Active' ? 'pointer' : 'not-allowed', justifyContent: "end" }} onClick={() => {
                                if (game.status !== 'Active') return;
                                if (game.game_type === "Main") {
                                    navigate("" + game.game_id);
                                } else {
                                    navigate("challenge/" + game.game_id)
                                }
                            }} >
                                <Card className={game.status === "Active" ? "animate__animated animate__zoomIn" : "game-card animate__animated animate__zoomIn"} style={{ height: '100%', animationDelay: (0.2 * index) + "s" }}>
                                    <CardActionArea>
                                        <CardHeader
                                            avatar={<Avatar sx={{ bgcolor: red[500] }} aria-label="recipe">{game.name[0]}</Avatar>}
                                            title={game.name}
                                            subheader={game.status === "Active" ? (game.online * 2) + 23 + " player playing on lobby" : ""} />
                                        <CardMedia component="img" height="194" image={game.image} alt={game.name} ></CardMedia>
                                        {/* <Chip label={game.min_amount + " - "+ game.max_amount + " ludo coin"} />  */}
                                        <CardContent>
                                            <Typography variant="body2" color="text.secondary">
                                                {game.description}
                                            </Typography>
                                        </CardContent>
                                    </CardActionArea>
                                </Card>
                            </Grid>
                        )
                    })
                }
                </Grid>
            </Container>
            <br />
            <LoginFooter />
        </MenuFrame>
    );
}

export default Games;