import React, { useEffect, useState } from "react";
import "../../css/common.css";
import MenuFrame from "../../component/menu_frame";
import Typography from '@mui/material/Typography';
import { Alert, Button, ButtonGroup, CircularProgress, Collapse, Container, Grid, Paper, Slider, TextField, Tooltip } from "@mui/material";
import { LIGHT_DUMMY, RICH_DUMMY, getApi, getLoginToken } from "../../shared/constant";
import { useNavigate, useParams } from "react-router-dom";
import EmojiEventsIcon from '@mui/icons-material/EmojiEvents';
import ShieldIcon from '@mui/icons-material/Shield';
import SportsKabaddiIcon from '@mui/icons-material/SportsKabaddi';
import LoginFooter from "../../component/login_footer";
import { ToastContainer, toast } from "react-toastify";
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import { RedirectOnGameOngoing } from "../../shared/redirect_manager";
import { cancelBeforeJoin, getAllChallangerMatches, getMyMatches } from "../../shared/match_methods";
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import { trackerLog } from "../../shared/tracker";
import 'animate.css';
import { Helmet } from "react-helmet";

function ChallengeRoom() {
    const navigate = useNavigate();
    const { game } = useParams();
    const [submit, setSubmit] = useState(false);
    const [gameRooms, setGamesRooms] = useState(null);
    const [challangeAmount, setChallangeAmount] = useState(0);
    const [gameError, setGameError] = useState(null);
    const [currentGame, setCurrentGame] = useState(null);
    const [thisgame, setThisGame] = useState(null);

    useEffect(() => {
        // 1 min reload time for games room, page reload
        const interval = setInterval(async () => {
            if (currentGame !== null) {
                await getMyMatches().then((res) => {
                    if (res.code === 200) { 
                        setCurrentGame(res.message);
                    }
                }).catch((err) => {
                    console.error(err);
                });
            }
            getAllChallangerMatches(game).then((data) => {
                if (data.code === 200) {
                    if (thisgame !== null) {
                        const time_key = new Date().getMinutes();
                        const count_key = (new Date().getMinutes() % 4) + 10;
                        for (let i = time_key, j = 0; j < count_key && i + (thisgame.game_id * 5) < 73; i++, j++) {
                            let match = {};
                            if (thisgame.max_amount === 20000) {
                                match = RICH_DUMMY[i];
                            } else {
                                match = LIGHT_DUMMY[i + (thisgame.game_id)]
                            }
                            data.message.push(match);
                        }
                    }

                    setGamesRooms(data.message);
                }
            }).catch((err) => {
                console.error(err);
            });
        }, 30000);
        return () => clearInterval(interval);
    }, [game, currentGame, thisgame]);

    async function initData() {
        await getMyMatches().then((res) => {
            if (res.code === 200) { 
                setCurrentGame(res.message); 
            }
            return fetch(getApi() + "games/get-game?gameid=" + game);
        }).then((res) => {
            return res.json();
        }).then((json) => {
            console.log(json);
            if (json.code === 200) {
                setThisGame(json.message);
                setChallangeAmount(json.message.min_amount);
            }
            return getAllChallangerMatches(game);
        }).then((data) => {
            if (data.code !== 200) {
                setGamesRooms([]);
                setGameError(data.message);
            } else {
                if (thisgame !== null) {
                    const time_key = new Date().getMinutes();
                    const count_key = (new Date().getMinutes() % 4) + 10;
                    for (let i = time_key, j = 0; j < count_key && i + (thisgame.game_id * 5) < 73; i++, j++) {
                        let match = {};
                        if (thisgame.max_amount === 20000) {
                            match = RICH_DUMMY[i];
                        } else {
                            match = LIGHT_DUMMY[i + (thisgame.game_id)]
                        }
                        data.message.push(match);
                    }
                }

                setGamesRooms(data.message);
                setGameError(null);
            }
            if (thisgame !== null && (!localStorage.getItem("POPUP_GAME_" + thisgame.game_id) || new Date().getTime() - localStorage.getItem("POPUP_GAME_" + thisgame.game_id) > 3600000)) {
                localStorage.setItem("POPUP_GAME_" + thisgame.game_id, new Date().getTime())
                toast('🏆 You can play ' + thisgame.min_amount + '-' + thisgame.max_amount + ' game in ' + thisgame.name.toLowerCase() + '!', {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                    toastId: "POPUP_GAME_" + thisgame.game_id,
                });
            }
        }).catch((err) => {
            console.error(err);
        });
        await trackerLog("CROOM");
    }

    if (gameRooms === null) {
        initData();
    }

    function cancelBefore(pid) {
        cancelBeforeJoin(pid).then((data) => {
            if (data.code !== 200) {
                setGameError(data.message);
            } else {
                setCurrentGame(null);
                initData();
            }
        }).catch((err) => {
            console.error(err);
        });
    }

    async function createGameRoom(fee) {
        setSubmit(true);
        if (currentGame !== null) {
            setGameError("A game is already in progress. Please cancel the current game if you want to start new game.");
            setSubmit(false);
            return;
        } else if (challangeAmount < thisgame.min_amount) {
            setGameError("You should enter more than " + thisgame.min_amount + " coin in " + thisgame.name.toLowerCase() + ".");
            setSubmit(false);
            return;
        } if (challangeAmount % 50 !== 0) {
            setGameError("You should enter coin multiple of 50 in rich mode.");
            setSubmit(false);
            return;
        } else if (challangeAmount > thisgame.max_amount) {
            setGameError("Currently, we allow up to " + thisgame.max_amount + " coins per game in " + thisgame.name.toLowerCase() + ". Please enter valid coins.");
            setSubmit(false);
            return;
        }
        if (currentGame !== null) {
            await cancelBefore(currentGame.pid);
        }
        fetch(getApi() + "games/challanger/room", {
            method: 'POST',
            body: JSON.stringify({
                "game": game,
                "challenge": fee,
                "token": getLoginToken()
            })
        }).then((res) => {
            return res.json();
        }).then((data) => {
            if (data.code === 200 && data.status === 'waiting') {
                navigate("/user/games/roomcode/user/" + data.message)
            } else if (data.code !== 200) {
                setGameError(data.message);
            } else {
                initData();
            }
            setSubmit(false);
        }).catch((err) => {
            console.error(err);
            setSubmit(false);
        });
    }

    function isOngoing(room) {
        return currentGame !== null && currentGame.match_id === room.match_id;
    }

    return (
        <MenuFrame warning="page_rich">
            <Helmet>
                <title>Khelboludo - Challange</title>
            </Helmet>
            <RedirectOnGameOngoing pid={(currentGame !== null && currentGame.game_room_code !== null) ? currentGame.pid : null} />
            <ToastContainer position="top-right" autoClose={5000} hideProgressBar={false} newestOnTop={false} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover theme="light" />
            {/* <CurrentGameAlert data={curGmAlert} /> */}

            <Container className="main-frame">
                <Collapse in={gameError !== null} >
                    <Alert severity="error" icon={false}><div dangerouslySetInnerHTML={{ __html: gameError }}></div></Alert>
                </Collapse>
                {thisgame === null ? "" :
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={12} md={12}>
                            <Slider aria-label="Challange Amount" value={isNaN(challangeAmount) ? 0 : Number(challangeAmount)} step={50} min={thisgame.min_amount} max={thisgame.max_amount} onChange={(event, newValue) => { setChallangeAmount(newValue) }} />
                        </Grid>
                        <Grid item xs={12} sm={9} md={10}>
                            <TextField fullWidth label="Challange Amount" type="text" variant="outlined" inputProps={{ step: 50, min: 500, max: 20000 }} placeholder={thisgame.min_amount + " to " + thisgame.max_amount} onChange={(event) => { setChallangeAmount(event.target.value) }} value={challangeAmount} />
                        </Grid>
                        <Grid item xs={12} sm={3} md={2}>
                            <Button fullWidth variant="contained" style={{ height: '100%' }} onClick={() => { createGameRoom(challangeAmount); }} disabled={submit}>Challange</Button>
                        </Grid>
                    </Grid>}
                <br />
                <Grid container spacing={2}>
                    {
                        gameRooms === null ? '' : gameRooms.map((room, index) => {
                            if (room === null || room.names === null) return "";
                            return (
                                <Grid key={index} item xs={12} sm={6}>
                                    <Paper style={{ padding: '5px', height: '100%', width: '100%', animationDelay: (0.1 * index) + "s" }} className="animate__animated animate__fadeIn">
                                        <Grid container alignItems={'center'} spacing={2}>
                                            <Grid item xs={12}>
                                                <Grid container textAlign={'center'}>
                                                    <Grid item xs={12} style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}>
                                                        <EmojiEventsIcon color="primary" />
                                                        <Typography variant="subtitle" color="primary" style={{ verticalAlign: 'middle', fontWeight: "bold" }}>
                                                            &nbsp; Entry coin {room.entry_fee}
                                                        </Typography>
                                                    </Grid>
                                                    <Grid item xs={12} style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>
                                                        <Tooltip title={room.names.split(",")[0]}>
                                                            <Typography variant="body1" color="text.primary" style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}>
                                                                <AccountCircleIcon style={{ color: "rgb(" + Math.floor(Math.random() * 100) + "," + Math.floor(Math.random() * 255) + "," + Math.floor(100 + (Math.random() * 155)) + ")" }} />
                                                                &nbsp; {room.names.split(",")[0]}
                                                            </Typography>
                                                        </Tooltip>
                                                        {/* <CasinoIcon /> */}
                                                        <img src="/vs_icon.png" alt="VS icon" width={20} />
                                                        {room.names.split(",").length === 1 ?
                                                            <Typography variant="subtitle2" color={'success.main'} ><strong>Waiting ...</strong></Typography> :
                                                            <Tooltip title={room.names.split(",")[1]}>
                                                                <Typography variant="body1" color="text.primary" style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}>
                                                                    {room.names.split(",")[1]} &nbsp;
                                                                    <AccountCircleIcon style={{ color: "rgb(" + Math.floor(100 + (Math.random() * 155)) + "," + Math.floor(Math.random() * 255) + "," + Math.floor(Math.random() * 100) + ")" }} />
                                                                </Typography>
                                                            </Tooltip>
                                                        }
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                            <Grid item xs={12}>
                                                {isOngoing(room) ?
                                                    <ButtonGroup fullWidth>
                                                        {/* <Button variant="contained" onClick={() => { window.location.reload() }} color="success" ><Refresh size={24} /> &nbsp; Play</Button> */}
                                                        <Button variant="contained" onClick={() => { cancelBefore(currentGame.pid); }} color="error" ><ExitToAppIcon size={24} /> &nbsp; Cancel</Button>
                                                    </ButtonGroup> :
                                                    room.result === 'Awaiting' ?
                                                        <Button fullWidth disabled={submit} variant="contained" onClick={() => { setChallangeAmount(room.entry_fee); createGameRoom(room.entry_fee) }} >
                                                            {submit ? (<CircularProgress color="primary" size={24} />) : <><ShieldIcon fontSize="small" /> &nbsp; Accept</>}
                                                        </Button> :
                                                        <Button fullWidth disabled={submit} variant="outlined" color="success" ><SportsKabaddiIcon fontSize="small" /> &nbsp; <strong>{room.result}</strong></Button>
                                                }
                                            </Grid>
                                        </Grid>
                                    </Paper>
                                </Grid>
                            )
                        })
                    }
                </Grid>
            </Container>
            <br />
            <LoginFooter />
        </MenuFrame>
    )
}

export default ChallengeRoom;