import MenuFrame from "../../component/menu_frame";
import { useNavigate } from "react-router-dom";
import { getApi, getLoginToken } from "../../shared/constant";
import { useState } from "react";
import { Container, Typography } from "@mui/material";
import LoginFooter from "../../component/login_footer";
import { Helmet } from "react-helmet";

function DocumentKYC() {
    const navigate = useNavigate();
    const [user, setUser] = useState(null);

    if (user === null) {
        fetch(getApi() + "get-user?token=" + getLoginToken()).then((res) => {
            return res.json();
        }).then((json) => {
            if (json.code !== 200) {
                navigate('/user/profile/logout');
            } else {
                setUser(json.message);
            }
        }).catch((e) => {
            console.error(e);
        });
    }

    return (
        <MenuFrame user={user} warning="page_kyc">
            <Helmet>
                <title>Khelboludo - KYC</title>
            </Helmet>
            <Container className="main-frame" style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column', textAlign: 'center' }}>
                <Typography variant="h3">KYC Verification</Typography>
                <Typography variant="body2">We will contact you for KYC verification in your phone.</Typography>
                {user !== null && user.kyc === 0 ?
                    <Typography variant="h4" color={'warning.main'}>Unverified</Typography> :
                    <Typography variant="h4" color={'success.main'}>Verified</Typography>
                }
            </Container>
            <LoginFooter />
        </MenuFrame>
    );
}


export default DocumentKYC;