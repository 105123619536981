import { Alert, Autocomplete, Avatar, Box, Button, CircularProgress, Collapse, Container, CssBaseline, FormControl, FormHelperText, Grid, InputLabel, MenuItem, Select, TextField, Typography } from "@mui/material";
import MenuFrame from "../../component/menu_frame";
import { Note } from "@mui/icons-material";
import { useState } from "react";
import { getApi, getLoginToken } from "../../shared/constant";
import { addAccountNote } from "../../shared/user";
import 'animate.css';
import { Helmet } from "react-helmet";

function DebitNote() {
    const [info, setInfo] = useState("");
    const [submit, setSubmit] = useState(false);
    const [debtors, setDebtors] = useState(null);
    const [creditors, setCreditors] = useState(null);
    const [debtorsBal, setDebtorsBal] = useState(null);
    const [creditorsBal, setCreditorsBal] = useState(null);
    const [coin, setCoin] = useState("Ludo Coin");

    const handleChange = (event) => {
        setCoin(event.target.value);
    };

    function handleSubmit(event) {
        event.preventDefault();
        setSubmit(true)
        let err = "";
        const data = new FormData(event.currentTarget);
        if (!data.get("debtors")) {
            err = "Crediting account must not empty.";
        } else if (!data.get("creditors")) {
            err = "Debiting account must not empty";
        } else if (data.get("amount") <= 0) {
            err = "Amount should be greter than 0.";
        } else if (data.get("description") <= 0) {
            err = "Please write a proper justification for transaction. कृपया लेन-देन का उचित औचित्य लिखें।";
        }
        if (err !== "") {
            setInfo(err);
            setSubmit(false);
            return
        }

        addAccountNote(data.get("amount"), data.get("creditors"), data.get("debtors"), data.get("description"), "Refund", coin, 0).then((json) => {
            if (json.message === 0) {
                setInfo("Insufficent balance in debiting account.");
            } else if (json.message === 1) {
                setInfo("Successfully transferred the money.");
                document.forms[0].reset();
            } else {
                setInfo(json.message);
            }
            setSubmit(false);
        }).catch((err) => {
            console.log(err);
            setSubmit(false);
        })
    }

    if (debtors === null) {
        fetch(getApi() + "accounts/all-account-name?note=D&token=" + getLoginToken()).then((res) => {
            return res.json();
        }).then((json) => {
            // console.log(json);
            setDebtors(json.debtors);
            setCreditors(json.creditors);
        }).catch((err) => {
            console.log(err);
        })
    }

    return (
        <MenuFrame>
            <Helmet>
                <title>Give reward and payout</title>
            </Helmet>
            <Container component="main" maxWidth="xs">
                <CssBaseline />
                <Box sx={{ marginTop: 2, display: 'flex', flexDirection: 'column', alignItems: 'center', }} mb={10} className="animate__animated animate__bounceIn">
                    <Avatar sx={{ m: 1, bgcolor: 'error.main' }}>
                        <Note />
                    </Avatar>
                    <Typography component="h1" variant="h5">
                        Refund
                    </Typography>
                    <Typography component="h1" variant="body2" color={"text.secondary"}>
                        Debit Note : Cash Outflow
                    </Typography>
                    <Typography component="h1" variant="caption" textAlign={"center"} color={"error"}>
                        The cash will be deducted from your account and get added to client account.
                    </Typography>
                    {debtors === null || creditors === null ? "" :
                        <Box component="form" noValidate onSubmit={handleSubmit} sx={{ mt: 3 }} id="regester-form">
                            <Grid container spacing={2} mb={1}>
                                <Grid item xs={12}>
                                    <Autocomplete required disablePortal id="creditors"
                                        defaultValue={creditors.filter((data) => data.user_id === 4)[0]}
                                        renderOption={(props, option) => (
                                            <Box component="li" {...props}> {option.display_name}  - {option.username}</Box>
                                        )}
                                        onChange={(e, v) => { setCreditorsBal(v); }}
                                        getOptionLabel={(cr) => cr.username} options={creditors} renderInput={(params) => <TextField {...params} name="creditors" label={"Debiting account (from account)"} />} />
                                </Grid>
                                <Grid item xs={12}>
                                    <Autocomplete required disablePortal id="debtors"
                                        renderOption={(props, option) => (
                                            <Box component="li" {...props}> {option.display_name}  - {option.username}</Box>
                                        )}
                                        onChange={(e, v) => { setDebtorsBal(v); }}
                                        getOptionLabel={(dr) => dr.username} options={debtors} renderInput={(params) => <TextField {...params} name="debtors" label={"Crediting account (to account)"} />} />
                                    {debtorsBal === null ? "" :
                                        <FormHelperText>Balance: {debtorsBal.balance} Reward: {debtorsBal.rewards}</FormHelperText>
                                    }
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField fullWidth id="amount" label="Amount" name="amount" type="number" inputProps={{ min: 0 }} />
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField required fullWidth id="description" label="Description" name="description" type="text" />
                                </Grid>
                                <Grid item xs={12}>
                                    <FormControl fullWidth>
                                        <InputLabel id="coins">Coin</InputLabel>
                                        <Select labelId="coins" id="coins" value={coin} label="Coin" onChange={handleChange}>
                                            <MenuItem value={"Auto"}>Auto</MenuItem>
                                            <MenuItem value={"Reward"}>Reward Coin - Withdrawal</MenuItem>
                                            <MenuItem value={"Ludo Coin"}>Ludo Coin - Gameplay</MenuItem>
                                        </Select>
                                    </FormControl>
                                </Grid>
                            </Grid>
                            <Collapse in={info !== ""}>
                                <Alert severity="info" onClose={() => { setInfo("") }}>{info}</Alert>
                            </Collapse>
                            <Button type="submit" fullWidth variant="contained" sx={{ mt: 3, mb: 2 }} disabled={submit}>
                                {submit ? (<CircularProgress color="primary" size={24} />) : ("Submit")}
                            </Button>
                            <Typography component="h1" variant="caption" textAlign={"center"} color={"info.main"}>
                                Warning: Auto refund give refund on Ludo Coin if Ludo Coin not available <strong>gives in Reward Coin</strong>
                            </Typography>
                        </Box>
                    }
                </Box>
            </Container>
        </MenuFrame>
    );
}

export default DebitNote;