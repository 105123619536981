import { Button, CircularProgress } from "@mui/material";
import { userLogout } from "../../../shared/user";

function Logout() {
    userLogout().then(() => {
        window.location.reload();
    }).catch((err) => {
        console.log(err);
    });

    return (
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column', height: '100vh' }}>
            <CircularProgress color="primary" />
            <Button onClick={() => { window.location.reload(); }}><h3>Please wait !</h3> &nbsp; &nbsp; logout in progress ... </Button>
        </div>);
}

export default Logout;
