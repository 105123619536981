import { Box, Button, CircularProgress, Container, Grid, Link, Paper, TextField, Typography } from "@mui/material";
import MenuFrame from "../../component/menu_frame";
import { useState } from "react";
import QRCode from "react-qr-code";
import { getAdminConfig, setAdminConfig } from "../../shared/config";
import LoginFooter from "../../component/login_footer";
import { Helmet } from "react-helmet";

function AdminSetting() {
    const [submit, setSubmit] = useState(false);
    const [upi, setUPI] = useState(null);
    const [upiName, setUpiName] = useState(null);
    const [upiPhone, setUpiPhone] = useState(null);
    const [whatsapp, setWhatsApp] = useState(null);
    const [roomcode, setRoomcode] = useState(null);
    const [defaultPayment, setDefaultPayment] = useState(null);
    const [phonepeMerchant, setPhonepeMerchant] = useState(null);

    if (upi === null) {
        getAdminConfig("upi_add,upi_name,upi_phone,whatsapp,roomcode_method,default_payment,phonepe_merchant").then((json) => {
            // console.log(json);
            setUPI(json.message["upi_add"]);
            setUpiName(json.message["upi_name"]);
            setUpiPhone(json.message["upi_phone"]);
            setWhatsApp(json.message["whatsapp"]);
            setRoomcode(json.message["roomcode_method"]);
            setDefaultPayment(json.message["default_payment"]);
            setPhonepeMerchant(json.message["phonepe_merchant"]);
        }).catch((err) => {
            console.log(err);
        });
    }

    function handleUpiSubmit(event) {
        event.preventDefault();
        setSubmit(true);
        const data = new FormData(event.currentTarget);
        // console.log(data.get("phone"));
        setAdminConfig("upi_add", data.get("upi_address")).then((_) => {
            return setAdminConfig("upi_name", data.get("name"));
        }).then((_) => {
            return setAdminConfig("upi_phone", data.get("phone"))
        }).then((res) => {
            if (res.code === 200) {
                document.location.reload();
            }
            setSubmit(false);
        }).catch((err) => {
            console.log(err);
            setSubmit(false);
        });
    }

    function handleWhatsAppSubmit(event) {
        event.preventDefault();
        setSubmit(true);
        const data = new FormData(event.currentTarget);
        setAdminConfig("whatsapp", data.get("whatsapp")).then((resd) => {
            if (resd.code === 200) {
                document.location.reload();
            }
            setSubmit(false);
        }).catch((err) => {
            console.log(err);
            setSubmit(false);
        });
    }

    function handleRoomcodeSubmit(event) {
        event.preventDefault();
        setSubmit(true);
        const data = new FormData(event.currentTarget);
        if (data.get("roomcode") !== "SELF" && data.get("roomcode") !== "API") {
            alert("Invalid game roomcode genration method.")
            setSubmit(false);
            return;
        }
        setAdminConfig("roomcode_method", data.get("roomcode")).then((resd) => {
            if (resd.code === 200) {
                document.location.reload();
            }
            setSubmit(false);
        }).catch((err) => {
            console.log(err);
            setSubmit(false);
        });
    }

    function handlePaymentSubmit(event) {
        event.preventDefault();
        setSubmit(true);
        const data = new FormData(event.currentTarget);
        if (data.get("payment") !== "UPI" && data.get("payment") !== "PHONEPE") {
            alert("Invalid payment method.")
            setSubmit(false);
            return;
        }
        console.log(data.get("merchant"));
        setAdminConfig("default_payment", data.get("payment")).then((_) => {
            return setAdminConfig("phonepe_merchant", data.get("merchant"));
        }).then((res) => {
            if (res.code === 200) {
                document.location.reload();
            }
            setSubmit(false);
        }).catch((err) => {
            console.log(err);
            setSubmit(false);
        });
    }

    const upiLink = "upi://pay?pa=" + upi + "&cu=INR&pn=" + upiName;

    return (
        <MenuFrame>
            <Helmet>
                <title>KhelboLudo Master Control</title>
            </Helmet>
            <Container maxWidth="lg">
                {upi === null || upiPhone === null || upiName === null ? "" :
                    <Box component="form" noValidate onSubmit={handleUpiSubmit} sx={{ mt: 3 }} id="regester-form">
                        <Paper style={{ padding: "5px" }}>
                            <Grid container spacing={2}>
                                {upi === null ? "" :
                                    <Grid item xs={12} sm={6}>
                                        <TextField name="upi_address" required defaultValue={upi} fullWidth id="upi_address" label="UPI Address" />
                                    </Grid>
                                }
                                {upiName === null ? "" :
                                    <Grid item xs={12} sm={6}>
                                        <TextField required fullWidth id="name" defaultValue={upiName} label="Name of UPI" name="name" />
                                    </Grid>
                                }
                                {upiPhone === null ? "" :
                                    <Grid item xs={12} sm={6}>
                                        <TextField required fullWidth id="phone" defaultValue={upiPhone} label="Phone number for PhonePay, Paytm and GPay" name="phone" />
                                    </Grid>
                                }
                                {upi === null || upiName === null ? "" :
                                    <Grid item xs={12} sm={6} style={{ textAlign: 'center' }}>
                                        <Typography variant="body1">Current UPI address</Typography>
                                        <Link href={upiLink} textAlign={"center"}>
                                            <QRCode value={upiLink} style={{ height: "auto", maxWidth: "100%", width: "128px" }} />
                                            <br />
                                            Click to check
                                        </Link>
                                    </Grid>
                                }
                            </Grid>

                            <Button type="submit" fullWidth variant="contained" sx={{ mt: 1 }} disabled={submit}>
                                {submit ? (<CircularProgress color="primary" size={24} />) : ("Update")}
                            </Button>
                        </Paper>
                    </Box>
                }
                {whatsapp === null ? "" :
                    <Box component="form" noValidate onSubmit={handleWhatsAppSubmit} sx={{ mt: 3 }} id="regester-form">
                        <Paper style={{ padding: "5px" }}>
                            {whatsapp === null ? "" : <Grid container spacing={2}>
                                <Grid item xs={12} sm={12}>
                                    <TextField name="whatsapp" required defaultValue={whatsapp} fullWidth id="whatsapp" label="WhatsApp support number" />
                                </Grid>
                            </Grid>}

                            <Button type="submit" fullWidth variant="contained" sx={{ mt: 1 }} disabled={submit}>
                                {submit ? (<CircularProgress color="primary" size={24} />) : ("Update")}
                            </Button>
                        </Paper>
                    </Box>
                }

                {roomcode === null ? "" :
                    <Box component="form" noValidate onSubmit={handleRoomcodeSubmit} sx={{ mt: 3 }} id="regester-form">
                        <Paper style={{ padding: "5px" }}>
                            {roomcode === null ? "" : <Grid container spacing={2}>
                                <Grid item xs={12} sm={12}>
                                    <TextField name="roomcode" required defaultValue={roomcode} fullWidth id="roomcode" label="Roomcode Method - SELF or API" placeholder="SELF or API"  />
                                </Grid>
                            </Grid>}

                            <Button type="submit" fullWidth variant="contained" sx={{ mt: 1 }} disabled={submit}>
                                {submit ? (<CircularProgress color="primary" size={24} />) : ("Save")}
                            </Button>
                        </Paper>
                    </Box>
                }
                {defaultPayment === null || phonepeMerchant === null ? "" :
                    <Box component="form" noValidate onSubmit={handlePaymentSubmit} sx={{ mt: 3 }} id="regester-form">
                        <Paper style={{ padding: "5px" }}>
                            <Grid container spacing={2}>
                                {defaultPayment === null ? "" :
                                    <Grid item xs={12} sm={6}>
                                        <TextField required fullWidth id="payment" defaultValue={defaultPayment} label="Default Payment (UPI or PHONEPE)" name="payment" />
                                    </Grid>
                                }
                                {phonepeMerchant === null ? "" :
                                    <Grid item xs={12} sm={6}>
                                        <TextField required fullWidth id="merchant" defaultValue={phonepeMerchant} label="Merchant ID on PhonePe" name="merchant" />
                                    </Grid>
                                }
                            </Grid>
                            <Button type="submit" fullWidth sx={{ mt: 1 }} variant="contained" disabled={submit}>
                                {submit ? (<CircularProgress color="primary" size={24} />) : ("Update")}
                            </Button>
                            {/* <Button fullWidth variant="outlined" sx={{ mt: 1 }} disabled={submit}>
                                {submit ? (<CircularProgress color="primary" size={24} />) : ("Update Payment Keys")}
                            </Button> */}
                        </Paper>
                    </Box>
                }
            </Container>
            <br />
            <LoginFooter />
        </MenuFrame>
    );
}

export default AdminSetting;
