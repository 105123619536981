import { Button, Typography } from "@mui/material";
import { getApi, getLoginToken } from "../shared/constant";
import 'animate.css';

function NoReferProgram(params) {
    function joinRefer() {
        fetch(getApi() + "refer/join?token=" + getLoginToken()).then((res) => {
            return res.json();
        }).then((json) => {
            if (json.code === 200) {
                params.onJoin(json.message);
            } else {
                alert(json.message);
            }
        }).catch((e) => {
            console.error(e);
        });
    };
    return (
        <div style={{ minHeight: '80vh', display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column', textAlign: 'center' }}>
            <img src="/refer1.png" alt="Refer" style={{ maxWidth: '100%' }} className="animate__animated animate__fadeInDown" />
            <Typography variant="h3">Join referrer program</Typography>
            <Typography variant="body2">Join now and earn 2% on every win of your friend</Typography>
            <Button variant="contained" color='success' style={{ margin: '20px 0' }} onClick={joinRefer}><Typography variant="h4">Join Now</Typography></Button>
            <Typography variant="body1" color={'text.secondary'}>Term and Condition</Typography>
        </div>
    );
}

export default NoReferProgram;