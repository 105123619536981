import { Box, Link, Typography } from "@mui/material";
import 'animate.css';

function Top3Card() {
    return (<>
        <div className="item animate__animated animate__fadeInUp animate__faster">
            <div className="img img-first"></div>
            <div className="card">
                <Typography variant='h4'>Ludo Classic</Typography>
                <Typography variant='body1' style={{ padding: '5px' }}><strong>Ludo coin 50 - 20000</strong></Typography>
                {/* <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p> */}
                <Link href="/user/games/challenge/1" className="blue">Play the Game</Link>
            </div>
        </div>
        <div className="item animate__animated animate__fadeInUp animate__faster" style={{ animationDelay: "0.3s" }}>
            <div className="img img-third"></div>
            <div className="card">
                <Typography variant='h4'>Snake and Ladder</Typography>
                <Typography variant='body1' style={{ padding: '5px' }}><strong>Ludo coin 50 - 5000</strong></Typography>
                {/* <p>Jumping from an aeroplane and falling through the air before opening your parachute. Kill the enemy and win the match.<br /></p> */}
                <Link href="/user/games/challenge/3" className="blue">Play Fast</Link>
            </div>
        </div>
        <Box sx={{ display: { xs: 'none', lg: 'block' } }}>
            <div className="item animate__animated animate__fadeInUp">
                <div className="img img-second"></div>
                <div className="card">
                    <Typography variant='h4'>Ludo Popular</Typography>
                    <Typography variant='body2'>Coming soon</Typography>
                    <p>We are soon introduce the popular mode of ludo king. Stay connected and for update<br /><br /></p>
                    <Link href="https://www.instagram.com/khelboludo/" className="blue">Subscribe</Link>
                </div>
            </div>
        </Box>
    </>);
}

export default Top3Card;