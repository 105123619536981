import { Alert, Box, Grid, Paper, TextField, Typography } from "@mui/material";
import MenuFrame from "../../component/menu_frame";
import { useState } from "react";
import { getAdminConfig, setAdminConfig } from "../../shared/config";
import { Container } from "@mui/system";
import "../../css/marquee.css";

function PageWarning() {
    const [data, setData] = useState(null);

    if (data === null) {
        getAdminConfig("top_warning,page_buy,page_contact,page_dashboard,page_game,page_history,page_kyc,page_lite,page_lucky,page_ongoing,page_profile,page_rich,page_sell,page_wallet,page_roomcode").then((json) => {
            console.log(json);
            setData(json.message)
        }).catch((err) => {
            console.log(err);
        });
    }

    function handleSubmit(key, value) {
        setAdminConfig(key, value).then((res) => {
            console.log(res);
        }).catch((err) => {
            console.log(err);
        });
    }

    const label = { "top_warning": "Top Banner", "page_buy": "Coin Buy Page", "page_contact": "Contact Page", "page_dashboard": "Dashboard", "page_game": "Game List Page", "page_history": "History Page", "page_kyc": "KYC Page", "page_lite": "Lite mode game page", "page_login": "Login Page", "page_lucky": "Lucky draw page", "page_ongoing": "Ongoing match page", "page_profile": "Profile Page", "page_rich": "Rich mode game page", "page_sell": "Coin Sell Page", "page_wallet": "Wallet", "page_roomcode": "Roomcode settings" };
    let html = []
    if (data !== null) {
        for (let key in data) {
            html.push(
                <Grid item xs={12} sm={6} key={key}>
                    <TextField name={key} defaultValue={data[key]} fullWidth id={key} label={label[key]} onBlur={(e) => {
                        handleSubmit(key, e.target.value);
                    }} />
                </Grid>
            );
        }
    }
    console.log(data)
    return (
        <MenuFrame>
            <Container>
                <Typography variant="h5">Warning on page </Typography>
                <Alert severity="warning">Long warning will show like this. A static warning is more visible to player.</Alert>
                <Box className="marqueebox"><Typography variant="body2" color={"warning.main"} className="marquee" >Short text upto 100 charecter show like this!</Typography></Box>
                {/* <Typography variant="body2" color={"warning.main"} fontWeight={"bold"} className="marquee">Short text show like this!</Typography> */}
                {data === null ? "" :
                    <Box component={Paper} noValidate style={{ padding: "5px" }}>
                        <Grid container spacing={2}>
                            {html}
                        </Grid>
                    </Box>
                }
            </Container>
        </MenuFrame>
    );
}

export default PageWarning;