import { Alert, Button, Checkbox, Collapse, Container, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Divider, FormControlLabel, IconButton, InputAdornment, Paper, Stack, TextField, Typography } from "@mui/material";
import { Component } from "react";
import '../css/login.css';
import { getApi } from "../shared/constant";
import LoginFooter from "../component/login_footer";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import 'animate.css';
import { trackerLog } from "../shared/tracker";
import { Helmet } from "react-helmet";
// import { GoogleLogin, GoogleOAuthProvider } from "@react-oauth/google";

class Login extends Component {
    state = { passwordless: 0, worong_pass: false, pass_msg: null, showPassword: false }
    constructor(props) {
        super(props);
        this.onFormSubmit = this.onFormSubmit.bind(this);
        this.handleClose = this.handleClose.bind(this);
    }

    async onFormSubmit(e) {
        e.preventDefault();
        if (this.state.passwordless === 1) {
            if (/^\d{10}$/.test(e.target.user.value)) {
                const xhr = await fetch(getApi() + "send-verification-otp", { method: "POST", body: JSON.stringify({ mobile: e.target.user.value }) });
                const data = await xhr.json();
                if (data.code === 200) {
                    this.setState({ passwordless: 2 });
                } else {
                    this.setState({ worong_pass: true, pass_msg: data.message });
                }
            } else {
                const xhr = await fetch(getApi() + "send-verification-mail", { method: "POST", body: JSON.stringify({ email: e.target.user.value }) });
                const data = await xhr.json();
                if (data.code === 200) {
                    this.setState({ passwordless: 2 });
                } else {
                    this.setState({ worong_pass: true, pass_msg: data.message });
                }
            }
        } else if (this.state.passwordless === 2) {
            const xhr = await fetch(getApi() + "otp-login", { method: "POST", body: JSON.stringify({ login: e.target.user.value, code: e.target.pass.value }) });
            const data = await xhr.json();
            if (data.code === 200) {
                await trackerLog("LOGIN");
                localStorage.setItem("token", data.message);
                localStorage.setItem("auth", data.privilege);
                window.location.reload();
            } else {
                this.setState({ worong_pass: true, pass_msg: data.message });
            }
        } else {
            const xhr = await fetch(getApi() + "login", { method: "POST", body: JSON.stringify({ user: e.target.user.value, password: e.target.pass.value }) });
            const data = await xhr.json();
            if (xhr.status !== 200) {
                this.setState({ worong_pass: true, pass_msg: data.message });
                return;
            }
            if (data.message !== null || data.message !== '') {
                await trackerLog("LOGIN");
                localStorage.setItem("token", data.message);
                localStorage.setItem("auth", data.privilege);
                window.location.reload();
            } else {
                alert(data.message);
            }
        }
    }

    handleClose(e) {
        // console.log(e);
        this.setState({ worong_pass: false });
    }

    render() {
        return (
            <div className="main-grid">
                <Helmet>
                    <title>Khelboludo - User Login</title>
                    <meta name="title" content="Login" />
                    <meta name="description" content="Join the large group of player. Play and win more." />
                </Helmet>
                <div className="split-grid">
                    <Container maxWidth="md" className="container-box">
                        <Typography variant="h3" gutterBottom style={{ color: '#027799' }}>
                            <strong>
                                Khelb
                                <img src="./logo192.png" alt="khel ludo logo" width={64} style={{ position: 'relative', bottom: '-12px' }} className="animate__animated animate__rotateIn" />
                                Ludo
                            </strong>
                        </Typography>
                        <Typography variant="h5" gutterBottom>
                            Play and win the exciting prize
                        </Typography>
                    </Container>
                    <Container maxWidth="md" className="container-box" style={{ display: 'flex' }}>
                        <Paper elevation={3} className="login-box">
                            <form onSubmit={this.onFormSubmit}>
                                <Stack spacing={2} >

                                    <Collapse in={(this.state.pass_msg != null)}>
                                        <Alert severity="error" style={{ marginBottom: '10px' }} onClose={() => { this.setState({ pass_msg: null }) }}>{this.state.pass_msg}</Alert>
                                    </Collapse>

                                    <TextField id="outlined-basic" label="Phone or Email" variant="outlined" name="user" type="text" />
                                    <Collapse in={this.state.passwordless !== 1} style={{ width: '100%' }}>
                                        <TextField id="outlined-basic" label={this.state.passwordless !== 0 ? "OTP" : "Password"} variant="outlined" name="pass" type={this.state.showPassword ? 'text' : 'password'} style={{ width: '100%' }} InputProps={{
                                            endAdornment: <InputAdornment position="end">
                                                <IconButton
                                                    aria-label="toggle password visibility"
                                                    onClick={() => { this.setState({ showPassword: !this.state.showPassword }) }}
                                                    onMouseDown={(e) => { e.preventDefault(); }}
                                                    edge="end" >
                                                    {this.state.showPassword ? <VisibilityOff /> : <Visibility />}
                                                </IconButton>
                                            </InputAdornment>,
                                        }} />
                                    </Collapse>
                                    <FormControlLabel control={<Checkbox defaultChecked={true} size="small" onClick={() => { this.setState({ passwordless: this.state.passwordless === 0 ? 1 : 0 }); }} />} label={this.state.passwordless ? "Login using OTP" : "Use password set by you"} style={{ marginTop: 0 }} />

                                    <Button type="submit" variant="contained" size="large" style={{ marginTop: 0 }} elevation="5">
                                        Login
                                    </Button>

                                    {/* <GoogleOAuthProvider clientId="516695093881-qp0hmt8nqphtvrk43aeauvg6n0jmb2rd">
                                        <GoogleLogin
                                            onSuccess={credentialResponse => {
                                                console.log(credentialResponse);
                                            }}
                                            onError={() => {
                                                console.log('Login Failed');
                                            }}
                                        />
                                    </GoogleOAuthProvider> */}

                                    <Divider />

                                    <Button href="/forgot-password" variant="text" size="large" style={{ marginTop: 0 }} elevation="5">
                                        <strong>Forgot Password</strong>
                                    </Button>

                                    <Button href="/register" variant="contained" color="success" size="large" style={{ marginTop: 0, padding: 15 }} elevation="5">
                                        <strong>Create an account</strong>
                                    </Button>
                                </Stack>
                            </form>
                        </Paper>
                    </Container>
                </div>

                <Dialog
                    open={this.state.worong_pass}
                    onClose={this.handleClose}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description">
                    <DialogTitle id="alert-dialog-title">
                        Warning
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            {this.state.pass_msg}
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={this.handleClose} autoFocus>Close</Button>
                    </DialogActions>
                </Dialog>

                <LoginFooter />
            </div>
        );
    }
}

export default Login;
