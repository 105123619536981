import { useState } from "react";
import { ADMIN, getApi, getAuthentication, getLoginToken } from "../../shared/constant";
import MenuFrame from "../../component/menu_frame";
import DataTable from "react-data-table-component";
import { Button, Container, Link, Typography } from "@mui/material";
import { green, orange } from "@mui/material/colors";
import 'animate.css';
import { Helmet } from "react-helmet";

const conditionalRowStyles = [
    {
        when: row => row.scheme === 'C',
        style: {
            backgroundColor: green[50],
            color: green[900],
            cursor: "pointer",
        },
    },
    {
        when: row => row.scheme === 'D',
        style: {
            backgroundColor: orange[50],
            color: orange[900],
            cursor: "pointer",
        },
    }
];

function ProfitLoss() {

    const [history, setHistory] = useState(null);
    // const [runningBalance, setRunningBalance] = useState(null);

    const columns = [
        { name: 'Account', sortable: true, wrap: true, minWidth: "150px", selector: row => <Link href={"/admin/transaction/" + row.username} color="unset" underline="none">{row.display_name}</Link>, },
        { name: 'Total', sortable: true, minWidth: "100px", right: true, selector: row => (row.balance + row.rewards), },
        { name: 'Balance', sortable: true, minWidth: "100px", right: true, selector: row => row.balance, },
        { name: 'Reward', sortable: true, minWidth: "100px", right: true, selector: row => row.rewards, },
        { name: 'Opening Total', sortable: true, minWidth: "150px", right: true, selector: row => (row.opening_balance + row.opening_rewards), },
        { name: 'Opening Balance', sortable: true, minWidth: "170px", right: true, selector: row => row.opening_balance, },
        { name: 'Opening Reward', sortable: true, minWidth: "150px", right: true, selector: row => row.opening_rewards, },
    ];

    if (history === null) {
        loadPage();
    }

    function loadPage() {
        fetch(getApi() + "accounts/get-all?token=" + getLoginToken()).then((res) => {
            return res.json();
        }).then((json) => {
            console.log(json);
            if (json.code === 200) {
                setHistory(json.message);
                // let sum = 0;
                // for (let i = 0; i < json.message.length; i++) {
                //     const row = json.message[i];
                //     let s = 1;
                //     if (row.scheme === 'D')
                //         s = -1;
                //     sum += s * ((row.balance - row.opening_balance) + (row.rewards - row.opening_rewards));
                // }
                // setRunningBalance(sum);
            }
            else setHistory([]);
        }).catch((e) => {
            console.error(e);
        });
    }

    function adjustBalance() {
        fetch(getApi() + "accounts/contra-adjustment").then((res) => {
            return res.json();
        }).then((json) => {
            console.log(json);
            loadPage();
        }).catch((e) => {
            console.error(e);
        });
    }

    return (
        <MenuFrame>
            <Helmet>
                <title>Khelboludo Balance Sheet</title>
            </Helmet>
            <DataTable
                title="Balance Sheet FY"
                dense={true}
                columns={columns}
                data={history === null ? [] : history}
                progressPending={history === null}
                conditionalRowStyles={conditionalRowStyles}
                className="animate__animated animate__fadeIn"
            />

            <Typography variant="body2" color={"warning.main"}>
                * All account are showing running balance and does not include consider pending transaction. <br />
                * सभी खाते शेष दिखा रहे हैं और इसमें लंबित लेनदेन पर विचार शामिल नहीं है।
            </Typography>
            <Container>
                {getAuthentication() === ADMIN ? <Button variant="outlined" fullWidth onClick={adjustBalance}>Contra Adjustment</Button> : ""}
                <Link href="/user/transaction" >My accounts</Link>
            </Container>
        </MenuFrame>
    );
}


export default ProfitLoss;