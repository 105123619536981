import { getApi } from "./constant";

export function clearLogs() {
    return fetch(getApi() + "system/extralogclean").then((res) => {
        return res.json();
    }).then((json) => {
        return json;
    });
}

export function clearApi() {
    return fetch(getApi() + "system/fastlogclean").then((res) => {
        return res.json();
    }).then((json) => {
        return json;
    });
}

export function clearOTP() {
    return fetch(getApi() + "system/clean-otp").then((res) => {
        return res.json();
    }).then((json) => {
        return json;
    });
}

export function clearScreenShot() {
    return fetch(getApi() + "system/remove-screenshot").then((res) => {
        return res.json();
    }).then((json) => {
        return json;
    });
}

export function clearMatches() {
    return fetch(getApi() + "system/clean-matches").then((res) => {
        return res.json();
    }).then((json) => {
        return json;
    });
}

export function clearDepositWithdraw() {
    return fetch(getApi() + "system/clean-deposit-withdraw").then((res) => {
        return res.json();
    }).then((json) => {
        return json;
    });
}

export function clearTransection() {
    return fetch(getApi() + "system/clean-transection").then((res) => {
        return res.json();
    }).then((json) => {
        return json;
    });
}
