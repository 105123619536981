import { Backdrop, Button, CircularProgress, Container, Link, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tooltip, Typography } from "@mui/material";
import MenuFrame from "../../component/menu_frame";
import { getApi, getLoginToken } from "../../shared/constant";
import { useEffect, useState } from "react";
import { SyncTransaction } from "../../shared/payments";
import { orange } from "@mui/material/colors";
import 'animate.css';
import { Helmet } from "react-helmet";

function DepositApprove() {
    const [request, setRequest] = useState(null);
    const [submit, setSubmit] = useState(true);

    useEffect(() => {
        const interval = setInterval(() => {
            fetch(getApi() + "deposit/get?state=Awaiting").then((res) => {
                return res.json();
            }).then((json) => {
                setRequest(json.message);
            }).catch((err) => {
                console.error(err);
            });
        }, 30000);
        return () => clearInterval(interval);
    }, []);

    if (request === null) {
        fetch(getApi() + "deposit/get?state=Awaiting").then((res) => {
            return res.json();
        }).then((json) => {
            // console.log(json);
            setRequest(json.message);
            setSubmit(false);
        }).catch((err) => {
            console.error(err);
            setSubmit(false);
        });
    }

    function approve(data) {
        setSubmit(true);
        fetch(getApi() + "deposit/approve", {
            method: 'POST',
            body: JSON.stringify({
                "token": getLoginToken(),
                "req_num": data.num,
                "comment": "Deposit received.",
                "amount": 0 //Not implimented
            })
        }).then((res) => {
            return res.json();
        }).then((json) => {
            console.log(json);
            if (json.message === 0) {
                alert("Low balance.")
            } else {
                setRequest(null);
            }
            setSubmit(false);
        }).catch((err) => {
            console.error(err);
            setSubmit(false);
        });
    }

    function reject(data) {
        setSubmit(true);
        fetch(getApi() + "deposit/reject", {
            method: 'POST',
            body: JSON.stringify({
                "token": getLoginToken(),
                "req_num": data.num,
                "comment": "Withdraw rejected",
            })
        }).then((res) => {
            return res.json();
        }).then((json) => {
            if (json.message === 0) {
                alert("Low balance.")
            } else {
                setRequest(null);
            }
            setSubmit(false);
        }).catch((err) => {
            console.error(err);
            setSubmit(false);
        });
    }

    function reSyncTransc(num) {
        SyncTransaction(num).then((json) => {
            if (json.code === 200) {
                setRequest(null);
            } else {
                alert(json.message)
            }
            // console.log(json);
        }).catch((e) => {
            console.error(e);
        });
    }

    return (
        <MenuFrame>
            <Helmet>
                <title>Khelboludo Deposit Approval</title>
            </Helmet>
            <Container className="main-frame">
                <Typography variant="h6" >Deposit Request</Typography>
                <Typography variant="body2" color="success.main" >Open Case: {request === null ? 0 : request.length}</Typography>
                <TableContainer component={Paper} className="animate__animated animate__fadeIn">
                    <Table sx={{ width: '100%' }}>
                        <TableHead>
                            <TableRow>
                                <TableCell><strong>Date</strong></TableCell>
                                <TableCell><strong>Username</strong></TableCell>
                                <TableCell><strong>Amount</strong></TableCell>
                                <TableCell><strong>Transaction number</strong></TableCell>
                                <TableCell><strong>Contact</strong></TableCell>
                                <TableCell align="center" colSpan={3}><strong>Action</strong></TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {request === null ? <></> :
                                request.map((row) => (
                                    <TableRow key={row.num} style={{ backgroundColor: row.method === "PhonePe" ? orange[50] : "inherit" }}>
                                        <TableCell scope="row" style={{ minWidth: "75px" }}>{row.created_on.substring(0, 9)}<br /><strong>{row.created_on.substring(10)}</strong></TableCell>
                                        <TableCell><Link underline="none" color={"unset"} href={"/admin/transaction/" + row.username} ><Tooltip title={row.name} arrow>@{row.username}</Tooltip></Link></TableCell>
                                        <TableCell align="right"><div className="icontext"><img src="/favicon.ico" width={"16px"} alt="ludo coin" />&nbsp;{row.amount}</div></TableCell>
                                        <TableCell>{row.tran_id} </TableCell>
                                        <TableCell>{row.mobile} {row.email} </TableCell>
                                        <TableCell>
                                            {row.method === "PhonePe" ? <Button color="success" variant="contained" onClick={() => { reSyncTransc(row.tran_id); }}>Refresh</Button> : ""}
                                        </TableCell>
                                        <TableCell>
                                            {row.method === "Manual" ? <Button color="success" variant="contained" onClick={() => { approve(row); }}>Approve</Button> : ""}
                                        </TableCell>
                                        <TableCell>
                                            <Button color="error" variant="contained" onClick={() => { reject(row); }}>Reject</Button>
                                        </TableCell>
                                    </TableRow>
                                ))
                            }
                        </TableBody>
                    </Table>
                </TableContainer>
            </Container>
            <Backdrop open={submit} ><CircularProgress /></Backdrop>
        </MenuFrame>
    );
}


export default DepositApprove;