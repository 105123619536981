import { getApi, getLoginToken } from "./constant";

export async function resultUpdate(gameID, result) {
    let res = await fetch(getApi() + "games/update-result", {
        method: 'POST',
        body: JSON.stringify({
            id: gameID,
            result: result,
            token: getLoginToken()
        })
    });
    let json = await res.json();
    return json;
}

export function getMyMatches() {
    return fetch(getApi() + "games/mymatch/get?token=" + getLoginToken()).then((res) => {
        return res.json();
    }).then((res) => {
        return res;
    });
}

export function getAllChallangerMatches(game) {
    return fetch(getApi() + "games/challange/get?game=" + game).then((res) => {
        return res.json();
    }).then((data) => {
        return data;
    });
}

export function getGameLiteRooms(game) {
    return fetch(getApi() + "games/get-room-by-game?game=" + game).then((res) => {
        return res.json();
    }).then((data) => {
        return data;
    });
}

export function cancelBeforeJoin(pid) {
    return fetch(getApi() + "/games/cancel", {
        method: "DELETE",
        body: JSON.stringify({
            token: getLoginToken(),
            pid: pid
        })
    }).then((res) => {
        return res.json();
    });
}