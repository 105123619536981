import { Container, Divider, Link, Paper, Typography } from "@mui/material";
import MenuFrame from "../../component/menu_frame";
import "../../css/chat.css";
import LoginFooter from "../../component/login_footer";
import { ADMIN, getAuthentication, getWhatsAppChat } from "../../shared/constant";
import { getAdminConfig } from "../../shared/config";
import { useEffect, useState } from "react";
import QRCode from "react-qr-code";
import { trackerLog } from "../../shared/tracker";
import { useNavigate } from "react-router-dom";
import { getUserData } from "../../shared/user";
import { Helmet } from "react-helmet";

function Chat() {
    const [whatsapp, setWhatsApp] = useState(null);
    const [user, setUser] = useState(null);
    const [rdr, setRdr] = useState(null);
    const navigate = useNavigate();

    useEffect(() => {
        if (rdr !== null && rdr !== undefined) {
            navigate(rdr)
        }
    }, [navigate, rdr]);

    if (whatsapp === null) {
        getAdminConfig("whatsapp").then((json) => {
            if (json.code === 200) {
                setWhatsApp(json.message["whatsapp"]);
            } else {
                setWhatsApp("");
            }
            return getUserData();
        }).then((json) => {
            console.log(json);
            if (json.message === null || json.code !== 200) {
                setRdr('/user/profile/logout');
            } else if (json.message.ctv === 0) {
                setRdr('/verification/mobile');
            } else {
                setUser(json.message);
                // Not sharing data with tidio
                document.tidioIdentify = {
                    distinct_id: json.message.username,
                    name: json.message.username
                };
            }
            trackerLog("LCHAT");
        }).catch((err) => {
            console.log(err);
        });
    }

    // const script = document.createElement("script");
    // script.src = "https://code.tidio.co/lacnpiy68axzloc5oq538ppi2qfb8iio.js";
    // script.async = true;
    // document.body.appendChild(script);

    const link1 = "https://t.me/" + whatsapp;
    const link2 = "https://www.instagram.com/khelboludo";
    return (
        <MenuFrame user={user} warning="page_contact">
            <Helmet>
                <title>Khelboludo - Chat Support</title>
            </Helmet>
            <Container className="chat-frame" maxWidth="md" style={{ display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center" }}>
                <Typography sx={{ typography: { xs: 'h5', sm: 'h3' } }} >We are live to resolve your issue on</Typography>
                {getAuthentication() === ADMIN ?
                    <Link href={"https://www.tidio.com/panel/dashboard"} underline="none" >Check User Chats</Link> :
                    <Paper style={{ padding: '10px', minWidth: '90%', maxWidth: '500px' }} elevation={3}>
                        <img src="/telegram.png" alt="WhatsApp Chat" style={{ maxWidth: '50px', width: '80%' }} />
                        <br />
                        <Link href={link1} underline="none">Connect us in Telegram</Link>
                        <br />
                        <QRCode value={link1} style={{ zoom: "0.6" }} />
                        <Divider style={{margin:"10px 0"}} />
                        <img src="/instagram.jpg" alt="WhatsApp Chat" style={{ maxWidth: '50px', width: '80%' }} />
                        <br />
                        <Link href={link2} underline="none">Connect us in Instagram</Link>
                        <br />
                        <QRCode value={link2} style={{ zoom: "0.6" }} />
                    </Paper>
                }
            </Container>
            <LoginFooter />
        </MenuFrame>
    );
}

export default Chat;