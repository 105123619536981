import { Grid, Link, Typography } from "@mui/material";
import 'animate.css';

function MidPart() {
    return (<Grid container spacing={2}>
        <Grid item xs={12} md={6} className='flex-body'>
            <Typography variant="h2" >
                Ludo Snake
            </Typography>
            <Typography variant="subtitle1" gutterBottom>
                We are online
            </Typography>

            <Typography variant="body1" gutterBottom textAlign={'justify'}>
            Most awating and demanding game of ever is comming soon in our platform. Play with thousand of player online and win exciting rewards.
            </Typography>
        </Grid>
        <Grid item xs={12} md={6} className='flex-body animate__animated animate__flipInY' >
            <img src='/snake-ladder.png' alt="Snake and ladder" style={{ minWidth: '50%' }} />
            {/* <Link href="/user/games/challenge/3" style={{margin:"5px 0 10px 0"}}>Play the Game</Link> */}
        </Grid>
    </Grid>);
}

export default MidPart;