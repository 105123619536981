import { useState } from "react";
import { ACTIVE, ADMIN, BANNED, HOLD, MANAGER, PARTNER, USER, VERIFICATION, getApi, getAuthentication, getLoginToken } from "../../shared/constant";
import MenuFrame from "../../component/menu_frame";
import DataTable from "react-data-table-component";
import { Button, ButtonGroup, Chip, Container, IconButton, InputBase, Link, Paper, Typography } from "@mui/material";
import DoneIcon from '@mui/icons-material/Done';
import SearchIcon from '@mui/icons-material/Search';
import VerifiedIcon from '@mui/icons-material/Verified';
import 'animate.css';
import { Helmet } from "react-helmet";
import { Edit } from "@mui/icons-material";

function UserList() {
    const [userList, setUserList] = useState(null);
    const [userListAll, setUserListAll] = useState([]);
    const [liability, setLiability] = useState(0);
    const [editRefer, setEditRefer] = useState(null);

    function handleChangeRefer(username, newperson) {
        if (username === newperson) return;
        fetch(getApi() + "refer/admin/update-person?token=" + getLoginToken() + "&username=" + username + "&person=" + newperson).then((res) => {
            return res.json();
        }).then((json) => {
            if (json.code !== 200) {
                alert(json.message);
            }
        }).catch((err) => {
            console.log(err);
        });
    }

    const RowComponent = ({ data }) => {
        return (<div style={{ padding: "10px" }}>
            <table style={{ width: "100%" }}>
                <tbody>
                    <tr>
                        <td style={{ width: "100px" }}>Mobile</td>
                        <td><Link href={"tel:" + data.mobile} underline="none" color="unset">{data.mobile}</Link> {data.ctv ? <VerifiedIcon fontSize="small" color="info" /> : ""} </td>
                    </tr>
                    <tr>
                        <td>Mail</td>
                        <td><Link href={"mailto:" + data.mail} underline="none" color="unset">{data.mail}</Link> {data.mlv ? <VerifiedIcon fontSize="small" color="info" /> : ""}</td>
                    </tr>
                    {getAuthentication() === ADMIN ?
                        <tr>
                            <td>Refer</td>
                            <td>
                                <IconButton type="button" sx={{ p: '10px' }}><Edit fontSize="small" onClick={() => { setEditRefer(data.username) }} /></IconButton>
                                <InputBase sx={{ ml: 1, flex: 1 }} placeholder="No refer" defaultValue={data.refer_username}
                                    onBlur={(e) => {
                                        handleChangeRefer(data.username, e.target.value);
                                    }} disabled={editRefer !== data.username} />
                            </td>
                        </tr>
                        : ""
                    }
                </tbody>
            </table>
            {getAuthentication() === MANAGER ? "" :
                <>
                    <ButtonGroup variant="outlined" style={{ marginBottom: "10px" }}>
                        {getAuthentication() === ADMIN ? <Button variant={data.authentication === PARTNER ? "contained" : "outlined"} color="error" onClick={() => { changeUserAuth(data, PARTNER); }} >{PARTNER}</Button> : ""}
                        {getAuthentication() === ADMIN || (getAuthentication() === PARTNER && data.authentication !== PARTNER) ? <Button variant={data.authentication === MANAGER ? "contained" : "outlined"} color="warning" onClick={() => { changeUserAuth(data, MANAGER); }} >{MANAGER}</Button> : ""}
                        {getAuthentication() === ADMIN || (getAuthentication() === PARTNER && data.authentication !== PARTNER) ? <Button variant={data.authentication === USER ? "contained" : "outlined"} color="success" onClick={() => { changeUserAuth(data, USER); }}>{USER}</Button> : ""}
                    </ButtonGroup>

                    <br />
                </>
            }
            {getAuthentication() === ADMIN || getAuthentication() === PARTNER ?
                <>
                    <ButtonGroup variant="outlined" style={{ marginBottom: "10px" }}>
                        <Button variant={data.status === VERIFICATION ? "contained" : "outlined"} color="warning" onClick={() => { changeUserStatus(data, VERIFICATION) }}>{VERIFICATION}</Button>
                        <Button variant={data.status === ACTIVE ? "contained" : "outlined"} color="warning" onClick={() => { changeUserStatus(data, ACTIVE) }}>{ACTIVE}</Button>
                        <Button variant={data.status === HOLD ? "contained" : "outlined"} color="warning" onClick={() => { changeUserStatus(data, HOLD) }}>{HOLD}</Button>
                        <Button variant={data.status === BANNED ? "contained" : "outlined"} color="warning" onClick={() => { changeUserStatus(data, BANNED) }}>{BANNED}</Button>
                    </ButtonGroup>
                    <br />
                </> : ""}
            {data.kyc === 0 && (getAuthentication() === ADMIN || getAuthentication() === PARTNER) ? <Button variant="outlined" color="success" style={{ marginBottom: "10px" }} onClick={() => { verifyKYC(data); }}>KYC Verified</Button> : ""}
        </div>);
    }

    const columns = [
        { name: 'UserID', sortable: true, wrap: true, minWidth: "150px", selector: row => <Link href={"/admin/transaction/" + row.username} color="unset" underline="none">@{row.username}</Link>, },
        { name: 'Name', sortable: true, minWidth: "150px", selector: row => row.name, },
        { name: 'Ludo Coin', sortable: true, right: true, selector: row => row.balance, },
        { name: 'Reward Coin', sortable: true, right: true, selector: row => row.rewards, },
        { name: 'Status', sortable: true, selector: row => row.status, },
        {
            name: '',
            selector: row => <>
                {row.kyc === 1 ? <Chip label="KYC" size="small" variant="outlined" color="success" deleteIcon={<DoneIcon />} onDelete={() => { }} /> : ""} &nbsp;
                {row.mlv === 1 ? <Chip label="Mail" size="small" variant="outlined" color="success" deleteIcon={<DoneIcon />} onDelete={() => { }} /> : ""} &nbsp;
                {row.ctv === 1 ? <Chip label="Contact" size="small" variant="outlined" color="success" deleteIcon={<DoneIcon />} onDelete={() => { }} /> : ""}
            </>,
            minWidth: "250px"
        },
    ];

    if (userList === null) {
        initData();
    }

    function initData() {
        fetch(getApi() + "get-all-user?token=" + getLoginToken()).then((res) => {
            return res.json();
        }).then((json) => {
            if (json.code === 200) {
                setUserListAll(json.message)
                setUserList(json.message);
                setLiability(json.liability);
            }
            else setUserList([]);
        }).catch((e) => {
            console.error(e);
        });
    }

    function changeUserAuth(data, change) {
        fetch(getApi() + "admin/change-auth", {
            method: "POST",
            body: JSON.stringify({
                token: getLoginToken(),
                auth: change,
                username: data.username
            })
        }).then((res) => {
            return res.json();
        }).then((_) => {
            alert("Your request is processing.");
            initData();
        }).catch((err) => {
            console.log(err);
        });
    }

    function verifyKYC(data) {
        fetch(getApi() + "admin/kyc-done", {
            method: "POST",
            body: JSON.stringify({
                token: getLoginToken(),
                username: data.username
            })
        }).then((res) => {
            return res.json();
        }).then((_) => {
            alert("Your request is processing.");
            initData();
        }).catch((err) => {
            console.log(err);
        });
    }

    function changeUserStatus(data, status) {
        fetch(getApi() + "admin/change-status", {
            method: "POST",
            body: JSON.stringify({
                token: getLoginToken(),
                status: status,
                username: data.username
            })
        }).then((res) => {
            return res.json();
        }).then((_) => {
            alert("Your request is processing.");
            initData();
        }).catch((err) => {
            console.log(err);
        });
    }

    return (
        <MenuFrame>
            <Helmet>
                <title>Khelboludo Admin - User list</title>
            </Helmet>
            <Container>
                <Paper component="form" sx={{ p: '2px 10px', display: 'flex', alignItems: 'center', m: "10px" }}>
                    <InputBase fullWidth placeholder="Search the user" name="filter" type="text" onChange={(event) => {
                        setUserList(userListAll.filter((item) => item.username.toLowerCase().includes(event.target.value.toLowerCase()) || (item.refer_username !== null && item.refer_username.toLowerCase().includes(event.target.value.toLowerCase()) && getAuthentication() === ADMIN) ||
                            item.name.toLowerCase().includes(event.target.value.toLowerCase())));
                    }} />
                    <IconButton type="button" sx={{ p: '10px' }} aria-label="search">
                        <SearchIcon />
                    </IconButton>
                </Paper>
            </Container>

            <DataTable
                title="User list"
                dense={true}
                columns={columns}
                data={userList === null ? [] : userList}
                progressPending={userList === null}
                pagination
                paginationPerPage={15}
                expandableRows
                expandableRowsComponent={RowComponent}
                className="animate__animated animate__fadeIn"
            />
            <Container>
                <Typography variant="body1">
                    Total Liability: &#8377;{liability}
                </Typography>
                <Typography variant="caption">
                    Excluding the money used in gameplay. full liabile on game cancel.
                </Typography>
            </Container>

        </MenuFrame>
    );
}

export default UserList;