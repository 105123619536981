import { getApi, getLoginToken } from "./constant";

export function getAdminConfig(name) {
    return fetch(getApi() + "admin/get-config?name=" + name).then((res) => {
        return res.json();
    }).then((json) => {
        return json;
    });
}


export function setAdminConfig(field, value) {
    return fetch(getApi() + "admin/set-config", {
        method: 'POST',
        body: JSON.stringify({
            field: field,
            value: value,
            token: getLoginToken()
        })
    }).then((res) => {
        return res.json();
    }).then((json) => {
        return json;
    })
}