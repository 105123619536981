import { Avatar, Box, CircularProgress, Container, CssBaseline, Grid, InputAdornment, TextField, Typography } from "@mui/material";
import { useState } from "react";
import Link from '@mui/material/Link';
import Button from '@mui/material/Button';
import MenuFrame from "../component/menu_frame";
import LockOpenIcon from '@mui/icons-material/LockOpen';
import  {getApi, isLogin } from "../shared/constant";
import { useNavigate, useParams } from "react-router-dom";
import { trackerLog } from "../shared/tracker";

function PasswordChange(params) {
    const { hash } = useParams();
    const [userID, setUserID] = useState(null);
    const [submit, setSubmit] = useState(false);
    const navigate = useNavigate()

    if (userID === null) {
        fetch(getApi() + "get-user-name?token=" + hash).then((res) => {
            return res.json();
        }).then((json) => {
            if (json.code === 200 && json.message !== null) {
                setUserID(json.message.username)
            }else{
                navigate("/login")
            }
            trackerLog("CPASS");
        }).catch((err) => {
            console.log(err);
        });
    }

    function handleSubmit(e) {
        e.preventDefault();
        setSubmit(true);
        const data = new FormData(e.currentTarget);

        if (data.get('password').length < 6 || data.get('password') !== data.get('repassword')) {
            alert("Invalid password or re-type password.");
            setSubmit(false);
            return;
        }

        fetch(getApi() + "user/change-password", {
            method: 'POST',
            body: JSON.stringify({
                "token": hash,
                "password": data.get('password')
            })
        }).then((res) => {
            return res.json();
        }).then((json) => {
            if (json.code === 200) {
                navigate("/login");
                localStorage.setItem("reset", false); // reset token off if set
            }
            setSubmit(false);
        }).catch((err) => {
            console.log(err);
            setSubmit(false);
        });
    }

    return (
        <MenuFrame>
            <Container component="main" maxWidth="xs">
                <CssBaseline />
                <Box sx={{ marginTop: 8, display: 'flex', flexDirection: 'column', alignItems: 'center', }} mb={10}>
                    <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
                        <LockOpenIcon />
                    </Avatar>
                    <Typography component="h1" variant="h5">
                        Change your password
                    </Typography>
                    <Box component="form" noValidate onSubmit={handleSubmit} sx={{ mt: 3 }} id="regester-form">
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <TextField fullWidth id="userid" label="User ID" type="text" defaultValue={userID} placeholder={userID} disabled={true} InputProps={{ startAdornment: <InputAdornment position="start">@</InputAdornment>, }} />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField fullWidth id="password" label="Password" name="password" autoComplete="password" type="password" />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField required fullWidth id="repassword" label="Type password again" name="repassword" type="password" />
                            </Grid>
                        </Grid>
                        <Button type="submit" fullWidth variant="contained" sx={{ mt: 3, mb: 2 }} disabled={submit}>
                            {submit ? (<CircularProgress color="primary" size={24} />) : "Change Password"}
                        </Button>
                        {
                            isLogin() ? "" : <Grid container justifyContent="flex-end">
                                <Grid item>
                                    <Link href="/login" variant="body2">
                                        Login
                                    </Link>
                                </Grid>
                            </Grid>
                        }
                    </Box>
                </Box>
            </Container>
        </MenuFrame>
    );

}
export default PasswordChange;