import { Backdrop, Button, Chip, CircularProgress, Container, Hidden, Link, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tooltip, Typography } from "@mui/material";
import MenuFrame from "../../component/menu_frame";
import { getApi, getLoginToken } from "../../shared/constant";
import { useEffect, useState } from "react";
import QRCode from "react-qr-code";
import { ContentCopy } from "@mui/icons-material";
import 'animate.css';
import { ToastContainer, toast } from "react-toastify";
import { red, yellow } from "@mui/material/colors";
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';
import { Helmet } from "react-helmet";

function WithdrowApprove() {
    const [request, setRequest] = useState(null);
    const [submit, setSubmit] = useState(true);

    useEffect(() => {
        const interval = setInterval(() => {
            fetch(getApi() + "withdraw/get?state=Awaiting").then((res) => {
                return res.json();
            }).then((json) => {
                setRequest(json.message);
            }).catch((err) => {
                console.error(err);
            });
        }, 30000);
        return () => clearInterval(interval);
    }, []);

    if (request === null) {
        fetch(getApi() + "withdraw/get").then((res) => {
            return res.json();
        }).then((json) => {
            // console.log(json);
            setRequest(json.message);
            setSubmit(false);
        }).catch((err) => {
            console.error(err);
            setSubmit(false);
        });
    }

    function approve(data) {
        setSubmit(true);
        fetch(getApi() + "/withdraw/approve", {
            method: 'POST',
            body: JSON.stringify({
                "token": getLoginToken(),
                "req_num": data.num,
                "comment": data.method + " withdraw approved by admin.",
                "amount": 0 //Not implimented
            })
        }).then((res) => {
            return res.json();
        }).then((json) => {
            if (json.message === 0) {
                alert("Low balance.")
            } else {
                setRequest(null);
            }
            setSubmit(false);
        }).catch((err) => {
            console.error(err);
            setSubmit(false);
        });
    }

    function pending(data) {
        setSubmit(true);
        fetch(getApi() + "/withdraw/pending", {
            method: 'POST',
            body: JSON.stringify({
                "token": getLoginToken(),
                "req_num": data.num,
                "comment": data.method + " withdraw is processed by admin but waiting for bank confirmation.",
            })
        }).then((res) => {
            return res.json();
        }).then((json) => {
            console.log(json);
            if (json.message === 0) {
                alert("Low balance.")
            } else {
                setRequest(null);
            }
            setSubmit(false);
        }).catch((err) => {
            console.error(err);
            setSubmit(false);
        });
    }

    function reject(data) {
        setSubmit(true);
        fetch(getApi() + "/withdraw/reject", {
            method: 'POST',
            body: JSON.stringify({
                "token": getLoginToken(),
                "req_num": data.num,
                "comment": "Withdraw rejected by admin.",
            })
        }).then((res) => {
            return res.json();
        }).then((json) => {
            if (json.message === 0) {
                alert("Low balance.")
            } else {
                setRequest(null);
            }
            setSubmit(false);
        }).catch((err) => {
            console.error(err);
            setSubmit(false);
        });
    }

    return (
        <MenuFrame>
            <Helmet>
                <title>Khelboludo Admin - Withdraw approval</title>
            </Helmet>
            <Container className="main-frame">
                <ToastContainer position="top-right" autoClose={1000} hideProgressBar={false} newestOnTop={false} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover theme="light" />
                <Typography variant="h6" >Withdraw Request</Typography>
                <Typography variant="body2" color="success.main" >Open Case: {request === null ? 0 : request.length}</Typography>
                <TableContainer component={Paper} className="animate__animated animate__fadeIn">
                    <Table sx={{ width: '100%' }}>
                        <TableHead>
                            <TableRow>
                                <TableCell><strong>Date</strong></TableCell>
                                <TableCell><strong>Username</strong></TableCell>
                                <TableCell align="right"><strong>Available</strong></TableCell>
                                <TableCell align="right"><strong>Requested</strong></TableCell>
                                <TableCell><strong>Details</strong></TableCell>
                                <TableCell><strong>QR</strong></TableCell>
                                <TableCell><strong>Contact</strong></TableCell>
                                <TableCell align="center" colSpan={3}><strong>Action</strong></TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {request === null ? <></> :
                                request.map((row) => (
                                    <TableRow key={row.num} style={row.rewards < 0 ? { background: red[300] } : row.status === "Pending" ? { background: yellow[50] } : {}}>
                                        <TableCell scope="row" style={{ minWidth: "75px" }}>{row.dt.substring(0, 9)}<br /><strong>{row.dt.substring(10)}</strong></TableCell>
                                        <TableCell><Link underline="none" color={"unset"} href={"/admin/transaction/" + row.username} ><Tooltip title={row.name} arrow>@{row.username}</Tooltip></Link></TableCell>
                                        <TableCell align="right"> <Chip icon={<AccountBalanceWalletIcon fontSize="small" color="success" />} label={row.rewards} color="success" variant="none" size="small" style={{ marginBottom: "5px" }} />  <Tooltip title="You can not withdraw this amount it is ludo coin." arrow><Chip icon={<img src="/favicon.ico" width={"16px"} alt="ludo coin" />} label={row.balance} color="error" variant="outlined" size="small" /></Tooltip></TableCell>
                                        <TableCell align="right"><div className="icontext"><AccountBalanceWalletIcon fontSize="small" color="error" />{row.amount} </div></TableCell>
                                        <TableCell>
                                            <strong>{row.method} Request</strong>
                                            <br />
                                            {row.bank_name !== null ? <><span style={{ cursor: 'copy' }} onClick={() => {
                                                navigator.clipboard.writeText(row.bank_name);
                                                toast("Bank name (" + row.bank_name + ") copied", { position: "top-right", toastId: "BANK_NAME" });
                                            }}>{row.bank_name}</span> <br /></> : ""}
                                            {row.ifsc !== null ? <><span style={{ cursor: 'copy' }} onClick={() => {
                                                navigator.clipboard.writeText(row.ifsc);
                                                toast("Bank IFSC code (" + row.ifsc + ") copied", { position: "top-right", toastId: "BANK_IFSC" });
                                            }}>{row.ifsc}</span><br /></> : ""}
                                            {row.acc_num !== null ? <><span style={{ cursor: 'copy' }} onClick={() => {
                                                navigator.clipboard.writeText(row.acc_num);
                                                toast("Bank account number (" + row.acc_num + ") copied", { position: "top-right", toastId: "BANK_ACCOUNT" });
                                            }}>{row.acc_num}</span></> : ""}
                                            {row.upi !== null ? <><span style={{ cursor: 'copy' }} onClick={() => {
                                                navigator.clipboard.writeText(row.upi);
                                                toast("Payee UPI address (" + row.upi + ") copied", { position: "top-right", toastId: "UPI_ADDRESS" });
                                            }} >{row.upi}</span><br /></> : ""}
                                            {row.other_details}
                                        </TableCell>
                                        <TableCell align="center">
                                            {row.upi === null ? "" :
                                                <>
                                                    <Link href={"upi://pay?pa=" + row.upi + "&cu=INR&tn=Khelboludo Withdraw&pn=" + row.other_details + "&tr=KBL2300" + row.num} underline="none">
                                                        <Hidden smDown>
                                                            <QRCode value={"upi://pay?pa=" + row.upi + "&cu=INR&tn=Khelboludo Withdraw&pn=" + row.other_details + "&tr=KBL2300" + row.num} style={{ height: "auto", width: "64px" }} />
                                                        </Hidden>
                                                        <Hidden smUp>
                                                            Click to Pay
                                                        </Hidden>
                                                    </Link>
                                                    <br />
                                                    <Button variant="outlined" fullWidth endIcon={<ContentCopy onClick={() => { navigator.clipboard.writeText(row.upi) }} />}>Copy</Button>
                                                </>
                                            }
                                        </TableCell>
                                        <TableCell>{row.mobile}<br />{row.email} </TableCell>
                                        <TableCell>
                                            <Button color="success" variant="contained" onClick={() => { approve(row); }}>Approve</Button>
                                        </TableCell>
                                        <TableCell>
                                            <Button color="warning" variant="contained" onClick={() => { pending(row); }}>Pending</Button>
                                        </TableCell>
                                        <TableCell>
                                            <Button color="error" variant="contained" onClick={() => { reject(row); }}>Reject</Button>
                                        </TableCell>
                                    </TableRow>
                                ))
                            }
                        </TableBody>
                    </Table>
                </TableContainer>
            </Container>
            <Backdrop open={submit} ><CircularProgress /></Backdrop>
        </MenuFrame>
    );
}


export default WithdrowApprove;