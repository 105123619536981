import { Avatar, Box, CircularProgress, Collapse, Container, CssBaseline, Grid, InputAdornment, TextField, Typography } from "@mui/material";
import { useState } from "react";
import Link from '@mui/material/Link';
import Button from '@mui/material/Button';
import MenuFrame from "../component/menu_frame";
import LockOpenIcon from '@mui/icons-material/LockOpen';
import FooterRegister from "../component/register_footer";
import "../css/common.css"
import { useNavigate } from "react-router-dom";
import { getApi } from "../shared/constant";
import 'animate.css';
import { trackerLog } from "../shared/tracker";
import { Helmet } from "react-helmet";

function ForgotPassword() {
    const [submit, setSubmit] = useState(false);
    const [emailErr, setEmailErr] = useState(null);
    const [phoneErr, setPhoneErr] = useState(null);
    const [otp, setOTP] = useState(false);
    const [recType, setRecType] = useState(null);
    const navigate = useNavigate();

    function handleSubmit(e) {
        e.preventDefault();
        const data = new FormData(e.currentTarget);
        if (recType === null || recType === "" || recType === undefined || (data.get("phone") === null && data.get("email") === null)) {
            alert("Invalid inputs");
            return;
        }
        setSubmit(true);
        trackerLog("FPASS");

        if (otp) {
            fetch(getApi() + "user/reset-otp", {
                method: 'POST',
                body: JSON.stringify({
                    "otp": data.get("otptxt"),
                    "user": data.get("email") === null ? data.get("phone") : data.get("email")
                })
            }).then((res) => {
                return res.json();
            }).then((data) => {
                if (data.code === 200) {
                    navigate("/password-change/" + data.message);
                } else {
                    alert(data.message)
                }
                setSubmit(false);
            }).catch((err) => {
                console.log(err);
                setSubmit(false);
            });
        } else if (recType !== null || recType !== '') {
            fetch(getApi() + "user/send-reset-otp", {
                method: "POST",
                body: JSON.stringify({
                    "user": data.get("email") === null ? data.get("phone") : data.get("email"),
                    "type": recType
                })
            }).then((res) => {
                return res.json();
            }).then((res) => {
                if (res.code === 200) {
                    setOTP(true);
                } else if (res.code === 403) {
                    setError(data.get("email") !== null, res.message);
                    setOTP(true);
                } else {
                    setErrorFromRes(data.get("email") !== null);
                }
                setSubmit(false);
            }).catch((err) => {
                console.log(err);
                setSubmit(false);
            });
        }
        function setErrorFromRes(mail) {
            if (mail) setError(true, "Email ID is not associted with any account.")
            else setError(false, "Phone number is not associted with any account.")
        }
        function setError(mail, text) {
            if (mail) setEmailErr(text)
            else setPhoneErr(text)
        }
    }

    return (
        <MenuFrame>
            <Helmet>
                <title>Khelboludo - Password Recovery</title>
            </Helmet>
            <Container component="main" maxWidth="xs" className="main-frame-min">
                <CssBaseline />
                <Box sx={{ marginTop: 8, display: 'flex', flexDirection: 'column', alignItems: 'center', }} mb={10}>
                    <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
                        <LockOpenIcon />
                    </Avatar>
                    <Typography component="h1" variant="h5">
                        Forgot Password
                    </Typography>
                    <Box component="form" className="animate__animated animate__fadeIn" noValidate onSubmit={handleSubmit} sx={{ mt: 3 }} id="regester-form">
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <TextField fullWidth id="email" label="Email Address" name="email" autoComplete="email" helperText={emailErr} error={emailErr !== null} disabled={recType !== null && recType !== 'mail'} onKeyUp={(e) => { e.target.value === '' ? setRecType(null) : setRecType('mail') }} />
                            </Grid>
                            <Grid item xs={12} textAlign={'center'}>
                                OR
                            </Grid>
                            <Grid item xs={12}>
                                <TextField required fullWidth id="phone" label="Phone number" name="phone" autoComplete="phone" helperText={phoneErr} error={phoneErr !== null} disabled={recType !== null && recType !== 'phone'} onKeyUp={(e) => { e.target.value === '' ? setRecType(null) : setRecType('phone') }} InputProps={{ startAdornment: <InputAdornment position="start">91</InputAdornment>, }} />
                            </Grid>
                            <Grid item xs={12}>
                                <Collapse in={otp}>
                                    <TextField fullWidth id="otptxt" label="OTP" name="otptxt" helperText="Enter the OTP you received" />
                                </Collapse>
                            </Grid>
                        </Grid>
                        <Button type="submit" fullWidth variant="contained" sx={{ mt: 3, mb: 2 }} disabled={submit}>
                            {submit ? (<CircularProgress color="primary" size={24} />) : otp ? "Reset Password" : "Get OTP"}
                        </Button>
                        <Grid container justifyContent="flex-end">
                            <Grid item>
                                <Link href="/login" variant="body2">
                                    Login
                                </Link>
                            </Grid>
                        </Grid>
                    </Box>
                </Box>
            </Container>
            <FooterRegister />
        </MenuFrame>
    );

}
export default ForgotPassword;