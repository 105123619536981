import { useState } from "react";
import "../../../css/edit.css";
import MenuFrame from "../../../component/menu_frame";
import { Backdrop, Card, CircularProgress, Grid, Typography } from "@mui/material";
import { getApi } from "../../../shared/constant";
import LocationOnIcon from '@mui/icons-material/LocationOn';
import { useParams } from "react-router-dom";
import { Helmet } from "react-helmet";


function PublicProfile() {
    const [user, setUser] = useState(null);
    const { username } = useParams();

    if (user === null) {
        fetch(getApi() + "get-user-public?user=" + username).then((res) => {
            if (res.status !== 200) { throw res; }
            return res.json();
        }).then((data) => {
            setUser(data.message);
        }).catch((err) => {
            console.log(err);
            alert("User not found");
            window.location.assign("/");
        });
    }

    return (
        <MenuFrame warning="page_profile">
            <Helmet>
                <title>{user === null ? "" : user.info.name}</title>
                <meta property="og:description" content={user === null ? "" : user.info.name + " on KhelboLudo, Get real cash by playing game. He won " + (user === null ? 0 : user.finance.Winning) + " with us."} />
                <meta name="keywords" content={user === null ? "" : user.info.name + ",khelboludo,ludo,player,ludo,khelbo,playing,game,coins,wins,reward,earning"}></meta>
                <meta property="article:tag" content={username} />
                <meta property="article:tag" content={user === null ? "" : user.info.name} />
                <meta property="article:tag" content={"user"} />
            </Helmet>
            {user === null ? (
                <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={true}>
                    <CircularProgress color="inherit" />
                </Backdrop>
            ) :
                (<Card className="profile-pane" style={{ margin: '10px', marginTop: "50px", padding: '10px', textAlign: 'center' }}>
                    <div className="profile">
                        <img src={user.info.profile_pic === null ? "/male-profile2.svg" : user.info.profile_pic} alt="profile card" />
                    </div>
                    <div className="name">{user.info.name}</div>
                    <Grid container spacing={2} justifyContent={"center"}>
                        <Grid item xs={6}>
                            <Typography variant="body2" color={"text.secondary"}>Game Played</Typography>
                            <Typography variant="h5"><strong>{user?.games?.played ?? 0}</strong></Typography>
                        </Grid>
                        <Grid item xs={6}>
                            <Typography variant="body2" color={"text.secondary"}>Winning</Typography>
                            <Typography variant="h5"><strong>{user?.finance?.Winning ?? 0}</strong></Typography>
                        </Grid>
                        <Grid item xs={6}>
                            <Typography variant="body2" color={"text.secondary"}>Referral Earning</Typography>
                            <Typography variant="h5"><strong>{user?.finance?.Referral ?? 0}</strong></Typography>
                        </Grid>
                        {/* <Grid item xs={6}>
                            <Typography variant="body2" color={"text.secondary"}>Extra</Typography>
                            <Typography variant="h5"><strong>{user?.finance?.Reward ?? 0}</strong></Typography>
                        </Grid> */}
                    </Grid>
                    <br />
                    <div><strong>+91 **********</strong></div>
                    <div className="icontext" style={{ alignItems: 'center', justifyContent: 'center' }}>
                        <LocationOnIcon /> Hidden by user
                    </div>
                    <Grid container spacing={2} mt={2} mb={1}>
                        <Grid item xs={6}>
                            <Typography variant="body2" color={"text.primary"} textAlign={"right"}>Joined</Typography>
                        </Grid>
                        <Grid item xs={6}>
                            <Typography variant="body2" color={"success.main"} textAlign={"left"}>{user === null ? "" : user.info.joined}</Typography>
                        </Grid>
                        <Grid item xs={6}>
                            <Typography variant="body2" color={"text.primary"} textAlign={"right"}>Last luck draw wining</Typography>
                        </Grid>
                        <Grid item xs={6}>
                            <Typography variant="body2" color={"success.main"} textAlign={"left"}>{user === null ? "" : user.info.lucky}</Typography>
                        </Grid>
                    </Grid>
                </Card>)
            }
        </MenuFrame>
    );
}


export default PublicProfile;